/* eslint-disable  jsx-a11y/label-has-associated-control */

import React, { useState } from "react";
import cc from "classcat";
import { Button } from "@narmi/design_system";
import { jsonFromDocument } from "cerulean"; // eslint-disable-line import/no-unresolved
import styles from "./StatementManagementContainer.module.css";

const NoStatements = () => (
  <div>
    <center>
      <i className="massive book icon" />
      <h2>No More Statements Available for Processing</h2>
    </center>
  </div>
);

const ManageStatementsForm = ({
  csrfToken,
  statementsPostedDate,
  statementsCount,
  testStatementUrl,
  testStatementAccountNumberPrimary,
  statementManagementUrl,
}) => {
  const date = new Date(statementsPostedDate);
  const statementsPosted = `${date.toLocaleString("default", {
    month: "long",
  })} ${date.getDate()}, ${date.getFullYear()}`;

  const [detailsChecked, setDetailsChecked] = useState(false);
  const [testStatementAccessed, setTestStatementAccessed] = useState(false);
  const [testSampleChecked, setTestSampleChecked] = useState(false);

  const confirmDetails = (e) => {
    setDetailsChecked(e.target.checked);
    if (!e.target.checked) {
      setTestSampleChecked(false);
    }
  };

  const accessStatement = (e) => {
    e.preventDefault();
    window.open(
      testStatementUrl,
      "Test Statement PDF",
      "scrollbars=1,resizable=1,height=400px,width=600px"
    );
    setTestStatementAccessed(true);
  };

  const confirmStatement = (e) => {
    setTestSampleChecked(e.target.checked);
  };

  const Step1 = () => (
    <div className={styles.step}>
      <div>
        <div className={styles.circle}>1</div>
        <div className={styles.line} />
      </div>
      <div>
        <h2>Confirm Details</h2>
      </div>
      <div className={styles.stepBody}>
        <p>
          You uploaded {statementsCount}{" "}
          {statementsCount === 1 ? "statement" : "statements"} for the month
          ending on {statementsPosted}.
        </p>
        <div className="ui checkbox">
          <input
            type="checkbox"
            name="details"
            checked={detailsChecked}
            onChange={confirmDetails}
          />
          <label>The above information is correct</label>
        </div>
      </div>
    </div>
  );

  const Step2Confirm = () => {
    if (!testStatementAccessed) {
      return null;
    }

    return (
      <div className="ui checkbox">
        <input
          type="checkbox"
          name="testSample"
          checked={testSampleChecked}
          onChange={confirmStatement}
        />
        <label>The statement is correct</label>
      </div>
    );
  };

  const Step2Download = () => (
    <p>
      <Button
        endIcon="download"
        kind="negative"
        label="Download Statement"
        onClick={accessStatement}
        size="m"
      />
    </p>
  );

  const Step2Body = () => {
    if (!detailsChecked) {
      return null;
    }

    return (
      <div className={styles.stepBody}>
        <p>
          Please download the statement for account{" "}
          {testStatementAccountNumberPrimary} on {statementsPosted} to ensure it
          was generated correctly.
        </p>
        <Step2Download />
        <Step2Confirm />
      </div>
    );
  };

  const Step2 = () => (
    <div className={cc([styles.step, { [styles.closed]: !detailsChecked }])}>
      <div>
        <div className={styles.circle}>2</div>
      </div>
      <div>
        <h2>Access &amp; Confirm a Test Statement</h2>
      </div>
      <Step2Body />
    </div>
  );

  return (
    <div>
      <form action={statementManagementUrl} method="POST">
        <h1>Manage Statements</h1>
        <Step1 />
        <Step2 />
        <input type="hidden" name="count" value={statementsCount} />
        <input type="hidden" name="csrfmiddlewaretoken" value={csrfToken} />
        <button
          type="submit"
          className="ui fluid positive huge button"
          style={{ marginTop: "3em" }}
          disabled={!(testSampleChecked && detailsChecked)}
        >
          Make Statements Available &amp; Notify Members
        </button>
      </form>
    </div>
  );
};

const StatementManagementContainer = () => {
  const csrfToken = jsonFromDocument("csrf_token");
  const statementsPostedDate = jsonFromDocument("statement_posted_date");
  const statementsCount = jsonFromDocument("statements_count");
  const testStatementUrl = jsonFromDocument("test_statement_url");
  const testStatementAccountNumberPrimary = jsonFromDocument(
    "test_statement_account_number_primary"
  );
  const statementManagementUrl = jsonFromDocument("statement_management_url");

  const hasStatements = statementsCount > 0;
  return (
    <div className={styles.mainContent}>
      {hasStatements ? (
        <ManageStatementsForm
          statementManagementUrl={statementManagementUrl}
          testStatementAccountNumberPrimary={testStatementAccountNumberPrimary}
          testStatementUrl={testStatementUrl}
          statementsCount={statementsCount}
          csrfToken={csrfToken}
          statementsPostedDate={statementsPostedDate}
        />
      ) : (
        <NoStatements />
      )}
    </div>
  );
};

export default StatementManagementContainer;
