/* eslint-disable camelcase,no-underscore-dangle */
import React from "react";
import { RadioButtons } from "@narmi/design_system";

const UserRoleRadio = ({ create_user_eligible_roles, onRoleSelected }) => {
  const options = {};
  Object.keys(create_user_eligible_roles).forEach((key) => {
    const role = create_user_eligible_roles[key];
    options[role.narmi_name] = role._id;
  });
  return (
    <div className="padding--top--m">
      <RadioButtons
        options={options}
        onChange={onRoleSelected}
        name="sisense_role_id"
      />
    </div>
  );
};

export default UserRoleRadio;
