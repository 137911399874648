import React from "react";
/**
 * takes a React component and a boolean value and, depending on the value of the boolean,
 * wraps the component in CSS attributes to indicate the component cannot be edited
 *
 * @param {children} React.ReactNode - the React components to be wrapped in CSS
 * @param {canEdit} boolean - determines whether or not the CSS attributes should be applies
 * @returns {React.ReactNode} returns the React component wrapped in the CSS attributes
 */
function PermissionedField({ children, canEdit }) {
  if (canEdit) {
    return <>{children}</>;
  }

  return (
    <div style={{ cursor: "not-allowed" }}>
      <div style={{ pointerEvents: "none" }}>{children}</div>
    </div>
  );
}

export default PermissionedField;
