import React from "react";

const ChangeLogRow = ({ settingHistory }) => (
  <tr
    style={{ height: "62px", borderTop: "5px solid rgb(var(--border-color))" }}
  >
    <td style={{ width: "18%" }}>
      {new Date(settingHistory.timestamp).toLocaleDateString("en-US", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      })}
    </td>
    <td style={{ width: "25%" }}>{settingHistory.user}</td>
    <td style={{ width: "57%", wordWrap: "break-word" }}>
      {settingHistory.note_value}
    </td>
  </tr>
);

const OpwChangeLog = () => {
  const [history, setHistory] = React.useState([]);
  const fetchHistory = async () => {
    const response = await fetch("/audit_logs/setting/CORE_OPENING_PRODUCTS");
    const data = await response.json();
    setHistory(data.audits);
  };

  React.useEffect(() => {
    fetchHistory();
  }, []);

  const TableHeaderCell = ({ width, header, isRightAligned = false }) => (
    <th
      className={`bgColor--snowGrey padding--left--l padding--bottom--xs fontWeight--bold fontFamily--body fontSize--xs ${
        isRightAligned ? "right aligned" : ""
      }`}
      style={{ textTransform: "uppercase", width, border: "none" }}
    >
      {header}
    </th>
  );

  return (
    <>
      <table className="ui fixed margin--y--xxs table">
        <thead>
          <tr>
            <TableHeaderCell width="18%" header="Date" />
            <TableHeaderCell width="25%" header="User" />
            <TableHeaderCell width="57%" header="Note" />
          </tr>
        </thead>
      </table>
      <table
        className="ui fixed table margin--y--xxs"
        style={{
          boxShadow: "0px 2px 12px rgba(80, 80, 80, 0.05)",
          borderRadius: "4px",
        }}
      >
        <tbody>
          {history.map((a) => (
            <ChangeLogRow settingHistory={a} key={a.timestamp} />
          ))}
        </tbody>
      </table>
    </>
  );
};

export default OpwChangeLog;
