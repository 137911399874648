const actions = {
  LOAD_LOCALES: "begin loading all locales used by the institution",
  LOAD_SETTINGS_LOCALES: "begin loading non-English 'fi' product locales",
  LOAD_LOCALES_FAIL: "locale loading failed",
  LOAD_LOCALES_SUCCESS: "locale loading completed",
  LOAD_SETTINGS_LOCALES_FAIL: "'fi' settings locales failed to load",
  LOAD_SETTINGS_LOCALES_SUCCESS: "'fi' settings locales completed loading",
  FILTER_MESSAGES: "update filtered messages in locale based on search query",
  SET_MESSAGE: "begin updating FTL file with an updated message",
  SET_MESSAGE_FAIL: "message updated failed",
  SET_MESSAGE_SUCCESS: "message updated completed",
  UPDATE_LOCALE: "updates entire FTL file (via raw editor)",
  UPDATE_LOCALE_FAIL: "failed to update FTL file",
  UPDATE_LOCALE_SUCCESS: "successfully updated FTL file",
  CHANGE_LOCALE: "switch between locales within a product",
  ENABLE_LOCALE_SUCCESS: "locale enabled",
  ENABLE_LOCALE_FAIL: "message updated failed",
  DISABLE_LOCALE_SUCCESS: "locale disabled",
  DISABLE_LOCALE_FAIL: "message updated failed",
  HIDE_LOCALE_SUCCESS: "locale hidden",
  HIDE_LOCALE_FAIL: "hiding locale failed",
  REMOVE_LOCALE: "remove locale from editor UI",
  ADD_LOCALE: "begin adding a new FTL file for a new language",
  ADD_LOCALE_FAIL: "locale add failed",
  ADD_LOCALE_SUCCESS: "locale add completed",
};

export default actions;
