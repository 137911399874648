/* eslint-disable camelcase,no-underscore-dangle,react/jsx-no-bind */
import { Button } from "@narmi/design_system";
import React from "react";
import AddUserScreen from "./AddUserScreen";
import DeleteUserScreen from "./DeleteUserScreen";
import UpdateUserScreen from "./UpdateUserScreen";

class ReportsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adding_user: false,
      deleting_user: false,
      updating_user: false,
      user_to_delete_id: "",
      user_to_delete_email: "",
      user_to_update_id: "",
      user_to_update_email: "",
    };
  }

  onAddUser(adding_user) {
    this.setState({
      adding_user,
    });
  }

  onDeleteUser(deleting_user, id, email) {
    this.setState({
      deleting_user,
      user_to_delete_id: id,
      user_to_delete_email: email,
    });
  }

  onUpdateUser(updating_user, id, email) {
    this.setState({
      updating_user,
      user_to_update_id: id,
      user_to_update_email: email,
    });
  }

  render() {
    const {
      adding_user,
      deleting_user,
      updating_user,
      user_to_delete_id,
      user_to_delete_email,
      user_to_update_id,
      user_to_update_email,
    } = this.state;

    const { team_management_context } = this.props;

    if (adding_user) {
      return (
        <AddUserScreen
          backFunction={this.onAddUser.bind(this, false)}
          csrf_token={team_management_context.csrf_token}
          create_user_eligible_roles={
            team_management_context.create_user_eligible_roles
          }
        />
      );
    }
    if (deleting_user) {
      return (
        <DeleteUserScreen
          backFunction={this.onDeleteUser.bind(this, false)}
          csrf_token={team_management_context.csrf_token}
          user_to_delete_id={user_to_delete_id}
          user_to_delete_email={user_to_delete_email}
        />
      );
    }
    if (updating_user) {
      return (
        <UpdateUserScreen
          backFunction={this.onUpdateUser.bind(this, false)}
          csrf_token={team_management_context.csrf_token}
          create_user_eligible_roles={
            team_management_context.create_user_eligible_roles
          }
          user_to_update_id={user_to_update_id}
          user_to_update_email={user_to_update_email}
        />
      );
    }
    return (
      <>
        <table className="ui celled table">
          <thead>
            <tr>
              <th className="five wide">Name</th>
              <th className="six wide">Email</th>
              <th className="three wide">Analytics role</th>
              <th className="two wide" aria-label="no title table header" />
            </tr>
          </thead>
          <tbody>
            {Object.keys(team_management_context.sisense_users).map((key) => (
              <tr key={key} className="item">
                <td>
                  <h5 className="ui header">
                    <div className="content">
                      {team_management_context.sisense_users[key].firstName}{" "}
                      {team_management_context.sisense_users[key].lastName}
                    </div>
                  </h5>
                </td>
                <td>{team_management_context.sisense_users[key].email}</td>
                <td>
                  {
                    team_management_context.sisense_users[key]
                      .sisense_role_narmi_name
                  }
                </td>
                <td>
                  {team_management_context.sisense_users[key]
                    .sisense_role_narmi_name !== "Analytics Admin" && (
                    <>
                      <Button
                        style={{ marginRight: "15px" }}
                        onClick={this.onUpdateUser.bind(
                          this,
                          true,
                          team_management_context.sisense_users[key]._id,
                          team_management_context.sisense_users[key].email
                        )}
                        as="a"
                        kind="plain"
                      >
                        Edit
                      </Button>
                      <Button
                        onClick={this.onDeleteUser.bind(
                          this,
                          true,
                          team_management_context.sisense_users[key]._id,
                          team_management_context.sisense_users[key].email
                        )}
                        as="a"
                        kind="plain"
                      >
                        Delete
                      </Button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <p>{team_management_context.remaining_licenses_sentence}</p>
        <Button
          label="Add user"
          onClick={this.onAddUser.bind(this, true)}
        />
      </>
    );
  }
}

export default ReportsTable;
