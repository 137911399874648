import React from "react";
import Collapsible from "../components/Collapsible";
import Iframe from "../components/Iframe";
import ReportsPageReportsTable from "./ReportsPageReportsTable";
import ReportsPageTeamTable from "./ReportsPageTeamTable";

export default class ReportsPageContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      iframeUrl: JSON.parse(document.getElementById("iframe_url").textContent),
      legacyReports: JSON.parse(
        document.getElementById("legacy_reports").textContent
      ),
      teamManagementContext: JSON.parse(
        document.getElementById("team_management_context").textContent
      ),
      availableBillingMonthsJSON: JSON.parse(
        document.getElementById("available_billing_months").textContent
      ),
      displayBillingReports: JSON.parse(
        document.getElementById("display_billing_reports").textContent
      ),
    };
  }

  render() {
    const {
      iframeUrl,
      legacyReports,
      teamManagementContext,
      availableBillingMonthsJSON,
      displayBillingReports,
    } = this.state;
    const availableBillingMonths = JSON.parse(availableBillingMonthsJSON);

    return (
      <div>
        <div className="ui column grid">
          {iframeUrl != null && (
            <div className="column sixteen wide">
              <Collapsible title="Dashboard" defaultOpen>
                <Iframe
                  title="dashboard"
                  src={iframeUrl}
                  frameBorder="0"
                  height="900"
                  width="100%"
                />
              </Collapsible>
            </div>
          )}
          <div className="column sixteen wide">
            <Collapsible title="Reports" defaultOpen={iframeUrl == null}>
              <ReportsPageReportsTable
                legacy_reports={legacyReports}
                availableBillingMonths={availableBillingMonths}
                displayBillingReports={displayBillingReports}
              />
            </Collapsible>
          </div>
          {teamManagementContext != null && (
            <div className="column sixteen wide">
              <Collapsible title="Team" defaultOpen>
                <ReportsPageTeamTable
                  team_management_context={teamManagementContext}
                />
              </Collapsible>
            </div>
          )}
        </div>
      </div>
    );
  }
}
