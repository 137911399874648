/* eslint-disable react/require-default-props */
import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { SeparatorList, Row, Button, NotificationContext } from "cerulean"; // eslint-disable-line import/no-unresolved

import AceEditor from "react-ace";
import "./ace-mode-fluent";
import "./ace-theme-fluent";

import { EditorContext } from "../../store/context";
import { validateMessage } from "../../fluentUtil";

const noop = () => {};

const MessageEditor = ({
  messageId,
  lines = 8,
  value,
  onSave = noop,
  onCancel = noop,
}) => {
  const [editorValue, setEditorValue] = useState(value.trim());
  const context = useContext(EditorContext);
  const [state] = context;
  const { sendNotification } = useContext(NotificationContext);

  const handleEditorChange = (newValue) => {
    setEditorValue(newValue);
  };

  const handleSave = () => {
    const defaultLocale = state.product === "fi" ? "en" : "default";
    const defaultMessage =
      state.locales[defaultLocale].messages[messageId] || value;
    const { isValid, reason } = validateMessage(
      value,
      editorValue,
      defaultMessage
    );
    if (isValid) {
      onSave({
        id: messageId,
        message: editorValue,
      });
    } else {
      sendNotification({
        type: "negative",
        text: `Can't save message: ${reason}`,
      });
    }
  };

  return typeof window === "undefined" ? (
    <></>
  ) : (
    <div className="padding--bottom--xs">
      <div
        style={{ backgroundColor: "#f8f8f8" }}
        className="border--all rounded--all padding--all--xs margin--bottom--s"
      >
        <AceEditor
          focus
          mode="fluent"
          theme="fluent"
          onChange={handleEditorChange}
          name={`editor-${messageId}`}
          showPrintMargin={false}
          showGutter={false}
          highlightActiveLine={false}
          value={editorValue}
          height={`${lines}em`}
          width="100%"
          cursorStart={1}
          fontSize={16}
          setOptions={{
            showLineNumbers: false,
            tabSize: 4,
          }}
        />
      </div>
      <Row>
        <Row.Item>
          <div className="fontSize--xs fontColor--secondary">
            <SeparatorList
              items={[
                <a
                  target="_blank"
                  className="fontColor--azul"
                  href="https://projectfluent.org/fluent/guide/"
                  rel="noreferrer"
                >
                  Syntax Guide
                </a>,
                <a
                  target="_blank"
                  className="fontColor--azul"
                  href="https://projectfluent.org/play/"
                  rel="noreferrer"
                >
                  Fluent Playground
                </a>,
              ]}
            />
          </div>
        </Row.Item>
        <Row.Item shrink>
          <Row alignItems="center">
            <Row.Item shrink>
              <Button label="Cancel" kind="negative" onClick={onCancel} />
            </Row.Item>
            <Row.Item shrink>
              <Button onClick={handleSave} label="Save" />
            </Row.Item>
          </Row>
        </Row.Item>
      </Row>
    </div>
  );
};

MessageEditor.propTypes = {
  /** fluent message id */
  messageId: PropTypes.string.isRequired,
  /** full fluent message to use in ace editor */
  value: PropTypes.string.isRequired,
  /** sets height of ace editor by number of lines */
  lines: PropTypes.number,
  /** callback saving an edited message */
  onSave: PropTypes.func,
  /** callback cancelling editing */
  onCancel: PropTypes.func,
};

export default MessageEditor;
