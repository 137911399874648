/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from "react";
import {
  Tabs,
  Row,
  LoadingSkeleton,
  Button,
  TextInput,
  Dialog,
  ContentCard,
} from "@narmi/design_system";
import { useParams, Link, useHistory } from "react-router-dom";

import { jsonFromDocument } from "cerulean"; // eslint-disable-line import/no-unresolved
import CodeEditor from "./CodeEditor";
import FunctionMonitoringWidget from "./FunctionMonitoringWidget";
import CopyableCell from "../components/CopyableCell";
import ConditionalMessage from "../components/ConditionalMessage";
import LabeledContentRow from "../components/LabeledContentRow";
import FunctionTestingDrawer from "./FunctionTestingDrawer";
import { CodeDownloadButton } from "./FunctionTemplateSelector";

const TYPE_MAPPINGS = {
  naf: { name: "Narmi application framework", extraField: "Request method" },
  schedule: { name: "Scheduled", extraField: "Cron string" },
  webhook: { name: "Webhook", extraField: "Webhook trigger event" },
};

const getTypeMetadataField = (func) => {
  switch (func?.type) {
    case "schedule":
      return func.type_metadata?.cron;
    case "webhook":
      return func.type_metadata?.event;
    case "naf":
      return func.type_metadata?.request_method.join(', ');
    default:
      return null;
  }
};

export default function FunctionStatus() {
  const csrfToken = jsonFromDocument("csrf_token") || "CSRF-TOKEN-NOT-FOUND";
  const isNarmi = jsonFromDocument("is_narmi") || false;
  const coreVpnExists = jsonFromDocument("core_vpn_exists", false);
  const coreVpnUrl = jsonFromDocument("core_vpn_url", false) || "Not Available";
  const canDeleteFunction = jsonFromDocument("can_delete_function", false);
  const { uuid } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0);
  const [narmiFunction, setNarmiFunction] = useState(null);
  const [deleteConfirmInput, setDeleteConfirmInput] = useState("");
  const [deleteModalFunction, setDeleteModalFunction] = useState(null);
  const [showConcurrencyModal, setShowConcurrencyModal] = useState(false);
  const [concurrencyInput, setConcurrencyInput] = useState(null);
  const [error, setError] = useState(null);
  const [infoMessage, setInfoMessage] = useState(null);
  const [testingModalOpen, setTestingModalOpen] = useState(false);
  const history = useHistory();

  const urlMessage = new URLSearchParams(window.location.search).get("message");

  async function getFunction() {
    const resp = await fetch(`/v1/functions/${uuid}`);
    if (!resp.ok) {
      history.push("/functions");
    }
    const body = await resp.json();
    setNarmiFunction(body);
    setIsLoading(false);
  }

  useEffect(() => {
    getFunction();
    if (urlMessage) {
      setInfoMessage(urlMessage);
    }
  }, [urlMessage]);

  async function handleDelete(message = "") {
    setError(null);
    const res = await fetch(
      `/v1/functions/${uuid}/delete?delete_confirm=${encodeURIComponent(
        message
      )}`,
      {
        method: "DELETE",
        headers: {
          "X-CSRFToken": csrfToken,
          "Content-Type": "application/json",
        },
      }
    );
    if (!res.ok) {
      const { detail: errorDetail } = await res.json();
      setError(errorDetail || res.statusText);
      return;
    }
    window.location.reload();
  }

  async function handleDeactivate() {
    setError(null);
    const res = await fetch(`/v1/functions/${uuid}/deactivate`, {
      method: "PUT",
      headers: { "X-CSRFToken": csrfToken, "Content-Type": "application/json" },
    });
    if (!res.ok) {
      setError(res.json() || res.statusText);
      return;
    }
    setIsLoading(true);
    setInfoMessage("Function has been deactivated successfully.");
    getFunction();
  }

  async function handlePutConcurrency() {
    setError(null);
    const res = await fetch(`/v1/functions/${uuid}/concurrency`, {
      method: "PUT",
      headers: { "X-CSRFToken": csrfToken, "Content-Type": "application/json" },
      body: JSON.stringify({ concurrency: concurrencyInput }),
    });
    if (!res.ok) {
      setError(res.json() || res.statusText);
      return;
    }
    setIsLoading(true);
    setInfoMessage("Function concurrency has been successfully updated.");
    getFunction();
  }

  async function handleActivate() {
    setError(null);
    try {
      await fetch(`/v1/functions/${uuid}/activate`, {
        method: "PUT",
        headers: {
          "X-CSRFToken": csrfToken,
          "Content-Type": "application/json",
        },
      });
    } catch (e) {
      setError(JSON.stringify(e.body));
    }
    setIsLoading(true);
    setInfoMessage("Function has been activated successfully.");
    getFunction();
  }

  function renderActionButtons(func) {
    const buttons = [];
    switch (func.state) {
      case "inactive":
        buttons.push(
          <Button
            kind="plain"
            label="Activate function"
            onClick={() => {
              handleActivate();
            }}
          />
        );
        if (canDeleteFunction) {
          buttons.push(
            <Button
              kind="plain"
              label="Delete function"
              onClick={() => {
                if (func.state === "inactive") {
                  setDeleteModalFunction(func);
                }
              }}
            />
          );
        }
        buttons.push(
          <Button
            kind="plain"
            label="Update function configuration"
            onClick={() => {
              history.push(`/functions/${func.uuid}/update`);
            }}
          />,
          <CodeDownloadButton
            backendUrl={`/v1/functions/${func.uuid}/download_archive`}
            frontendUrl={
              func.type_metadata.frontend_url
                ? `/v1/functions/${func.uuid}/download_frontend`
                : null
            }
            downloadText="Download function code"
          />
        );
        break;
      case "active":
        buttons.push(
          <Button
            kind="plain"
            label="Deactivate function"
            onClick={() => {
              handleDeactivate();
            }}
          />,
          <Button
            kind="plain"
            label="Update function configuration"
            onClick={() => {
              history.push(`/functions/${func.uuid}/update`);
            }}
          />,
          <CodeDownloadButton
            backendUrl={`/v1/functions/${func.uuid}/download_archive`}
            frontendUrl={
              func.type_metadata.frontend_url
                ? `/v1/functions/${func.uuid}/download_frontend`
                : null
            }
            downloadText="Download function code"
          />,
          <Button
            kind="plain"
            label="Submit a test event"
            onClick={() => {
              setTestingModalOpen(true);
            }}
          />
        );
        break;
      case "creating":
        if (isNarmi && canDeleteFunction) {
          buttons.push(
            <Button
              kind="plain"
              label="Delete function"
              onClick={() => {
                setDeleteModalFunction(func);
              }}
            />
          );
        }
        break;
      case "failed":
        buttons.push(
          <Button
            kind="plain"
            label="Update function configuration"
            onClick={() => {
              history.push(`/functions/${func.uuid}/update`);
            }}
          />,
          <CodeDownloadButton
            backendUrl={`/v1/functions/${func.uuid}/download_archive`}
            frontendUrl={
              func.type_metadata.frontend_url
                ? `/v1/functions/${func.uuid}/download_frontend`
                : null
            }
            downloadText="Download function code"
          />
        );
        if (canDeleteFunction) {
          buttons.push(
            <Button
              kind="plain"
              label="Delete function"
              onClick={() => {
                setDeleteModalFunction(func);
              }}
            />
          );
        }
        break;
      default:
        return [];
    }
    if (isNarmi) {
      buttons.push(
        <Button
          kind="plain"
          label="Override function concurrency"
          onClick={() => {
            setShowConcurrencyModal(true);
          }}
        />
      );
    }
    return buttons;
  }

  function renderCoreVpnRows(func) {
    if (!coreVpnExists) {
      return null;
    }
    if (!func?.type_metadata?.core_vpn_enabled) {
      return (
        <LabeledContentRow
          label="Core VPN access"
          content="Disabled"
          testId="core-vpn-access"
        />
      );
    }
    return (
      <div>
        <LabeledContentRow
          label="Core VPN access"
          content="Enabled"
          testId="core-vpn-access"
        />
        <LabeledContentRow
          label="Core VPN URL"
          content={
            <CopyableCell text={`https://${coreVpnUrl}`} truncateLength={40} />
          }
          testId="core-vpn-url"
          tooltip="Only accessible from within a Narmi Function. Narmi does not provide core credentials."
        />
      </div>
    );
  }

  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Link style={{ marginTop: "14px" }} to="/functions">
          <span
            style={{ fontSize: "20px", cursor: "pointer", color: "#333333" }}
            className="narmi-icon-chevron-left"
          />
        </Link>
        <h1 className="padding--y--l">{narmiFunction?.name}</h1>
      </div>
      <ConditionalMessage message={error} />
      <ConditionalMessage message={infoMessage} kind="success" />

      <Tabs
        selectedIndex={selectedTab}
        onTabChange={(index) => {
          if (narmiFunction?.state === "creating") {
            setError(
              "Please wait for function creation to complete before editing or monitoring. Refresh the page and try again once the state is active."
            );
            return;
          }
          if (narmiFunction?.state === "deleting") {
            setError(
              "Cannot access code and monitoring once function has started deletion."
            );
            return;
          }

          setSelectedTab(index);
        }}
      >
        <Tabs.List>
          <Tabs.Tab label="Overview" tabId="overview" />
          <Tabs.Tab label="Code" tabId="code" />
          <Tabs.Tab label="Monitor" tabId="monitor" />
        </Tabs.List>
        <Tabs.Panel tabId="overview">
          {selectedTab === 0 ? (
            <LoadingSkeleton isLoading={isLoading}>
              <div className="nds-grid nds-span-12 padding--s margin--top">
                <div className="nds-span-8 nds-span-mobile-12">
                  <ContentCard>
                    <Row>
                      <Row.Item>
                        <h3 className="nds-sans padding--bottom">
                          Function details
                        </h3>
                      </Row.Item>
                      <Row.Item shrink>
                        <div className="padding--right--l">
                          <Button
                            kind="plain"
                            endIcon="refresh-cw"
                            onClick={() => {
                              setInfoMessage(null);
                              setIsLoading(true);
                              getFunction();
                            }}
                          />
                        </div>
                      </Row.Item>
                    </Row>
                    <LabeledContentRow
                      label="Description"
                      content={narmiFunction?.description}
                      testId="status-description"
                    />
                    <LabeledContentRow
                      label="State"
                      content={
                        narmiFunction?.state.charAt(0).toUpperCase() +
                        narmiFunction?.state.substr(1).toLowerCase()
                      }
                    />
                    {renderCoreVpnRows(narmiFunction)}
                    <LabeledContentRow
                      label="Type"
                      content={TYPE_MAPPINGS[narmiFunction?.type]?.name}
                    />
                    {narmiFunction?.type === "naf" ? (
                      <div>
                        <LabeledContentRow
                          label="Frontend URL"
                          content={
                            <CopyableCell
                              text={narmiFunction?.type_metadata?.frontend_url}
                              truncateLength={40}
                            />
                          }
                        />
                        <LabeledContentRow
                          label="Backend URL"
                          content={
                            <CopyableCell
                              text={narmiFunction?.lambda_invoke_url}
                              truncateLength={40}
                            />
                          }
                        />
                      </div>
                    ) : narmiFunction?.type === "webhook" ? (
                      <LabeledContentRow
                        label="Function URL"
                        content={
                          <CopyableCell
                            text={narmiFunction?.lambda_invoke_url}
                            truncateLength={40}
                          />
                        }
                      />
                    ) : null}
                    <LabeledContentRow
                      label="Runtime"
                      content={
                        narmiFunction?.type === "naf"
                          ? "Hosted static site and Node.js 18 serverless backend"
                          : "Node.js 18"
                      }
                    />
                    <LabeledContentRow
                      label="Created at"
                      content={new Date(
                        narmiFunction?.created_at
                      ).toLocaleString()}
                    />
                    <LabeledContentRow
                      label="Updated at"
                      content={new Date(
                        narmiFunction?.updated_at
                      ).toLocaleString()}
                    />
                    <LabeledContentRow
                      label="Concurrency"
                      content={narmiFunction?.concurrency}
                    />
                    <LabeledContentRow
                      label={TYPE_MAPPINGS[narmiFunction?.type]?.extraField}
                      content={getTypeMetadataField(narmiFunction)}
                    />
                  </ContentCard>
                </div>
                <div className="nds-span-4 nds-span-mobile-12 padding--s">
                  <ContentCard>
                    <h3 className="nds-sans padding--bottom">Actions</h3>
                    <ul className="list--reset">
                      {narmiFunction &&
                        renderActionButtons(narmiFunction).map((child, idx) => (
                          <li
                            key={idx}
                            className="padding--left--s padding--bottom--xs"
                          >
                            {child}
                          </li>
                        ))}
                    </ul>
                  </ContentCard>
                </div>
              </div>
            </LoadingSkeleton>
          ) : null}
        </Tabs.Panel>
        <Tabs.Panel tabId="code">
          <div className="padding--all">
            {selectedTab === 1 ? <CodeEditor uuid={uuid} /> : null}
          </div>
        </Tabs.Panel>
        <Tabs.Panel tabId="monitor">
          <div className="padding--all">
            {selectedTab === 2 ? (
              <FunctionMonitoringWidget uuid={uuid} />
            ) : null}
          </div>
        </Tabs.Panel>
      </Tabs>
      <div>
        <Dialog
          id="delete-function-dialog"
          footer={
            <div style={{ textAlign: "right" }}>
              <Button
                onClick={() => handleDelete(deleteConfirmInput)}
                disabled={deleteConfirmInput !== deleteModalFunction?.name}
              >
                Delete
              </Button>
            </div>
          }
          onUserDismiss={() => setDeleteModalFunction(null)}
          title="Are you sure you want to delete this function?"
          isOpen={!!deleteModalFunction}
        >
          <div>
            <Row>
              <p>
                Enter the function name <i>{deleteModalFunction?.name}</i> to
                confirm deletion. This cannot be undone.
              </p>
            </Row>
            <Row>
              <TextInput
                label="Function name"
                onChange={(evt) => setDeleteConfirmInput(evt.target.value)}
              />
            </Row>
          </div>
        </Dialog>
        <Dialog
          id="override-concurrency-dialog"
          footer={
            <div style={{ textAlign: "right" }}>
              <Button onClick={() => handlePutConcurrency()}>
                Override concurrency
              </Button>
            </div>
          }
          onUserDismiss={() => setShowConcurrencyModal(false)}
          title="Update function concurrency"
          isOpen={showConcurrencyModal}
        >
          <div>
            <Row>
              <p>
                Enter the desired function concurrency, this will take effect
                immediately. Any requests exceeding the desired concurrency will
                fail with a 429 HTTP status.
              </p>
            </Row>
            <Row>
              <TextInput
                type="text"
                inputMode="numeric"
                label="Concurrency"
                onChange={(evt) => setConcurrencyInput(evt.target.value)}
              />
            </Row>
          </div>
        </Dialog>
        <FunctionTestingDrawer
          isOpen={testingModalOpen}
          setIsOpen={setTestingModalOpen}
          uuid={uuid}
        />
      </div>
    </div>
  );
}
