import React, { useState, useEffect } from "react";
import {
  Row,
  TextInput,
  Button,
  Checkbox,
  LoadingShim,
  Tooltip,
} from "@narmi/design_system";
import ConditionalMessage from "../components/ConditionalMessage";

export default function EnvironmentVarsEditor({
  uuid,
  onChange,
  prepopulateKeys,
}) {
  const [vars, setVars] = useState({});
  const [existingKeys, setExistingKeys] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(true && !!uuid);

  useEffect(() => {
    async function getFunctionSecrets() {
      const resp = await fetch(`/v1/functions/${uuid}/vars`);
      if (!resp.ok) {
        return;
      }
      const secretsBody = await resp.json();
      setVars(secretsBody.vars);
      setExistingKeys(Object.keys(secretsBody.vars));
      onChange(secretsBody.vars);
      setLoading(false);
    }
    if (uuid) {
      getFunctionSecrets();
    }
    if (!uuid && prepopulateKeys) {
      const clean = {};
      Object.keys(prepopulateKeys).forEach((k) => {
        clean[k] = prepopulateKeys[k];
      });
      setVars(clean);
    } else {
      setVars({});
    }
  }, [uuid, prepopulateKeys]);

  function handleKeyChange(key, newKey) {
    // Only allow UPPER_SNAKE_CASE keys
    const upperSnakeCaseKey = newKey
      .toUpperCase()
      .replaceAll(/[^A-Z0-9_]/g, "");
    if (key === upperSnakeCaseKey) {
      return;
    }
    const copy = { ...vars };
    copy[upperSnakeCaseKey] = vars[key];
    delete copy[key];
    setVars(copy);
    onChange(copy);
  }

  function handleValChange(key, newVal) {
    const copy = { ...vars };
    if (copy[key] === undefined) {
      copy[key] = { value: null, is_encrypted: false };
    }
    copy[key].value = newVal;
    setVars(copy);
    onChange(copy);
  }

  function handleKeyDelete(key) {
    const copy = { ...vars };
    delete copy[key];
    setVars(copy);
    onChange(copy);
  }

  function setEncryption(key) {
    if (existingKeys.indexOf(key) > -1) {
      setError("Cannot change encryption of an existing environment variable.");
      return;
    }
    const copy = { ...vars };
    copy[key].is_encrypted = !copy[key].is_encrypted;
    setVars(copy);
    onChange(copy);
  }

  return (
    <div className="padding--y--bottom">
      <h3 className="nds-sans margin--y">Environment variables</h3>
      <ConditionalMessage message={error} />
      {Object.keys(vars).length > 0 ? (
        <Row>
          <Row.Item>
            <strong>Key</strong>
            <Tooltip text="Must be UPPER_SNAKE_CASE and cannot contain symbols.">
              <span className="narmi-icon-info padding--x" />
            </Tooltip>
          </Row.Item>
          <Row.Item>
            <strong>Value</strong>
          </Row.Item>
          <Row.Item shrink>
            <strong>Encrypted</strong>
          </Row.Item>
          <Row.Item shrink>
            <div style={{ width: "72px" }} />
          </Row.Item>
        </Row>
      ) : null}
      <LoadingShim isLoading={isLoading}>
        {Object.keys(vars).map((k) => (
          <div className="padding--top" key={k}>
            <Row>
              <Row.Item>
                <TextInput
                  defaultValue={k}
                  placeholder="Key"
                  onBlur={(evt) => handleKeyChange(k, evt.target.value)}
                />
              </Row.Item>
              <Row.Item>
                <TextInput
                  value={vars[k].value}
                  placeholder="Value"
                  onChange={(evt) => handleValChange(k, evt.target.value)}
                />
              </Row.Item>
              <Row.Item shrink>
                <div className="padding--all">
                  <Checkbox
                    onChange={() => setEncryption(k)}
                    checked={vars[k].is_encrypted}
                  />
                </div>
              </Row.Item>
              <Row.Item shrink>
                <div className="padding--all--s">
                  <Button
                    kind="plain"
                    onClick={() => handleKeyDelete(k)}
                    label="Delete"
                  />
                </div>
              </Row.Item>
            </Row>
          </div>
        ))}

        <div className="padding--y">
          <Button
            kind="plain"
            label="+ New variable"
            onClick={() => handleValChange("", "")}
          />
        </div>
      </LoadingShim>
    </div>
  );
}
