import React, { useState } from "react";
import { ContentCard, Row } from "@narmi/design_system";

export default function FunctionSourceSelector({ onChange }) {
  const [selected, setSelected] = useState(null);

  return (
    <div className="nds-grid" style={{ margin: "0 auto" }}>
      <div
        className="function-card-container nds-span-6 nds-span-tablet-8 margin--top"
        style={{ height: "100%" }}
      >
        <ContentCard
          kind="interactive"
          isSelected={selected === "zip"}
          onClick={() => {
            setSelected("zip");
            onChange("zip");
          }}
          paddingSize="m"
          testId="start-from-scratch-card"
        >
          <Row gapSize="s">
            <Row.Item shrink>
              <h4 className="nds-sans padding--bottom--xs">
                Start from scratch
              </h4>
            </Row.Item>
            <Row.Item>
              <span className="narmi-icon-edit-2" />
            </Row.Item>
          </Row>
          Create your Narmi Function from a zip file you upload.
        </ContentCard>
      </div>
      <div
        className="function-card-container nds-span-6 nds-span-tablet-8 margin--top"
        style={{ height: "100%" }}
      >
        <ContentCard
          kind="interactive"
          isSelected={selected === "template"}
          onClick={() => {
            setSelected("template");
            onChange("template");
          }}
          paddingSize="m"
        >
          <Row gapSize="s">
            <Row.Item shrink>
              <h4 className="nds-sans padding--bottom--xs">
                Start from a template
              </h4>
            </Row.Item>
            <Row.Item>
              <span className="narmi-icon-folder-open" />
            </Row.Item>
          </Row>
          Build your Narmi Function using sample code and configuration for
          common use cases.
        </ContentCard>
      </div>
    </div>
  );
}
