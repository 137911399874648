/* eslint-disable no-shadow,react/require-default-props */
import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { NotificationContext, jsonFromDocument } from "cerulean"; // eslint-disable-line import/no-unresolved
import MessageEditor from "./MessageEditor";
import MessageDisplay from "./MessageDisplay";

import { EditorContext } from "../store/context";
import { saveMessage } from "../store/actions";

const Message = ({ id, message, isReadOnly = false }) => {
  const context = useContext(EditorContext);
  const [isEditing, setIsEditing] = useState(false);
  const { sendNotification } = useContext(NotificationContext);

  const toggleEditor = () => {
    setIsEditing((isEditing) => !isEditing);
  };

  const handleSave = ({ id, message }) => {
    const csrfToken = jsonFromDocument("csrf_token") || "CSRF-TOKEN-NOT-FOUND";
    const response = saveMessage({ [id]: message }, context, csrfToken);
    response.then((statusCode) => {
      if (statusCode === 200) {
        sendNotification({ type: "success", text: "Success" });
      } else {
        sendNotification({ type: "negative", text: "Failed to save changes" });
      }
    });
    toggleEditor();
  };

  return (
    <div className="padding--x--xxs padding--y--xs border--top">
      {!isReadOnly && isEditing ? (
        <MessageEditor
          messageId={id}
          value={message}
          onSave={handleSave}
          onCancel={toggleEditor}
        />
      ) : (
        <MessageDisplay
          id={id}
          message={message}
          onEdit={toggleEditor}
          isReadOnly={isReadOnly}
        />
      )}
    </div>
  );
};

Message.propTypes = {
  /** fluent id of message */
  id: PropTypes.string.isRequired,
  /** full fluent message (including id and whitespace) */
  message: PropTypes.string.isRequired,
  /** true if the locale these messages belong to is read only */
  isReadOnly: PropTypes.bool,
};

export default Message;
