import React from "react";

export default function ConditionalMessage({ message, kind = "error" }) {
  return message ? (
    <div className={`ui ${kind} message margin--y--l`}>
      <div className="ui center aligned container">
        <span className="title">
          {message?.message || typeof message === "object"
            ? JSON.stringify(message)
            : message}
        </span>
      </div>
    </div>
  ) : null;
}
