import React, { createContext, useReducer } from "react";
import PropTypes from "prop-types";
import reducer from "./reducer";

export const EditorContext = createContext();

// Each product will receive this as its initial state.
// (We create a new context for each product)
const initialState = {
  product: null, // the context provider sets this
  isAddingLocale: false,
  isLoadingLocales: false,
  hasLoadingFailed: false,
  hasLocaleAddFailed: false,
  currentLocale: "en", // we always sort 'en' to be the first locale
  readOnlyLocales: ["en-default"],
  locales: {
    default: {
      isEnabled: true,
      isPosting: false,
      hasPostingFailed: false,
      messages: {},
      raw: "",
    },
    en: {
      isEnabled: true,
      isPosting: false,
      hasPostingFailed: false,
      messages: {}, // { id: 'message', id: 'message' }
      visibleMessages: {},
      raw: "",
    },
  },
};

/**
 * The `fi` translation domain cuts across all products, so we
 * initialize state differently on the "institution settings and products" page.
 */
const initialStateFiSettings = {
  product: "fi",
  // The `en` locale is generated by the back end from institution settings.
  // It is the source of truth for current values in settings, and is not editable.
  readOnlyLocales: ["en"],
  isAddingLocale: false,
  isLoadingLocales: false,
  hasLoadingFailed: false,
  hasLocaleAddFailed: false,
  currentLocale: null,
  locales: {
    en: {
      isEnabled: true,
      isPosting: false,
      hasPostingFailed: false,
      messages: {}, // { id: 'message', id: 'message' }
      visibleMessages: {},
      raw: "",
    }
  },
};

const EditorContextProvider = ({ product, children }) => {
  const defaultState = product === "fi" ? initialStateFiSettings : initialState;
  const [state, dispatch] = useReducer(reducer, { ...defaultState, product });

  return (
    <EditorContext.Provider value={[state, dispatch]}>
      {children}
    </EditorContext.Provider>
  );
};

EditorContextProvider.propTypes = {
  product: PropTypes.oneOf(["fi", "bao", "cao", "olb", "app"]).isRequired,
  children: PropTypes.node.isRequired,
};

export default EditorContextProvider;
