import React, { useState } from "react";
import { ContentCard, Row } from "@narmi/design_system";

export default function FunctionTypeSelector(props) {
  const { onChange } = props;
  const [selected, setSelected] = useState(null);

  return (
    <div className="nds-grid padding--bottom" style={{ margin: "0 auto" }}>
      <div
        className="function-card-container nds-span-4 nds-span-tablet-6 margin--top"
        style={{ height: "100%" }}
      >
        <ContentCard
          kind="interactive"
          isSelected={selected === "webhook"}
          onClick={() => {
            setSelected("webhook");
            onChange("webhook");
          }}
          paddingSize="m"
        >
          <Row gapSize="s">
            <Row.Item shrink>
              <h4 className="nds-sans padding--bottom--xs">Webhook</h4>
            </Row.Item>
            <Row.Item>
              <span className="narmi-icon-upload-cloud" />
            </Row.Item>
          </Row>
          Your function will be subscribed to a webhook based on events in the
          Narmi platform.
        </ContentCard>
      </div>
      <div
        className="function-card-container nds-span-4 nds-span-tablet-6 margin--top"
        style={{ height: "100%" }}
      >
        <ContentCard
          kind="interactive"
          isSelected={selected === "schedule"}
          onClick={() => {
            setSelected("schedule");
            onChange("schedule");
          }}
          paddingSize="m"
          testId="schedule-card"
        >
          <Row gapSize="s">
            <Row.Item shrink>
              <h4 className="nds-sans padding--bottom--xs">Schedule</h4>
            </Row.Item>
            <Row.Item>
              <span className="narmi-icon-clock" />
            </Row.Item>
          </Row>
          Your function will be executed on a recurring schedule.
        </ContentCard>
      </div>
      <div
        className="function-card-container nds-span-4 nds-span-tablet-6 margin--top"
        style={{ height: "100%" }}
      >
        <ContentCard
          kind="interactive"
          isSelected={selected === "naf"}
          onClick={() => {
            setSelected("naf");
            onChange("naf");
          }}
          paddingSize="m"
        >
          <Row gapSize="s">
            <Row.Item shrink>
              <h4 className="nds-sans padding--bottom--xs">
                Narmi Application Framework
              </h4>
            </Row.Item>
            <Row.Item>
              <span className="narmi-icon-code" />
            </Row.Item>
          </Row>
          Your function is linked to a NAF app and has a static frontend that
          will be accessed by users.
        </ContentCard>
      </div>
    </div>
  );
}
