/* eslint-disable react/forbid-prop-types */
import React, { useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import {
  Button,
  ContentCard,
  Dialog,
  Popover,
  Row,
  Tabs,
} from "@narmi/design_system";
import PropTypes from "prop-types";
import { CsrfTokenMiddleware, jsonFromDocument } from "cerulean"; // eslint-disable-line import/no-unresolved
import styles from "./styles/OpeningProductsWizard.module.css";
import OpwAddProductPage from "./OpwAddProductPage";
import OpwChangeLog from "./OpwChangeLog";
import OpwProductDetailPage from "./OpwProductDetailPage";
import { ACCOUNT_TYPES } from "./OpwForm";
import {
  filterProductsByType,
  OpwContextProvider,
  useOpwContext,
} from "./OpwContext";
import PreviewList from "./PreviewList";
import Product from "./OpwProduct";
import ProductForm from "./OpwFormValidator";

export const getProductsByCategory = (products, category) =>
  products.reduce((categoryProducts, product) => {
    if (product?.category === category) {
      categoryProducts.push(product);
    }
    return categoryProducts;
  }, []);

const ProductCategoryCards = ({ productsForType, productCategory }) => {
  const { submitForm } = useOpwContext();
  const history = useHistory();
  const [changedPublic, setChangedPublic] = useState(false);
  const productsByCategory = getProductsByCategory(
    productsForType,
    productCategory
  );
  const [selectedProduct, setSelectedProduct] = useState({});
  const [isDeactivateModalOpen, setDeactivateModalOpen] = useState(false);

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const permissions = jsonFromDocument("user_permissions");

  const canEditProducts = permissions.includes(
    "indigo.change_opening_products"
  );

  const OpwProductStateModal = ({
    isModalOpen,
    setModalOpen,
    product,
    onConfirmCallback,
    isDelete = false,
  }) => {
    let title = "";
    let description;
    if (isDelete) {
      title = "Delete";
      description = "permanently deleted";
    } else {
      title = product.public ? "Deactivate" : "Activate";
      description = product.public
        ? "inactive but not deleted"
        : "visible to customers";
    }
    const actions = (
      <div style={{ float: "right" }} className="padding--right">
        <Row alignItems="center">
          <Row.Item shrink>
            <Button
              onClick={() => {
                setModalOpen(false);
              }}
              kind="negative"
              label="Cancel"
            />
          </Row.Item>
          <Row.Item shrink>
            <CsrfTokenMiddleware />
            <Button
              onClick={(evt) => {
                setModalOpen(false);
                onConfirmCallback(evt);
              }}
              label={`Yes, ${title.toLowerCase()}`}
            />
          </Row.Item>
        </Row>
      </div>
    );
    return (
      <Dialog
        isOpen={isModalOpen}
        title={`${title} product`}
        footer={actions}
        onUserDismiss={() => {
          setModalOpen(false);
        }}
        width="385px"
      >
        Are you sure you want to {title.toLowerCase()} {product.name}? It will
        be {description}.
      </Dialog>
    );
  };

  return (
    <div className={`${styles.opwGrid} nds-grid`}>
      {productsByCategory.map((p) => {
        const product = p;
        return (
          <div key={product.id}>
            <ContentCard paddingSize="l" kind="elevated">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="whiteSpace--truncate fontWeight--semibold">
                  {product.name}
                </div>
                <Popover
                  closeOnContentClick
                  content={
                    <div>
                      {canEditProducts ? (
                        <>
                          <div
                            id="opw-state-button"
                            className="padding--x--xs padding--y--xs"
                          >
                            <Row>
                              <Button
                                style={{
                                  color: "var(--font-color-primary)",
                                  width: "100%",
                                }}
                                kind="plain"
                                onClick={() => {
                                  history.push({
                                    pathname: `/products/${product.id}/edit`,
                                  });
                                }}
                                startIcon="edit"
                                label="Edit"
                                size="m"
                              />
                            </Row>
                          </div>
                          <div
                            id="opw-state-button"
                            className="padding--x--xs padding--y--xs"
                          >
                            <Row>
                              <Button
                                style={{
                                  color: "var(--font-color-primary)",
                                  width: "100%",
                                }}
                                kind="plain"
                                onClick={() => {
                                  setSelectedProduct(product);
                                  setDeactivateModalOpen(true);
                                }}
                                startIcon={
                                  product.public ? "slash" : "check-circle"
                                }
                                label={
                                  product.public ? "Deactivate" : "Activate"
                                }
                                size="m"
                              />
                            </Row>
                          </div>
                          <div
                            id="opw-state-button"
                            className="padding--x--xs padding--y--xs"
                          >
                            <Row>
                              <Button
                                style={{
                                  color: "var(--font-color-primary)",
                                  width: "100%",
                                }}
                                kind="plain"
                                onClick={() => {
                                  setSelectedProduct(product);
                                  setDeleteModalOpen(true);
                                }}
                                startIcon="trash"
                                label="Delete"
                                size="m"
                              />
                            </Row>
                          </div>
                        </>
                      ) : (
                        <div
                          id="opw-state-button"
                          className="padding--x--l padding--y--s"
                        >
                          <Row>
                            <Button
                              style={{
                                color: "var(--font-color-primary)",
                                width: "100%",
                              }}
                              kind="plain"
                              onClick={() => {
                                history.push({
                                  pathname: `/products/${product.id}/edit`,
                                });
                              }}
                              startIcon="eye"
                              label="View"
                              size="m"
                            />
                          </Row>
                        </div>
                      )}
                    </div>
                  }
                >
                  <div>
                    <Button
                      id="opw-more-menu-button"
                      endIcon="more-vertical"
                      kind="plain"
                      testId="product-more-button"
                      style={{ height: "24px", borderRadius: "5px" }}
                    />
                  </div>
                </Popover>
              </div>
              <span
                className={styles.opwText}
                style={!product.public ? { color: "#8C8C8C" } : null}
              >
                {product.public ? "Active" : "Inactive"}
              </span>
            </ContentCard>
          </div>
        );
      })}
      <OpwProductStateModal
        isModalOpen={isDeactivateModalOpen}
        setModalOpen={setDeactivateModalOpen}
        product={selectedProduct}
        onConfirmCallback={(evt) => {
          selectedProduct.public = !selectedProduct.public;
          setChangedPublic(!changedPublic);
          submitForm(
            new ProductForm({
              product: new Product({ ...selectedProduct }),
              note: `${selectedProduct.public ? "activated" : "deactivated"}`,
            }),
            evt,
            "edit"
          );
        }}
      />
      <OpwProductStateModal
        isModalOpen={isDeleteModalOpen}
        setModalOpen={setDeleteModalOpen}
        product={selectedProduct}
        onConfirmCallback={(evt) => {
          submitForm(
            new ProductForm({
              product: new Product({ ...selectedProduct }),
              note: `deleted`,
            }),
            evt,
            "delete"
          );
        }}
        isDelete
      />
    </div>
  );
};

const ProductsCards = ({ isBusiness }) => {
  const { modifyingProducts } = useOpwContext();
  const productsForType = filterProductsByType(modifyingProducts, isBusiness);

  return (
    <div className="padding--y--l">
      {productsForType?.length > 0 ? (
        <div>
          {Object.entries(ACCOUNT_TYPES).map(([key, value]) =>
            productsForType.some((p) => key === p.category) ? (
              <div key={key} className="padding--bottom--xl">
                <h3
                  style={{ textTransform: "none" }}
                  className="padding--bottom--m fontFamily--body fontSize--l"
                >
                  {value.pluralToString}
                </h3>
                <ProductCategoryCards
                  productsForType={productsForType}
                  productCategory={key}
                />
              </div>
            ) : (
              ""
            )
          )}
        </div>
      ) : (
        <h3 className="fontSize--heading4 fontFamily--body padding--bottom--xs">
          No {isBusiness ? "B" : "C"}AO products
        </h3>
      )}
    </div>
  );
};

const NoProductsCard = ({ uatProducts, setIsImportModalOpen }) => {
  const { baoEnabled, canImport, caoEnabled, setIsBusiness } = useOpwContext();
  const history = useHistory();
  return (
    <div style={{ width: caoEnabled && baoEnabled ? "888px" : "568px" }}>
      <ContentCard>
        <h3 className="fontSize--heading4 padding--bottom--s">
          Create your product list
        </h3>
        <div className="padding--bottom--s">
          {" "}
          We recommend importing your product list from your UAT environment to
          start. You can also manually create products.{" "}
        </div>
        <Row alignItems="center">
          {uatProducts?.length > 0 && canImport && (
            <Row.Item shrink>
              <Button
                onClick={() => {
                  window.history.pushState({}, "", "products/import");
                  setIsImportModalOpen(true);
                }}
                kind="primary"
                label="Import product list"
              />
            </Row.Item>
          )}
          {caoEnabled && baoEnabled ? (
            <>
              <Row.Item shrink>
                <Button
                  kind="secondary"
                  label="+ Add a new business product"
                  onClick={() => {
                    setIsBusiness(true);
                    history.push("/products/add");
                  }}
                />
              </Row.Item>
              <Row.Item shrink>
                <Button
                  kind="secondary"
                  label="+ Add a new consumer product"
                  onClick={() => {
                    setIsBusiness(false);
                    history.push("/products/add");
                  }}
                />
              </Row.Item>
            </>
          ) : (
            <>
              <Row.Item shrink>
                <Button
                  kind="secondary"
                  label="+ Add a new product"
                  onClick={() => {
                    setIsBusiness(baoEnabled);
                    history.push("/products/add");
                  }}
                />
              </Row.Item>
            </>
          )}
        </Row>
      </ContentCard>
    </div>
  );
};

const ImportModal = ({
  isImportModalOpen,
  setIsImportModalOpen,
  uatProducts,
}) => {
  const { submitForm } = useOpwContext();
  const actions = (
    <div style={{ float: "right" }} className="padding--right">
      <Row alignItems="center">
        <Row.Item shrink>
          <Button
            onClick={() => {
              setIsImportModalOpen(false);
            }}
            kind="negative"
            label="Cancel"
          />
        </Row.Item>
        <Row.Item shrink>
          <CsrfTokenMiddleware />
          <Button
            onClick={(evt) => {
              setIsImportModalOpen(false);
              submitForm(
                { products: uatProducts, note: "Importing all UAT products" },
                evt,
                "import"
              );
            }}
            label="Yes, import all products"
          />
        </Row.Item>
      </Row>
    </div>
  );
  return (
    <Dialog
      isOpen={isImportModalOpen}
      title="Import products"
      footer={actions}
      onUserDismiss={() => {
        setIsImportModalOpen(false);
      }}
      width="385px"
    >
      Are you sure you want to import the product list from UAT? This will
      override all existing products.
    </Dialog>
  );
};

const AoCard = ({ isBusiness, setIsImportModalOpen }) => {
  const history = useHistory();
  const { baoEnabled, canImport, caoEnabled, uatProducts, setIsBusiness } =
    useOpwContext();
  const isAoEnabled = isBusiness ? baoEnabled : caoEnabled;

  const onClickNewAddProduct = () => {
    setIsBusiness(!!isBusiness);
    history.push("/products/add");
  };

  return (
    <div>
      {isAoEnabled ? (
        <>
          <div>
            <ProductsCards isBusiness={isBusiness} />
          </div>
          <Row alignItems="center">
            <Row.Item shrink>
              <Button
                kind="primary"
                label="+ Add a new product"
                onClick={onClickNewAddProduct}
              />
            </Row.Item>
            {uatProducts?.length > 0 && canImport && (
              <Row.Item>
                <Button
                  onClick={() => {
                    window.history.pushState({}, "", "products/import");
                    setIsImportModalOpen(true);
                  }}
                  kind="secondary"
                  label="Import product list"
                />
              </Row.Item>
            )}
          </Row>
        </>
      ) : (
        <div
          style={{ fontStyle: "Italic" }}
          className="fontFamily--body fontSize--heading4 padding--y--xs"
        >
          {isBusiness ? "Business" : "Consumer"} account opening is not active
          for this financial institution. If you wish to discuss{" "}
          {isBusiness ? "business" : "consumer"} account opening, please contact
          support to begin the process.
        </div>
      )}
    </div>
  );
};

const OpwLandingPage = () => {
  const {
    baoEnabled,
    caoEnabled,
    error,
    isBusiness,
    modifyingProducts,
    setIsBusiness,
    uatProducts,
  } = useOpwContext();
  const isAoEnabled = isBusiness ? baoEnabled : caoEnabled;
  const [isChangeLog, setIsChangeLog] = useState(false);
  const [previewOpened, setPreviewOpened] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  return (
    <>
      <div id="opw-top-bar" style={{ height: "64px" }}>
        <div id="opw-top-bar-text">
          <div id="opw-top-bar-text-landing">
            <Row alignItems="center">
              <Row.Item>
                <h2 className="fontSize--heading1">Products</h2>
              </Row.Item>
              {isAoEnabled && (
                <Row.Item shrink>
                  {!isChangeLog && modifyingProducts.length > 0 && (
                    <Button
                      testId="preview-button"
                      onClick={() => {
                        setPreviewOpened(true);
                      }}
                      label="Preview"
                    />
                  )}
                  <Dialog
                    isOpen={previewOpened}
                    title="Preview"
                    onUserDismiss={() => setPreviewOpened(false)}
                    width="846px"
                  >
                    <div>
                      <PreviewList products={modifyingProducts} />
                    </div>
                  </Dialog>
                </Row.Item>
              )}
            </Row>
          </div>
        </div>
      </div>
      <div className={styles.opwContainer}>
        {error && (
          <Row>
            <div className={styles.opwInputError}>
              <div className="fontSize--s narmi-icon-x-circle" />
              {error}
            </div>
          </Row>
        )}
        <Row>
          <div id="opw-product-cards" className={styles.opwCardContainer}>
            {modifyingProducts.length > 0 ? (
              <Tabs
                onTabChange={(index) => {
                  setIsBusiness(index === 1);
                  setIsChangeLog(index === 2);
                }}
              >
                <Tabs.List>
                  <Tabs.Tab label="Consumer Banking" tabId="personal" />
                  <Tabs.Tab label="Business Banking" tabId="business" />
                  <Tabs.Tab label="Change log" tabId="change-log" />
                </Tabs.List>
                <Tabs.Panel tabId="personal" testId="personal-products-tab">
                  <div className="padding--y--l">
                    <AoCard setIsImportModalOpen={setIsImportModalOpen} />
                  </div>
                </Tabs.Panel>
                <Tabs.Panel tabId="business" testId="business-products-tab">
                  <div className="padding--y--l">
                    <AoCard
                      isBusiness
                      setIsImportModalOpen={setIsImportModalOpen}
                    />
                  </div>
                </Tabs.Panel>
                <Tabs.Panel tabId="change-log" testId="change-log-tab">
                  <div className="padding--y--l">
                    <div>
                      <OpwChangeLog />
                    </div>
                  </div>
                </Tabs.Panel>
              </Tabs>
            ) : (
              <NoProductsCard
                uatProducts={uatProducts}
                setIsImportModalOpen={setIsImportModalOpen}
              />
            )}
            <ImportModal
              uatProducts={uatProducts}
              isImportModalOpen={isImportModalOpen}
              setIsImportModalOpen={setIsImportModalOpen}
            />
          </div>
        </Row>
      </div>
    </>
  );
};

const OpeningProductsWizard = ({ settings, attemptedValue, error }) => (
  <OpwContextProvider
    allSettings={settings}
    attemptedValue={attemptedValue}
    error={error}
  >
    <style type="text/css">
      {`
        #institution_settings_container {
          margin: 0px !important;
        }
        #navigation_top_padding {
          display: none !important;
        }
    `}
    </style>
    <Switch>
      <Route exact path="/products">
        <OpwLandingPage />
      </Route>
      <Route
        path="/products/:uuid/edit"
        render={(props) => (
          <OpwProductDetailPage productId={props?.match?.params?.uuid} />
        )}
      />
      <Route exact path="/products/add">
        <OpwAddProductPage />
      </Route>
    </Switch>
  </OpwContextProvider>
);

OpeningProductsWizard.propTypes = {
  settings: PropTypes.object.isRequired,
  attemptedValue: PropTypes.string,
  error: PropTypes.string,
};
OpeningProductsWizard.defaultProps = {
  attemptedValue: "",
  error: "",
};
getProductsByCategory.propTypes = {
  products: PropTypes.array.isRequired,
  category: PropTypes.string.isRequired,
};

export default OpeningProductsWizard;
