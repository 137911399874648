// eslint-disable-next-line import/extensions
import jsonFromDocument from "../../../../../cerulean/src/JsonFromDocument";

// Until we support automated third party translations, we must restrict what FIs
// are able to do on their own in order to avoid operational issues.
//
// As of v2024.8, FIs with the "translations" feature flag enabled should be able
// to view and edit messages one at a time in English only.
const useTranslationPermissions = () => {
  const isNarmi = jsonFromDocument("current_user")?.is_narmi || false;
  const features = Object.entries(
    jsonFromDocument("institution_features", "null") ?? jsonFromDocument("features")
  )
    .filter(([, value]) => value)
    .map(([key]) => key);
  return {
    addLanguage: isNarmi,
    removeLanguage: isNarmi,
    statusChange: isNarmi, // enable or disable a language
    editRaw: isNarmi,
    viewRaw: isNarmi,
    editOtherMessage: isNarmi,
    editEnglishMessage: features.includes("translations") || isNarmi,
  };
};

export default useTranslationPermissions;
