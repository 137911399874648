/* eslint-disable react/forbid-prop-types */
import React from "react";
import { useOpwContext, filterProductsByType } from "./OpwContext";
import BAOAccountSelectionFormField from "./BaoProductSelection";

export default function PreviewList({ products, category = null }) {
  const { isBusiness } = useOpwContext();
  const filteredProducts = filterProductsByType(products, isBusiness);

  return (
    <div id="opw-previews">
      <BAOAccountSelectionFormField
        field="selected_products"
        businessProducts={filteredProducts}
        categorySelected={category}
      />
    </div>
  );
}
