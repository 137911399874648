import React, { useState } from "react";
import { Drawer, Button, LoadingShim, Row } from "@narmi/design_system";
import AceEditor from "react-ace";
import axios from "axios";
import { jsonFromDocument } from "cerulean"; // eslint-disable-line import/no-unresolved
import ConditionalMessage from "../components/ConditionalMessage";

export default function FunctionTestingDrawer({ uuid, isOpen, setIsOpen }) {
  const [payload, setPayload] = useState("{}");
  const [result, setResult] = useState(null);
  const [logs, setLogs] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const csrfToken = jsonFromDocument("csrf_token") || "CSRF-TOKEN-NOT-FOUND";

  async function onSubmit() {
    setIsLoading(true);
    setError(null);
    try {
      JSON.parse(payload);
    } catch (e) {
      setError(
        "Payload is not valid JSON, please correct the errors and try again."
      );
      setIsLoading(false);
      return;
    }
    try {
      const resp = await axios.post(
        `/v1/functions/${uuid}/test`,
        { payload },
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
        }
      );
      try {
        setResult(JSON.parse(resp.data.payload));
      } catch {
        setResult(resp.data.payload);
      }
      setLogs(resp.data.logs.join("\n"));
      setIsLoading(false);
    } catch (e) {
      setError(e.response.data?.message || e.response.statusText);
      setIsLoading(false);
    }
  }

  return (
    <Drawer
      depth="80%"
      showControls={false}
      onUserDismiss={() => setIsOpen(false)}
      position="right"
      isOpen={isOpen}
    >
      <div className="padding--bottom">
        <h2>Submit a test event</h2>
      </div>
      <ConditionalMessage message={error} />
      <LoadingShim isLoading={isLoading}>
        <div className="padding--bottom">
          <p>Invoke your function using a test JSON event.</p>
          <AceEditor
            mode="json"
            theme="github"
            name="test-event-editor"
            onChange={(val) => setPayload(val)}
            width="inherit"
            height="30em"
            value={payload}
            setOptions={{
              enableBasicAutocompletion: true,
              enableLiveAutocompletion: true,
              enableSnippets: true,
            }}
          />
          <Row className="padding--y" style={{ float: "right" }}>
            <Row.Item />
            <Row.Item shrink>
              <div className="padding--top">
                <Button
                  kind="primary"
                  label="Submit test event"
                  onClick={() => onSubmit()}
                />{" "}
              </div>
            </Row.Item>
          </Row>
        </div>
        <div style={{ height: "30em" }}>
          <div className="padding--bottom">
            <h3 className="nds-sans">Result</h3>
          </div>
          <textarea
            readOnly
            value={
              result && typeof result === "object"
                ? JSON.stringify(result, null, 4)
                : result
            }
            style={{
              width: "100%",
              height: "35%",
              overflowY: "scroll",
              border: "none",
            }}
          />
          <div className="padding--y">
            <h3 className="nds-sans">Logs</h3>
          </div>
          <textarea
            readOnly
            value={logs || null}
            style={{
              width: "100%",
              height: "35%",
              overflowY: "scroll",
              border: "none",
            }}
          />
        </div>
      </LoadingShim>
    </Drawer>
  );
}
