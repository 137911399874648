import React, { useState } from "react";

const Collapsible = ({ defaultOpen = false, title, children }) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const togglePanel = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  return (
    <div>
      <div
        role="button"
        tabIndex={0}
        onClick={togglePanel}
        onKeyUp={({ key }) => {
          if (key === "Enter") {
            togglePanel();
          }
        }}
        className={`collapsible_header ${isOpen && "open"}`}
      >
        {title}
      </div>
      <div
        className="collapsible_content"
        style={{ display: isOpen ? "block" : "none" }}
      >
        {children}
      </div>
    </div>
  );
};

export default Collapsible;
