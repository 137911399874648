import React from "react";

export default function Footer() {
  return (
    <div
      className="functions-footer padding--all"
      style={{
        width: "100%",
        bottom: 0,
        left: 0,
        position: "fixed",
        backgroundColor: "var(--bgColor-cloudGrey)",
      }}
    >
      <a
        href="https://www.narmi.com/policies/functions-terms"
        target="_blank"
        rel="noreferrer"
      >
        Terms of Service for Narmi Functions
      </a>
    </div>
  );
}
