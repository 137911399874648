import React, { useContext, useEffect, useState } from "react";
import { Button, Dialog, Row } from "@narmi/design_system";
import { NotificationContext, NotificationContextProvider } from "cerulean"; // eslint-disable-line import/no-unresolved
import AddAccountActivityQuestionDialog from "./AddAccountActivityQuestionDialog";
import Question from "./Question";
import ProductForm from "../OpwFormValidator";


export default function AccountActivityQuestions({ formData, setFormData }) {

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [questionData, setQuestionData] = useState({});
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(undefined)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { sendNotification } = useContext(NotificationContext);

  const questions = formData.product.account_activity_questions || [];

  useEffect(() => {
    if (formData.product.account_activity_questions) {
      setQuestionData(formData.product.account_activity_questions);
    }
  }, [formData]);

  function updateQuestions(newQuestions) {
    const data = new ProductForm({ ...formData });
    data.product.account_activity_questions = newQuestions;
    setFormData(data, "account_activity_questions");
  }

  const isEditing = Number.isInteger(selectedQuestionIndex);

  return (
    <NotificationContextProvider>
      <div className="padding--y fontSize--l fontWeight--bold">
        <Row>
          <div>Account activity questions</div>
        </Row>
        <div className="margin--top--m">
        {questions.map((question, index) => (
          <div className="margin--top--s">
            <Question
              question={question}
              onEdit={() => {
                setQuestionData({
                  question: question.question,
                  answer: question.questionType || "yes/no",
                  options: question.options || [],
                  label: question.label || "",
                })
                setSelectedQuestionIndex(index);
                setIsDialogOpen(true);
              }}
              onDelete={() => {
                setSelectedQuestionIndex(index);
                setDeleteModalOpen(true);
              }}
            />
          </div>)
        )}
        </div>
        <div className="margin--top--m">
          <Button kind="secondary" startIcon="plus" label="Add a question" onClick={() => setIsDialogOpen(true)} />
        </div>
        <AddAccountActivityQuestionDialog
          isOpen={isDialogOpen}
          onClose={() => {
            setSelectedQuestionIndex(null);
            setQuestionData({});
            setIsDialogOpen(false);
          }}
          questionData={questionData}
          setQuestionData={setQuestionData}
          isEditing={isEditing}
          submitChange={(question) => {
            const update = [...questions];
            if (isEditing) {
              update[selectedQuestionIndex] = question;
            } else {
              update.push(question);
            }
            updateQuestions(update);
          }}
        />
        <Dialog
          isOpen={deleteModalOpen}
          width='385px'
          onUserDismiss={() => {
            setSelectedQuestionIndex(null);
            setDeleteModalOpen(false);
          }}
          title="Delete question"
        >
          Are you sure you want to delete this question? This cannot be undone.
          <div className="padding--top--xl">
            <Row justifyContent="end" alignItems="center">
              <Row.Item shrink>
                <Button
                  onClick={() => {
                    setDeleteModalOpen(false);
                  }}
                  kind="plain"
                >
                  Cancel
                </Button>
              </Row.Item>
              <Row.Item shrink>
                <Button
                  onClick={() => {
                    setDeleteModalOpen(false);
                    const update = [...questions]
                    update.splice(selectedQuestionIndex, 1);
                    updateQuestions(update);
                    sendNotification({ type: "success", text: "Question deleted" });
                  }}
                >
                  Yes, delete
                </Button>
              </Row.Item>
            </Row>
          </div>
        </Dialog>
      </div>
    </NotificationContextProvider>
  )
}