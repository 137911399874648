import { JSONEditor } from "@json-editor/json-editor";
import style from "./NarmiTheme.module.css";

/* eslint-disable class-methods-use-this */

class NarmiTheme extends JSONEditor.AbstractTheme {
  getGridRow() {
    const el = document.createElement("div");
    el.classList.add("row");
    el.classList.add(style.jeGridRow);
    return el;
  }

  getFormInputLabel(text, req) {
    const el = document.createElement("label");
    el.appendChild(document.createTextNode(text));
    if (req) el.classList.add(style.required);
    el.classList.add(style.jeLabel);
    return el;
  }

  /* eslint-disable-next-line no-unused-vars */
  getHeader(text, pathDepth) {
    const el = document.createElement("h3");
    if (typeof text === "string") {
      el.textContent = text;
    } else {
      el.appendChild(text);
    }
    el.classList.add(style.jeHeader);

    return el;
  }

  getCheckbox() {
    const el = this.getFormInputField("checkbox");
    el.classList.add(style.jeCheckbox);
    return el;
  }

  getCheckboxLabel(text, req) {
    const el = document.createElement("label");
    el.appendChild(document.createTextNode(`\u00A0${text}`));
    if (req) el.classList.add(style.required);
    return el;
  }

  /* eslint-disable no-param-reassign */
  getMultiCheckboxHolder(controls, label, description, infoText) {
    const el = document.createElement("div");
    el.classList.add("control-group");
    el.classList.add(style.jeControlGroup);

    if (label) {
      label.style.display = "block";
      label.classList.add(style.jeLabel);
      el.appendChild(label);
      if (infoText) label.appendChild(infoText);
    }

    Object.values(controls).forEach((control) => {
      control.style.display = "inline-block";
      control.style.marginRight = "20px";
      el.appendChild(control);
    });

    if (description) el.appendChild(description);

    return el;
  }
  /* eslint-enable no-param-reassign */

  getFormRadioLabel(text, req) {
    const el = document.createElement("label");
    el.appendChild(document.createTextNode(`\u00A0${text}`));
    if (req) el.classList.add(style.required);
    return el;
  }

  /* eslint-disable-next-line no-unused-vars */
  getSelectInput(options, multiple) {
    const select = document.createElement("select");
    if (options) this.setSelectOptions(select, options);
    select.classList.add(style.jeSelect);
    return select;
  }

  getSwitcher(options) {
    const switcher = this.getSelectInput(options, false);
    switcher.classList.add(style.jeSwitcher);
    return switcher;
  }

  getFormInputField(type) {
    const el = document.createElement("input");
    el.setAttribute("type", type);
    if (type === "text") {
      el.classList.add(style.jeInputText);
    }
    return el;
  }

  getFormControl(label, input, description, infoText, formName) {
    const el = document.createElement("div");
    el.classList.add("form-control");
    el.classList.add(style.jeFormControl);

    const ndsInputColumnDiv = document.createElement("div");
    ndsInputColumnDiv.classList.add(style.jeNDSInputColumnDiv);

    if (label) {
      el.appendChild(label);
      if (formName) label.setAttribute("for", formName);
    }
    if ((input.type === "checkbox" || input.type === "radio") && label) {
      input.style.width = "auto"; // eslint-disable-line no-param-reassign
      label.insertBefore(input, label.firstChild);
      if (infoText) label.appendChild(infoText);
    } else if (input.type === "text") {
      const textDiv = document.createElement("div");
      textDiv.classList.add(style.jeInputTextWrapper);

      ndsInputColumnDiv.appendChild(input);
      textDiv.appendChild(ndsInputColumnDiv);

      if (infoText && label) label.appendChild(infoText);
      el.appendChild(textDiv);
    } else {
      if (infoText && label) label.appendChild(infoText);
      el.appendChild(input);
    }
    if (description && input.type === "text") {
      ndsInputColumnDiv.appendChild(description);
    } else if (description) {
      el.appendChild(description);
    }
    return el;
  }

  getIndentedPanel() {
    const el = document.createElement("div");
    el.classList.add("je-indented-panel");
    el.classList.add(style.jeIndentedPanel);
    return el;
  }

  getChildEditorHolder() {
    const el = document.createElement("div");
    el.classList.add(style.jeChildEditorHolder);
    return el;
  }

  getDescription(text) {
    const el = document.createElement("div");
    if (window.DOMPurify) el.innerHTML = window.DOMPurify.sanitize(text);
    else el.textContent = this.cleanText(text);
    el.classList.add(style.jeDescription);
    return el;
  }

  getButtonHolder() {
    const el = document.createElement("div");
    el.classList.add(style.jeButtonHolder);
    return el;
  }
}

JSONEditor.defaults.themes.narmi = NarmiTheme;

export default NarmiTheme;
