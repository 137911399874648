import React from "react";
import { Tooltip } from "@narmi/design_system";

export default function LabeledContentRow({ label, content, tooltip, testId }) {
  return (
    <div className="nds-grid nds-span-9" data-testid={testId}>
      <div className="nds-span-3 nds-span-mobile-12">
        <p className="padding--left--s padding--bottom--xs">{label}:</p>
      </div>
      {tooltip ? (
        <div className="nds-span-6 nds-span-mobile-12">
          <div style={{ display: "flex", gap: "var(--space-s)" }}>
            <p
              className="padding--left--s padding--bottom--xs"
              style={{ marginBlockEnd: 0, whiteSpace: "nowrap" }}
            >
              {content}
            </p>
            <Tooltip text={tooltip}>
              <span className="narmi-icon-info" />
            </Tooltip>
          </div>
        </div>
      ) : (
        <div className="nds-span-6 nds-span-mobile-12">
          <p className="padding--left--s padding--bottom--xs">{content}</p>
        </div>
      )}
    </div>
  );
}
