/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from "react";
import PropTypes from "prop-types";
import Fuse from "fuse.js";
import { TextInput, Row, Button } from "@narmi/design_system";

/**
 * @param {Object} messages
 * @returns {Array} [{ id, message }, { id, message }]
 */
export const messagesToFuseIndex = (messages) =>
  Object.entries(messages).reduce((fuseIndex, [id, message]) => {
    fuseIndex.push({ id, message });
    return fuseIndex;
  }, []);

/**
 * @param {Array} results
 * @returns {Object} messages { messageId: message, messageId, message }
 */
export const fuseResultsToMessages = (results) =>
  results.reduce((messages, { item }) => {
    const { id, message } = item;
    // eslint-disable-next-line no-param-reassign
    messages[id] = message;
    return messages;
  }, {});

const Search = ({ messages, onSearchResults = () => {} }) => {
  const [inputValue, setInputValue] = useState("");

  const fuse = new Fuse(messagesToFuseIndex(messages), { keys: ["message"] });

  const handleInputChange = ({ target }) => {
    const { value } = target;
    setInputValue(value);
    if (value.length > 1) {
      // return messages matching the search query
      const visibleMessages = fuseResultsToMessages(fuse.search(value));
      onSearchResults(visibleMessages);
    } else {
      // return all messages if the query is empty or too short
      onSearchResults(messages);
    }
  };

  return (
    <div className="margin--bottom bgColor--blueGrey padding--all--s rounded--all">
      <Row alignItems="center" gapSize="s">
        <Row.Item>
          <TextInput
            startIcon="filter"
            label="Filter messages"
            value={inputValue}
            onChange={handleInputChange}
          />
        </Row.Item>
        <Row.Item shrink>
          <Button
            kind="negative"
            label="Clear"
            onClick={() => {
              setInputValue("");
              onSearchResults(messages);
            }}
          />
        </Row.Item>
      </Row>
    </div>
  );
};

Search.propTypes = {
  /** map of fluent message ids to full messages */
  messages: PropTypes.object.isRequired,
  /**
   * callback to handle search results
   * called with a `messages` object that contains messages matching the query
   */
  onSearchResults: PropTypes.func,
};

export default Search;
