import React, { useState, useEffect } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import {
  LoadingShim,
  TextInput,
  Row,
  Button,
  Checkbox,
} from "@narmi/design_system";
import axios from "axios";
import { jsonFromDocument } from "cerulean"; // eslint-disable-line import/no-unresolved
import EnvironmentVarsEditor from "./EnvironmentVarsEditor";
import FunctionTypeSelector from "./FunctionTypeSelector";
import FunctionTemplates from "./FunctionTemplateSelector";
import FunctionSourceSelector from "./FunctionSourceSelector";
import ConditionalMessage from "../components/ConditionalMessage";

export default function UploadFunctionForm({ update }) {
  const csrfToken = jsonFromDocument("csrf_token") || "CSRF-TOKEN-NOT-FOUND";
  const hasSecretsPerm = jsonFromDocument("has_secrets_perm") || false;
  const coreVpnExists = jsonFromDocument("core_vpn_exists", false);
  const [narmiFunction, setNarmiFunction] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFrontendFile, setSelectedFrontendFile] = useState(null);
  const [functionName, setFunctionName] = useState(null);
  const [functionSource, setFunctionSource] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [typeMetadata, setTypeMetadata] = useState({});
  const [type, setType] = useState(null);
  const [functionDescription, setFunctionDescription] = useState(null);
  const [coreVpnCheckboxChecked, setCoreVpnCheckboxChecked] = useState(false);
  const [secrets, setSecrets] = useState({});
  const [error, setError] = useState(null);
  const history = useHistory();
  const { uuid } = useParams();
  // Django default is 64MB. Cam be adjusted settings.DATA_UPLOAD_MAX_MEMORY_SIZE
  const MAX_FILES_BYTES_SIZE = 67108864;

  useEffect(() => {
    async function getFunction() {
      const resp = await fetch(`/v1/functions/${uuid}`);
      if (!resp.ok) {
        history.push("/functions");
      }
      const body = await resp.json();
      setNarmiFunction(body);
      setFunctionName(body.name);
      setFunctionDescription(body.description);
      setType(body.type);
      setTypeMetadata(body.type_metadata);
      setIsLoading(false);
    }

    if (!narmiFunction && update) {
      getFunction();
    } else {
      setIsLoading(false);
    }
  }, []);

  function onTemplateChange(t) {
    setSelectedTemplate(t);
  }

  function onFileChange(event, isFrontend = false) {
    if (isFrontend) {
      setSelectedFrontendFile(event.target.files[0]);
      return;
    }
    setSelectedFile(event.target.files[0]);
  }

  function updateMetadata(key, val) {
    setTypeMetadata({ ...typeMetadata, ...{ [key]: val } });
  }

  const checkFileSize = (sFile, name = "File") => {
    if (sFile && sFile.size > MAX_FILES_BYTES_SIZE) {
      const megabytes = MAX_FILES_BYTES_SIZE / 1000000;
      const errorText = `${name} exceeds maximum file size of ${megabytes}MB`;
      setError(errorText);
      setIsLoading(false);
      return false;
    }
    return true;
  };

  async function onFileUpload() {
    const isNaf = type === "naf";
    if (
      functionSource === "template" &&
      selectedTemplate.enforce_type &&
      selectedTemplate.enforce_type !== type
    ) {
      const formattedType =
        selectedTemplate.enforce_type.charAt(0).toUpperCase() +
        selectedTemplate.enforce_type.slice(1);
      setError(
        `The selected template only supports ${String(
          formattedType
        ).toUpperCase()} type functions. Select the correct type to continue.`
      );
      setIsLoading(false);
      return;
    }
    let url = `/v1/functions/${uuid}/update`;
    if (!update && type === "naf") {
      url = "/v1/functions/create_naf";
    } else if (!update) {
      url = "/v1/functions/create";
    }
    setError(null);
    setIsLoading(true);
    if (isNaf) {
      if (!checkFileSize(selectedFrontendFile, "Frontend archive")) {
        return;
      }
      if (!checkFileSize(selectedFile, "Backend archive")) {
        return;
      }
    } else if (!checkFileSize(selectedFile)) {
      return;
    }
    const formData = new FormData();
    if (isNaf && selectedFrontendFile) {
      formData.append(
        "archive_frontend",
        selectedFrontendFile,
        selectedFrontendFile.name
      );
    }
    if (selectedFile) {
      formData.append("archive", selectedFile, selectedFile.name);
    }
    formData.append("name", functionName);
    formData.append("description", functionDescription);
    if (!update) {
      formData.append("source", functionSource);
    }
    const enrichedTypeMetadata = { ...typeMetadata };
    if (coreVpnCheckboxChecked) {
      enrichedTypeMetadata.core_vpn_enabled = true;
    }
    formData.append("type_metadata", JSON.stringify(enrichedTypeMetadata));

    if (functionSource === "template" && selectedTemplate) {
      if (type === "naf" && selectedTemplate.enforce_type !== "naf") {
        setError("Cannot use a non-NAF template for NAF functions.");
        setIsLoading(false);
        return;
      }
      formData.append("template_name", selectedTemplate.filename);
      formData.append("template_static", selectedTemplate.frontend);
    }
    if (!update) {
      formData.append("type", type);
    } else {
      formData.append("type", narmiFunction.type);
    }
    if (hasSecretsPerm) {
      formData.append("environment_vars", JSON.stringify(secrets));
    }
    try {
      const resp = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          "X-CSRFToken": csrfToken,
        },
      });
      let message = update
        ? "Configuration updated successfully."
        : "Your function is being created. Refresh the page to see the latest status.";
      if (isNaf && !update) {
        message =
          "Your function is being created. The frontend URL may take several minutes to become available.";
      }
      window.location.href = `/functions/${
        resp.data.uuid
      }?message=${encodeURIComponent(message)}`;
    } catch (e) {
      setError(e.response.data?.message || e.response.statusText);
      setIsLoading(false);
    }
  }

  function renderTypeSpecificInputs() {
    if (type === "naf") {
      return (
        <>
          <p className="margin--bottom--s">
            Select the request method your NAF application will be using.
          </p>          
            <Checkbox
              label="GET"
              id="get-checkbox"
              value="GET"
              checked={typeMetadata.request_method && typeMetadata?.request_method.includes("GET")}
              onChange={(evt) =>
                updateMetadata(
                  "request_method",
                  evt.target.checked
                    ? (typeMetadata?.request_method || []).concat(evt.target.value)
                    : typeMetadata?.request_method.filter((item) => item !== evt.target.value)
                )
            }
            />
            <Checkbox
              label="POST"
              id="post-checkbox"
              value="POST"
              checked={typeMetadata.request_method && typeMetadata?.request_method.includes("POST")}
              onChange={(evt) =>
                updateMetadata(
                  "request_method",
                  evt.target.checked
                    ? (typeMetadata?.request_method || []).concat(evt.target.value)
                    : typeMetadata?.request_method.filter((item) => item !== evt.target.value)
                )
              }
            />
         
        </>
      );
    }
    if (type === "webhook") {
      return (
        <div>
          <TextInput
            label="Trigger event"
            onChange={(evt) => updateMetadata("event", evt.target.value)}
            value={typeMetadata?.event || undefined}
          />
          <small>
            <a
              href="https://help.narmi.com/article/275-manage-users#history"
              target="_blank"
              rel="noreferrer"
            >
              Valid trigger events
            </a>
          </small>
        </div>
      );
    }
    if (type === "schedule") {
      return (
        <div>
          <TextInput
            label="CRON string"
            onChange={(evt) => updateMetadata("cron", evt.target.value)}
            value={typeMetadata?.cron || undefined}
            testId="cron-string-input"
          />
          <small>
            <a
              href="https://docs.aws.amazon.com/AmazonCloudWatch/latest/events/ScheduledEvents.html#CronExpressions"
              target="_blank"
              rel="noreferrer"
            >
              Cron expression rules
            </a>
          </small>
        </div>
      );
    }
    return null;
  }

  return (
    <div className="padding--y--l">
      <div style={{ display: "flex", alignItems: "center" }}>
        <Link
          style={{ marginTop: "14px" }}
          to={update ? `/functions/${uuid}` : "/functions"}
        >
          <span
            style={{ fontSize: "20px", cursor: "pointer", color: "#333333" }}
            className="narmi-icon-chevron-left"
          />
        </Link>
        <h1 className="padding--y--l">
          {update ? "Update function configuration" : "Create a new function"}
        </h1>
      </div>
      <ConditionalMessage message={error} />
      <LoadingShim isLoading={isLoading}>
        {!update && coreVpnExists && (
          <div className="padding--y--l">
            <Row>
              <Row.Item>
                <Checkbox
                  label="Enable Core VPN access"
                  id="enable-core-vpn-checkbox"
                  testId="enable-core-vpn-checkbox"
                  name="enable-core-vpn"
                  checked={coreVpnCheckboxChecked}
                  onChange={() => {
                    setCoreVpnCheckboxChecked(!coreVpnCheckboxChecked);
                  }}
                />
              </Row.Item>
            </Row>
          </div>
        )}
        {!update && (
          <Row>
            <FunctionSourceSelector onChange={setFunctionSource} />
          </Row>
        )}
        {(update || !!functionSource) && (
          <div className="padding--y--xl">
            <Row>
              <Row.Item>
                <TextInput
                  label="Function name"
                  value={functionName || undefined}
                  onChange={(evt) => setFunctionName(evt.target.value)}
                  testId="function-name-input"
                />
              </Row.Item>
            </Row>
            <div className="padding--y--s" />
            <Row>
              <Row.Item>
                <TextInput
                  label="Description"
                  value={functionDescription || undefined}
                  onChange={(evt) => setFunctionDescription(evt.target.value)}
                  testId="description-input"
                />
              </Row.Item>
            </Row>
            <div className="padding--y--s" />
            {(functionSource === "zip" || update) && (
              <div className="padding--y">
                <Row>
                  {type === "naf" && (
                    <Row.Item>
                      <p className="margin--bottom--s">
                        Select a zip file to be deployed as a static website.
                      </p>
                      <Button
                        label="Select file"
                        onClick={() =>
                          document
                            .getElementById("nf-file-select-frontend")
                            .click()
                        }
                      />
                      <input
                        id="nf-file-select-frontend"
                        type="file"
                        onChange={(e) => {
                          onFileChange(e, true);
                        }}
                        style={{ display: "none" }}
                      />
                      <small className="padding--x">
                        {selectedFrontendFile?.name}
                      </small>
                    </Row.Item>
                  )}
                  <Row.Item>
                    {type === "naf" && (
                      <p className="margin--bottom--s">
                        Select a zip file to deploy backend code.
                      </p>
                    )}
                    <Button
                      label="Select file"
                      onClick={() =>
                        document.getElementById("nf-file-select").click()
                      }
                    />
                    <input
                      id="nf-file-select"
                      type="file"
                      onChange={onFileChange}
                      style={{ display: "none" }}
                      data-testid="file-select-input"
                    />
                    <small className="padding--x">{selectedFile?.name}</small>
                  </Row.Item>
                </Row>
              </div>
            )}
            {functionSource === "template" && (
              <FunctionTemplates onChange={(t) => onTemplateChange(t)} />
            )}
            {!update && (
              <h3 className="nds-sans padding--y">Select function type</h3>
            )}
            <Row>
              <Row.Item>
                {!update && (
                  <FunctionTypeSelector
                    onChange={(t) => {
                      setType(t);
                      setTypeMetadata({});
                    }}
                  />
                )}
              </Row.Item>
            </Row>
            <Row>
              <Row.Item>
                <div className="padding--y">{renderTypeSpecificInputs()}</div>
              </Row.Item>
            </Row>
            {hasSecretsPerm && (
              <Row>
                <Row.Item>
                  <EnvironmentVarsEditor
                    uuid={uuid}
                    onChange={setSecrets}
                    prepopulateKeys={
                      !update &&
                      selectedTemplate &&
                      Object.keys(selectedTemplate.environment_vars).length > 0
                        ? selectedTemplate.environment_vars
                        : null
                    }
                  />
                </Row.Item>
              </Row>
            )}
            <Row>
              <Row.Item />
              <Row.Item shrink>
                <div className="padding--y--s">
                  <Button
                    testId="upload-btn"
                    onClick={() => onFileUpload()}
                    disabled={
                      (!update &&
                        type !== "naf" &&
                        selectedFile === null &&
                        selectedTemplate === null) ||
                      (!update &&
                        type === "naf" &&
                        selectedFrontendFile === null &&
                        selectedTemplate === null) ||
                      functionName === null ||
                      (!update && type === null)
                    }
                  >
                    Upload
                  </Button>
                </div>
              </Row.Item>
            </Row>
          </div>
        )}
      </LoadingShim>
    </div>
  );
}
