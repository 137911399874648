import React, { useEffect, useState } from "react";
import {
  Button,
  ContentCard,
  LoadingSkeleton,
  Popover,
  Row,
} from "@narmi/design_system";

const DownloadButton = ({ url, text = "code" }) => (
  <Button
    kind="plain"
    onClick={() => {
      window.open(url, "_blank");
    }}
  >
    {text}
  </Button>
);
export const CodeDownloadButton = ({
  backendUrl,
  frontendUrl,
  downloadText = "Download code",
}) =>
  frontendUrl ? (
    <Popover
      wrapperDisplay="flex"
      content={
        <div className="padding--all--xs rounded--all--m ">
          <div className="padding--bottom--s">
            <Row>
              <DownloadButton url={frontendUrl} text="Download frontend code" />
            </Row>
          </div>
          <div>
            <Row>
              <DownloadButton url={backendUrl} text="Download backend code" />
            </Row>
          </div>
        </div>
      }
    >
      <Button kind="plain">{downloadText}</Button>
    </Popover>
  ) : (
    <DownloadButton url={backendUrl} text={downloadText} />
  );

export default function FunctionTemplates({ onChange }) {
  const [templates, setTemplates] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  useEffect(() => {
    async function fetchTemplates() {
      if (templates === null) {
        const templatesRes = await fetch("/v1/functions/templates");
        const templatesJson = await templatesRes.json();
        setTemplates(templatesJson.results);
        setIsLoading(false);
      }
    }
    fetchTemplates();
  }, []);
  return (
    <div>
      <LoadingSkeleton isLoading={isLoading}>
        <h3 className="nds-sans">Select a template</h3>
        {templates?.map((t) => {
          let enforceType = "Any";
          if (t.enforce_type === "naf") {
            enforceType = t.enforce_type.toUpperCase();
          } else if (t.enforce_type) {
            enforceType =
              t.enforce_type.charAt(0).toUpperCase() + t.enforce_type.slice(1);
          }
          return (
            <div className="margin--top" key={t.filename + (t.frontend || "")}>
              <ContentCard
                kind="interactive"
                selectable
                isSelected={selectedTemplate === t.filename}
                onClick={() => {
                  setSelectedTemplate(t.filename);
                  onChange(t);
                }}
              >
                <Row>
                  <Row.Item>
                    <p>{t.name}</p>
                  </Row.Item>
                  <Row.Item>
                    <p>{t.description}</p>
                  </Row.Item>
                  <Row.Item shrink>Supports: {enforceType}</Row.Item>
                  <Row.Item shrink>
                    <CodeDownloadButton
                      backendUrl={`/v1/functions/templates/${t.filename}`}
                      frontendUrl={
                        t.frontend
                          ? `/v1/functions/templates/${t.frontend}`
                          : null
                      }
                      downloadText="Download code"
                    />
                  </Row.Item>
                </Row>
              </ContentCard>
            </div>
          );
        })}
      </LoadingSkeleton>
    </div>
  );
}
