import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { LoadingSkeleton, Row, Button, Select } from "@narmi/design_system";
import ConditionalMessage from "../components/ConditionalMessage";

export default function FunctionMonitoringWidget() {
  const TIME_RANGES = {
    "1 hour": 1,
    "3 hours": 3,
    "6 hours": 6,
    "12 hours": 12,
    "24 hours": 24,
    "Last 30 days": 720,
  };

  const LOG_TIME_RANGES = {
    "1 minute": 60000,
    "5 minutes": 300000,
    "15 minutes": 900000,
    "30 minutes": 1800000,
    "1 hour": 3600000,
    "3 hours": 10800000,
    "6 hours": 21600000,
    "12 hours": 43200000,
    "24 hours": 86400000,
    "7 days": 604800000,
  };

  const [narmiFunction, setNarmiFunction] = useState(null);
  const [functionMetrics, setFunctionMetrics] = useState(null);
  const [selectedTimeRange, setSelectedTimeRange] = useState(3);
  const [logTimeRange, setLogTimeRange] = useState("15 minutes");
  const [isLoading, setIsLoading] = useState(true);
  const [isDownloadingLogs, setIsDownloadingLogs] = useState(false);
  const [error, setError] = useState(null);
  const history = useHistory();
  const { uuid } = useParams();

  async function handleTimerangeChange(timeRange) {
    setSelectedTimeRange(timeRange);
    setIsLoading(true);
    const res = await fetch(
      `/v1/functions/${uuid}/metrics?hours_ago=${timeRange}`
    );
    if (!res.ok) {
      const errMessage = (await res.json()) || res.statusText;
      setError(errMessage);
      setIsLoading(false);
    }
    const metricsBody = await res.json();
    setFunctionMetrics(metricsBody);
    setIsLoading(false);
  }

  useEffect(() => {
    async function getFunctionAndMetrics() {
      const res = await fetch(`/v1/functions/${uuid}`);
      if (!res.ok) {
        history.push("/functions");
      }
      const body = await res.json();
      setNarmiFunction(body);
      await handleTimerangeChange(selectedTimeRange);
    }
    if (!narmiFunction) {
      getFunctionAndMetrics();
    }
  }, []);

  async function downloadLogs() {
    setIsDownloadingLogs(true);
    const res = await fetch(
      `/v1/functions/${uuid}/logs?timeRange=${LOG_TIME_RANGES[logTimeRange]}`
    );
    if (!res.ok) {
      const errMessage = (await res.json()) || res.statusText;
      setError(errMessage);
      setIsDownloadingLogs(false);
    }
    const data = await res.json();
    const blob = new Blob([JSON.stringify(data)], { type: "application/json" });
    const elem = window.document.createElement("a");
    elem.href = window.URL.createObjectURL(blob);
    elem.download = `${narmiFunction.uuid}.json`;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
    setIsDownloadingLogs(false);
  }

  return (
    <div>
      <ConditionalMessage message={error} />
      <Row>
        <Row.Item>
          <h3 className="nds-sans padding--y">Metrics</h3>
        </Row.Item>
        <Row.Item shrink>
          <div style={{ width: "12em" }}>
            <Select
              label="Time range"
              value={selectedTimeRange}
              onChange={(timeRange) => handleTimerangeChange(timeRange)}
            >
              {Object.keys(TIME_RANGES).map((t) => (
                <Select.Item value={TIME_RANGES[t]}>{t}</Select.Item>
              ))}
            </Select>
          </div>
        </Row.Item>
      </Row>
      <LoadingSkeleton isLoading={isLoading}>
        <div className="nds-grid nds-span-9">
          <div className="nds-span-3 nds-span-mobile-12">
            <ul className="list--reset">
              <li className="padding--left--s padding--bottom--xs">
                Number of invocations:
              </li>
              <li className="padding--left--s padding--bottom--xs">
                Error count:
              </li>
              <li className="padding--left--s padding--bottom--xs">
                Error rate:
              </li>
              <li className="padding--left--s padding--bottom--xs">
                Avg. execution duration(ms):
              </li>
            </ul>
          </div>
          <div className="nds-span-6 nds-span-mobile-12">
            <ul className="list--reset">
              <li className="padding--left--s padding--bottom--xs">
                {functionMetrics?.invocations}
              </li>
              <li className="padding--left--s padding--bottom--xs">
                {functionMetrics?.errors}
              </li>
              <li className="padding--left--s padding--bottom--xs">
                {functionMetrics?.invocations === 0
                  ? 0
                  : parseFloat(
                      functionMetrics?.errors / functionMetrics?.invocations
                    ).toFixed(3) * 100}{" "}
                %
              </li>
              <li className="padding--left--s padding--bottom--xs">
                {parseFloat(functionMetrics?.duration).toFixed(3)}
              </li>
            </ul>
          </div>
        </div>
      </LoadingSkeleton>

      <h3 className="nds-sans padding--y">Download Logs</h3>
      <Row>
        <Row.Item>
          <div style={{ width: "60%" }}>
            <Select
              label="Time range"
              value={logTimeRange}
              onChange={(range) => setLogTimeRange(range)}
            >
              {Object.keys(LOG_TIME_RANGES).map((ct) => (
                <Select.Item value={ct}>{ct}</Select.Item>
              ))}
            </Select>
          </div>
        </Row.Item>
      </Row>
      <Row>
        <Row.Item shrink>
          <div className="padding--y">
            <Button
              label="Download logs"
              onClick={() => downloadLogs()}
              disabled={isDownloadingLogs}
            />
          </div>
        </Row.Item>
      </Row>
    </div>
  );
}
