import React from "react";
import "regenerator-runtime/runtime";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { jsonFromDocument } from "cerulean"; // eslint-disable-line import/no-unresolved
import FunctionsList from "./FunctionsList";
import UploadFunctionForm from "./UploadFunctionForm";
import FunctionMonitoringWidget from "./FunctionMonitoringWidget";
import FunctionStatus from "./FunctionStatus";
import TermsAcceptance from "./TermsAcceptance";
import Footer from "./Footer";

export default function FunctionsContainer() {
  const acceptedTerms = jsonFromDocument("accepted_terms") || false;

  if (!acceptedTerms) {
    return <TermsAcceptance />;
  }

  return (
    <Router>
      <Switch>
        <Route path="/functions" exact>
          <FunctionsList />
        </Route>
        <Route path="/functions/new" exact>
          <UploadFunctionForm />
        </Route>
        <Route path="/functions/:uuid/monitor">
          <FunctionMonitoringWidget />
        </Route>
        <Route path="/functions/:uuid/update">
          <UploadFunctionForm update />
        </Route>
        <Route path="/functions/:uuid" exact>
          <FunctionStatus />
        </Route>
      </Switch>
      <Footer />
    </Router>
  );
}
