import React from "react";
import { Link } from "react-router-dom";
import { getTitleDisplay } from "./utils";

const ICONS = {
  GENERAL: "narmi-icon-settings-gear",
  "DIGITAL BANKING": "narmi-icon-bank",
  "DIGITAL ACCOUNT OPENING": "narmi-icon-folder-open",
  default: "narmi-icon-folder-open",
  ERRORS: "narmi-icon-alert-octagon",
};

const SettingsMenuSubgroup = ({
  group,
  subgroup,
  active,
  getGroupPath,
  forceClose = false,
}) => (
  <Link
    to={getGroupPath(group, subgroup)}
    className={`item ${
      subgroup === active.subgroup && !forceClose ? "active" : ""
    }`}
    style={{ paddingLeft: "34px" }}
  >
    <span style={{ fontSize: "16px" }} className="nds-typography">
      {getTitleDisplay(subgroup.toLowerCase())}
    </span>
  </Link>
);

const SettingsMenuGroup = ({
  group,
  subgroups,
  active,
  getGroupPath,
  forceClose = false,
}) => {
  const icon = ICONS[group] || ICONS.default;
  const isGroupActive = group === active.group && !forceClose;

  const defaultRoute = getGroupPath(group, Object.keys(subgroups)[0]);

  return (
    <div className={`nds-typography item ${isGroupActive ? "active" : ""}`}>
      {group === "ERRORS" && (
        <hr
          style={{
            marginTop: 20,
            marginBottom: 20,
            marginLeft: 0,
            marginRight: 0,
          }}
        />
      )}
      <Link
        to={defaultRoute}
        className={`nds title ${isGroupActive ? "active" : ""}`}
      >
        <span className={icon} style={{ paddingRight: "9px" }} />
        <p>{getTitleDisplay(group.toLowerCase())}</p>
      </Link>

      <div className={`content ${isGroupActive ? "active" : ""}`}>
        <div className="ui vertical text menu">
          {group !== "ERRORS" &&
            Object.keys(subgroups).map((subgroup) => (
              <SettingsMenuSubgroup
                key={subgroup}
                group={group}
                subgroup={subgroup}
                active={active}
                getGroupPath={getGroupPath}
                forceClose={forceClose}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

const SettingsMenu = ({
  settings,
  active,
  getGroupPath,
  forceClose = false,
}) => (
  <div
    className="column four wide"
    style={{ backgroundColor: "#ffffff", paddingTop: "10px" }}
  >
    <div className="ui vertical text menu accordion nds-typography">
      <h3 style={{ whiteSpace: "nowrap" }}>Institution Settings</h3>
      {Object.keys(settings).map((group) => (
        <SettingsMenuGroup
          key={group}
          group={group}
          subgroups={settings[group]}
          active={active}
          getGroupPath={getGroupPath}
          forceClose={forceClose}
        />
      ))}
    </div>
  </div>
);

export default SettingsMenu;
