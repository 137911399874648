/**
 * Actions with side effects
 */
import ISO6391 from "iso-639-1";
import actionTypes from "./actionTypes";
import { localeDataToFtl, getAllSupportedLocales } from "../fluentUtil";
import {
  getDefaultFtl,
  getInstitutionFtl,
  postInstitutionFtl,
  updateLanguage,
  getSettingValuesFtl,
} from "../api";

const noop = () => {};

/**
 * Fetches default FTL and all FTL files used by the institution
 *
 * @param {String} namespace
 * @param {String} product
 * @param {Array} locales list of ISO 2 letter lang codes
 * @param {Array} context an EditorContext returned from useContext()
 */
export const loadLocales = (product, context) => {
  const [, dispatch] = context;

  dispatch({ type: actionTypes.LOAD_LOCALES });

  const fetchLocales = async () => {
    const ftlsByLocale = {};

    const { institutionFtls, manifest } = await getInstitutionFtl(product);

    if (Object.keys(institutionFtls).length < 1) {
      dispatch({ type: actionTypes.LOAD_LOCALES_FAIL });
    } else {
      Object.keys(institutionFtls).forEach((locale) => {
        ftlsByLocale[locale] = institutionFtls[locale];
      });
      ftlsByLocale.default = await getDefaultFtl(product);
      dispatch({
        type: actionTypes.LOAD_LOCALES_SUCCESS,
        payload: { locales: ftlsByLocale, manifest },
      });
    }
  };

  fetchLocales();
};

/**
 * @param {Array} context an EditorContext returned from useContext()
 */
export const loadFiSettingsLocales = (context) => {
  const [, dispatch] = context;
  dispatch({
    type: actionTypes.LOAD_SETTINGS_LOCALES,
  });

  const fetchLocales = async () => {
    const defaultLocale = {};  // generated from settings
    const savedLocales = {};   // loaded from s3
    const unsavedLocales = {}; // need to support, but not in s3 yet

    defaultLocale.en = await getSettingValuesFtl();
    const { institutionFtls, manifest } = await getInstitutionFtl("fi");

    // add ftls from any previously saved settings locales
    if ("fi" in manifest) {
      Object.keys(institutionFtls).forEach((locale) => {
        savedLocales[locale] = institutionFtls[locale];
      });
    }

    // if other products include non-English locales,
    // add them to `locales` as stubs built from `fi` `en`
    getAllSupportedLocales(manifest).forEach((locale) => {
      if (locale in savedLocales) return;
      unsavedLocales[locale] = defaultLocale.en;
    });

    const locales = {
      ...defaultLocale,
      ...savedLocales,
      ...unsavedLocales,
    };

    dispatch({
      type: actionTypes.LOAD_SETTINGS_LOCALES_SUCCESS,
      payload: {
        locales,
        manifest,
      },
    });
  };

  fetchLocales();
};

/**
 * @param {String} visibleMessages messages returned from search results
 * @param {Array} context an EditorContext returned from useContext()
 */
export const filterMessages = (visibleMessages, context) => {
  const [, dispatch] = context;
  dispatch({ type: actionTypes.FILTER_MESSAGES, payload: { visibleMessages } });
};

/**
 *
 * @param {String} localeCode ISO-639 two letter locale code
 * @param {Array} context an EditorContext returned from useContext()
 */
export const changeLocale = (localeCode, context) => {
  const [, dispatch] = context;
  dispatch({ type: actionTypes.CHANGE_LOCALE, payload: { localeCode } });
};

/**
 * Update an entire FTL file (useful for raw editor)
 * @param {String} ftlString full FTL file content to post
 * @param {Array} context an EditorContext returned from useContext()
 * @param {String} csrfToken
 * @param {Function} sendNotification from `NotificationContext`
 */
export const updateLocale = (
  ftlString,
  context,
  csrfToken,
  sendNotification = noop
) => {
  const [state, dispatch] = context;
  const { product, currentLocale } = state;

  dispatch({ type: actionTypes.UPDATE_LOCALE });

  const postUpdate = async () => {
    const status = await postInstitutionFtl(
      product,
      currentLocale,
      ftlString,
      csrfToken
    );

    if (status === 200) {
      dispatch({
        type: actionTypes.UPDATE_LOCALE_SUCCESS,
        payload: { ftlString },
      });
      sendNotification({
        type: "success",
        text: `Updated ${ISO6391.getName(currentLocale)} translations`,
      });
    } else {
      dispatch({ type: actionTypes.UPDATE_LOCALE_FAIL });
      sendNotification({
        type: "negative",
        text: `Failed to update ${ISO6391.getName(currentLocale)} translations`,
      });
    }
    return status;
  };

  return postUpdate();
};

/**
 * @param {String} message full content of fluent message
 * @param {Array} context an EditorContext returned from useContext()
 * @param {String} csrfToken
 * @param {Function} sendNotification from `NotificationContext`
 */
export const saveMessage = (
  message,
  context,
  csrfToken,
  sendNotification = noop
) => {
  const [state, dispatch] = context;
  const { product, currentLocale } = state;

  // This updates the store for UI state
  dispatch({ type: actionTypes.SET_MESSAGE, payload: { message } });

  // We used to rely on the above `dispatch` to update the store before
  // the `postUpdate` function below reads from it. In React 18, the updates
  // may be bundled, so we must instead build the data we want to post.
  //
  // Ticket for long term fix: <https://github.com/narmi/banking/issues/41825>
  const newLocaleData = {
    ...state.locales[currentLocale],

    messages: {
      ...state.locales[currentLocale].messages,
      ...message,
    },
  };

  const postUpdate = async () => {
    const ftlString = localeDataToFtl(newLocaleData);
    const status = await postInstitutionFtl(
      product,
      currentLocale,
      ftlString,
      csrfToken
    );

    if (status === 200) {
      dispatch({ type: actionTypes.SET_MESSAGE_SUCCESS });
      sendNotification({
        type: "success",
        text: `Updated ${ISO6391.getName(currentLocale)} translations`,
      });
    } else {
      dispatch({ type: actionTypes.SET_MESSAGE_FAIL });
      sendNotification({
        type: "negative",
        text: `Failed to update ${ISO6391.getName(currentLocale)} translations`,
      });
    }
    return status;
  };

  return postUpdate();
};

/**
 * Adds a new locale, populated from our default Narmi locale
 * @param {String} message full content of fluent message
 * @param {Array} context an EditorContext returned from useContext()
 * @param {String} csrfToken
 * @param {Function} sendNotification from `NotificationContext`
 */
export const addLocale = (
  localeCode,
  context,
  csrfToken,
  sendNotification = noop
) => {
  const [state, dispatch] = context;
  const { product } = state;

  dispatch({ type: actionTypes.ADD_LOCALE });

  const postUpdate = async () => {
    const ftlString = localeDataToFtl(state.locales.default);
    const status = await postInstitutionFtl(
      product,
      localeCode,
      ftlString,
      csrfToken
    );

    if (status === 200) {
      dispatch({
        type: actionTypes.ADD_LOCALE_SUCCESS,
        payload: { localeCode },
      });
      sendNotification({
        type: "success",
        text: `${ISO6391.getName(localeCode)} added`,
      });
    } else {
      sendNotification({
        type: "negative",
        text: `Failed to Add ${ISO6391.getName(localeCode)}`,
      });
      dispatch({ type: actionTypes.ADD_LOCALE_FAIL });
    }
    return status;
  };

  return postUpdate();
};

/**
 * Enables a locale (make it visible to users)
 * @param {String} localeCode ISO-639 two letter locale code
 * @param {Array} context an EditorContext returned from useContext()
 * @param {String} csrfToken
 * @param {Function} sendNotification from `NotificationContext`
 */
export const enableLocale = (
  localeCode,
  context,
  csrfToken,
  sendNotification = noop
) => {
  const [state, dispatch] = context;
  const { product } = state;

  const postUpdate = async () => {
    const status = await updateLanguage(
      product,
      localeCode,
      csrfToken,
      "enabled"
    );

    if (status === 200) {
      dispatch({ type: actionTypes.ENABLE_LOCALE_SUCCESS });
      sendNotification({
        type: "success",
        text: `${ISO6391.getName(localeCode)} is now visible to users`,
      });
    } else {
      dispatch({ type: actionTypes.ENABLE_LOCALE_SUCCESS });
      sendNotification({
        type: "negative",
        text: `Failed to enable ${ISO6391.getName(localeCode)}`,
      });
    }
  };

  postUpdate();
};

/**
 * Disables a locale (hide it from users)
 * @param {String} localeCode ISO-639 two letter locale code
 * @param {Array} context an EditorContext returned from useContext()
 * @param {String} csrfToken
 * @param {Function} sendNotification from `NotificationContext`
 */
export const disableLocale = (
  localeCode,
  context,
  csrfToken,
  sendNotification = noop
) => {
  const [state, dispatch] = context;
  const { product } = state;

  const postUpdate = async () => {
    const status = await updateLanguage(
      product,
      localeCode,
      csrfToken,
      "disabled"
    );

    if (status === 200) {
      dispatch({ type: actionTypes.DISABLE_LOCALE_SUCCESS });
      sendNotification({
        type: "success",
        text: `${ISO6391.getName(localeCode)} is now hidden from users`,
      });
    } else {
      dispatch({ type: actionTypes.DISABLE_LOCALE_FAIL });
      sendNotification({
        type: "negative",
        text: `Failed to disable ${ISO6391.getName(localeCode)}`,
      });
    }
  };

  postUpdate();
};

/**
 * "Soft delete" - removes locale tab in editor
 * @param {String} localeCode ISO-639 two letter locale code
 * @param {Array} context an EditorContext returned from useContext()
 * @param {String} csrfToken
 * @param {Function} sendNotification from `NotificationContext`
 */
export const hideLocale = (
  localeCode,
  context,
  csrfToken,
  sendNotification = noop
) => {
  const [state, dispatch] = context;
  const { product } = state;

  const postUpdate = async () => {
    const status = await updateLanguage(
      product,
      localeCode,
      csrfToken,
      "hidden"
    );

    if (status === 200) {
      dispatch({ type: actionTypes.HIDE_LOCALE_SUCCESS });
      sendNotification({
        type: "success",
        text: `Removed ${ISO6391.getName(localeCode)}`,
      });
    } else {
      dispatch({ type: actionTypes.HIDE_LOCALE_FAIL });
      sendNotification({
        type: "negative",
        text: `Failed to remove ${ISO6391.getName(localeCode)}`,
      });
    }
  };

  postUpdate();
};
