/* eslint-disable consistent-return */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Dialog, IconButton, Row } from "@narmi/design_system";
import PropTypes from "prop-types";
import PreviewList from "./PreviewList";
import { useOpwContext } from "./OpwContext";
import OpwForm from "./OpwForm";
import { BUSINESS_APP_TYPES } from "./OpwProduct";
import styles from "./styles/OpeningProductsWizard.module.css";

const OpwConfirmLoseChangesModal = ({
  isModalOpen,
  setModalOpen,
  confirmCallback,
}) => {
  const actions = (
    <div style={{ float: "right" }} className="padding--right">
      <Row alignItems="center">
        <Row.Item shrink>
          <Button
            onClick={() => {
              setModalOpen(false);
            }}
            kind="negative"
            label="Cancel"
          />
        </Row.Item>
        <Row.Item shrink>
          <Button
            onClick={() => {
              confirmCallback();
              setModalOpen(false);
            }}
            label="Yes, discard changes"
          />
        </Row.Item>
      </Row>
    </div>
  );
  return (
    <Dialog
      isOpen={isModalOpen}
      title="Discard changes"
      footer={actions}
      onUserDismiss={() => {
        setModalOpen(false);
      }}
    >
      Do you want to navigate away without saving changes?
    </Dialog>
  );
};

const generateProductTitle = (productId, productCategory) => {
  let productTitle = "No such product";
  if (productId === "" && productCategory) {
    productTitle = `New ${
      productCategory.charAt(0).toUpperCase() + productCategory.slice(1)
    } Account`;

    if (productCategory === "certificate_of_deposit") {
      productTitle = "New CD";
    }

    if (productCategory === "hsa") {
      productTitle = "New HSA account";
    }
  }
  return productTitle;
};

const OpwProductDetailPage = ({ productId, productCategory }) => {
  const history = useHistory();
  const {
    error,
    modifyingProducts,
    setError,
    setIsBusiness,
  } = useOpwContext();
  const [previewOpened, setPreviewOpened] = useState(false);
  const [inProgressProducts, setInProgressProducts] =
    useState(modifyingProducts);
  const [categorySelected, setCategorySelected] = useState(null);
  const [changed, setChanged] = useState(false);
  const [isConfirmLoseChangesOpen, setConfirmLoseChangesOpen] = useState(false);

  const foundProduct = modifyingProducts?.find(
    (product) => product.id === productId
  );
  if (productId && foundProduct) {
    setIsBusiness(
      foundProduct?.exclusive_application_types?.some((appType) =>
        Object.values(BUSINESS_APP_TYPES).includes(appType)
      )
    );
  }
  const productTitle =
    foundProduct?.name || generateProductTitle(productId, productCategory);

  useEffect(() => {
    if (!foundProduct && productId) {
      setError(`product does not exist with id: ${productId} `);
    } else {
      setError("");
    }
  });

  return (
    <>
      <div id="opw-top-bar">
        <div id="opw-top-bar-text">
          <div id="opw-top-bar-text-edit">
            <Row gapSize="small" alignItems="center" justifyContent="end">
              <Row.Item shrink>
                <div style={{ marginLeft: "26px", paddingRight: "8px" }}>
                  <IconButton
                    kind="action"
                    label="Back"
                    name="chevron-left"
                    onClick={() => {
                      if (changed) {
                        setConfirmLoseChangesOpen(true);
                      } else {
                        history.push("/products");
                      }
                    }}
                  />
                </div>
              </Row.Item>
              <div className="nds-row-item whiteSpace--truncate">
                <div className="fontSize--heading1 fontFamily--heading">
                  {productTitle}
                </div>
              </div>
              <Row.Item shrink>
                <div>
                  <Button
                    testId="preview-button"
                    onClick={() => {
                      setPreviewOpened(true);
                    }}
                    label="Preview"
                  />
                  <Dialog
                    isOpen={previewOpened}
                    title="Preview"
                    onUserDismiss={() => setPreviewOpened(false)}
                    width="846px"
                  >
                    <div>
                      <PreviewList
                        category={categorySelected}
                        products={inProgressProducts}
                      />
                    </div>
                  </Dialog>
                </div>
              </Row.Item>
            </Row>
          </div>
        </div>
      </div>
      <div>
        {error && (
          <Row>
            <div className={`${styles.opwInputError} ${styles.opwContainer}`}>
              <div className="fontSize--s narmi-icon-x-circle padding--left--xxl" />
              {error}
            </div>
          </Row>
        )}
        {!error && (
          <OpwForm
            initialProduct={foundProduct || { category: productCategory }}
            buttonTitle="Save changes"
            setInProgressProducts={setInProgressProducts}
            setCategorySelected={setCategorySelected}
            setChanged={setChanged}
          />
        )}
        <OpwConfirmLoseChangesModal
          isModalOpen={isConfirmLoseChangesOpen}
          setModalOpen={setConfirmLoseChangesOpen}
          confirmCallback={() => {
            setInProgressProducts(modifyingProducts);
            history.push("/products");
          }}
        />
      </div>
    </>
  );
};

OpwProductDetailPage.propTypes = {
  productId: PropTypes.string,
};
OpwProductDetailPage.defaultProps = {
  productId: "",
};

export default OpwProductDetailPage;
