import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, ContentCard, Row } from "@narmi/design_system";
import { useEffect, useRef } from "react";
var ProductCard = function (_a) {
    var _b;
    var product = _a.product, onClick = _a.onClick, isSelected = _a.isSelected, _c = _a.hideRecommendedTag, hideRecommendedTag = _c === void 0 ? false : _c, _d = _a.monthlyServiceFeeText, monthlyServiceFeeText = _d === void 0 ? "Monthly service fee" : _d, _e = _a.recommendedLabel, recommendedLabel = _e === void 0 ? "Recommended" : _e, _f = _a.benefitsLabel, benefitsLabel = _f === void 0 ? "Benefits" : _f, _g = _a.moreDetailsLabel, moreDetailsLabel = _g === void 0 ? "More details" : _g;
    var contentRef = useRef(null);
    useEffect(function () {
        var handleLinkClick = function (event) {
            event.stopPropagation();
        };
        if (contentRef.current) {
            var links = contentRef.current.querySelectorAll("a");
            links.forEach(function (link) {
                link.addEventListener("click", handleLinkClick);
            });
        }
        return function () {
            if (contentRef.current) {
                var links = contentRef.current.querySelectorAll("a");
                links.forEach(function (link) {
                    link.removeEventListener("click", handleLinkClick);
                });
            }
        };
    }, [product]);
    var convertMarkdownLinksToHTML = function (markdown) {
        return markdown.replace(/\[([^\]]+)]\(([^)]+)\)/g, '<a href="$2" target="_blank">$1</a>');
    };
    return (_jsx(ContentCard, { kind: "interactive", isSelected: isSelected, onClick: function () { onClick(); }, radiusSize: "m", children: _jsxs("div", { className: "product-card", children: [product.recommended && !hideRecommendedTag && (_jsx("div", { className: "tag", children: recommendedLabel })), _jsxs("div", { className: "product-header", children: [_jsxs(Row, { alignItems: "center", children: [_jsx(Row.Item, { children: _jsx("div", { className: "fontWeight--semibold fontSize--l", style: { color: "RGB(var(--nds-black))" }, children: product.name }) }), isSelected && (_jsx(Row.Item, { shrink: true, children: _jsx("div", { className: "circle", children: _jsx("span", { className: "narmi-icon-check" }) }) }))] }), _jsx("div", { className: "product-description", children: product.description })] }), _jsxs("div", { className: "product-card-body", children: [_jsx("h5", { children: benefitsLabel }), _jsx("ul", { children: (_b = product.details) === null || _b === void 0 ? void 0 : _b.map(function (detail) { return (_jsx("li", { children: _jsx("div", { className: "markdown-content", ref: contentRef, dangerouslySetInnerHTML: {
                                        __html: convertMarkdownLinksToHTML(detail),
                                    } }) }, detail)); }) })] }), _jsxs("div", { className: "product-card-footer", children: [_jsx("h5", { children: monthlyServiceFeeText }), _jsx("div", { children: product.monthly_service_fee || "$0" }), product.more_details_url && (_jsx("div", { className: "margin--top--l margin--bottom--s", children: _jsx(Button, { kind: "plain", size: "s", children: _jsxs("a", { href: "".concat(product.more_details_url), target: "blank", rel: "noreferrer", onClick: function (event) { return event.stopPropagation(); }, children: [product.more_details_copy || moreDetailsLabel, " >"] }) }) }))] })] }) }));
};
export default ProductCard;
