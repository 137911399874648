import React from "react";
import { Button, ContentCard, RadioButtons, Row } from "@narmi/design_system";
import ProductForm, { OpwFormError } from "../OpwFormValidator";
import PermissionedField from "../PermissionedField";
import BrandedCardsDialog from "./components/BrandedCardsDialog";
import useBrandedCards, { brandedCardStruct } from "./hooks/useBrandedCards";

function BrandedCards({ formData, setFormDataAndInProgress, canEditProducts, product }) {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [brandedCardForm, setBrandedCardForm] = React.useState({
    data: brandedCardStruct,
    errors: brandedCardStruct,
  });

  const brandedCards = useBrandedCards(
    product,
    brandedCardForm,
    setBrandedCardForm,
    formData,
    setFormDataAndInProgress
  );

  function onEdit(card) {
    brandedCards.onEditCard(card);
    setIsDialogOpen(true);
  }

  function onChangeRadioButton(event) {
    const data = new ProductForm({ ...formData });
    const selection = event.target.value.toString() === "true";

    data.product.offer_branded_cards = selection;

    if (selection) {
      if (data.product.options == null) {
        data.product.options = {};
      }
      data.product.options.card_images = [];
    } else {
      delete data.product.options.card_images;
    }

    setFormDataAndInProgress(data, "options");
  }

  return (
    <>
      <div className="fontFamily--normal fontWeight--bold padding--bottom--s padding--top--xl">
        Do you want to offer custom debit cards?
        <OpwFormError error={formData.errors.offer_branded_cards} />
      </div>
      <div className="margin--bottom--l">
        <PermissionedField canEdit={canEditProducts}>
          <RadioButtons
            value={formData.product?.offer_branded_cards}
            id="custom_debit_cards"
            style={{ display: "block" }}
            name="custom_debit_cards"
            options={{ Yes: true, No: false }}
            onChange={(event) => onChangeRadioButton(event)}
          />
        </PermissionedField>
      </div>
      {formData.product?.offer_branded_cards && (
        <>
          {formData.product.options?.card_images &&
            formData.product.options.card_images.map((card) => (
              <div
                key={card.issue_code}
                className="margin--bottom--l"
                style={{ maxWidth: "38.875rem" }}
              >
                <ContentCard kind="bordered">
                  <Row>
                    <Row.Item>{card.card_name}</Row.Item>
                    <Row.Item shrink>
                      <Row justifyStart>
                        <Row.Item shrink>
                          <Button
                            as="button"
                            kind="plain"
                            label="Edit"
                            onClick={() => onEdit(card)}
                            type="button"
                            size="s"
                          />
                        </Row.Item>
                        <Row.Item shrink>
                          <Button
                            as="button"
                            kind="plain"
                            label="Delete"
                            onClick={() => brandedCards.deleteImage(card)}
                            type="button"
                            size="s"
                          />
                        </Row.Item>
                      </Row>
                    </Row.Item>
                  </Row>
                </ContentCard>
              </div>
            ))}
          <Button
            as="button"
            kind="plain"
            label="+ Upload images"
            onClick={() => setIsDialogOpen(true)}
            type="button"
          />
        </>
      )}
      <BrandedCardsDialog
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        brandedCardForm={brandedCardForm}
        setBrandedCardForm={setBrandedCardForm}
        formData={formData}
        setFormDataAndInProgress={setFormDataAndInProgress}
        product={product}
      />
    </>
  );
}

export default BrandedCards;
