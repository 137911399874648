/* eslint-disable no-console */
import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
  Redirect,
} from "react-router-dom";
import ISO6391 from "iso-639-1";

import {
  Button,
  Row,
  Tabs,
  Toggle,
  LoadingSkeleton,
  SeparatorList,
  NotificationContext,
  NotificationContextProvider,
  jsonFromDocument,
} from "cerulean"; // eslint-disable-line import/no-unresolved
import EditorContextProvider, { EditorContext } from "./store/context";
import {
  loadLocales,
  loadFiSettingsLocales,
  filterMessages,
  changeLocale,
  addLocale,
  enableLocale,
  disableLocale,
  hideLocale,
} from "./store/actions";

import useTranslationPermissions from "./hooks/useTranslationPermissions";

import Message from "./components/Message";
import Search from "./components/Search";
import LanguageAdder from "./components/LanguageAdder";
import EditRaw from "./components/EditRaw";

// :TODO: this will eventually come from the API,
// based on what products the institution has
const PRODUCTS_MAP = {
  fi: "Institution Settings and Products",
  olb: "Online Banking",
  app: "Mobile application",
  bao: "Business Account Opening",
  cao: "Consumer Account Opening",
};

// :TODO: unit test coverage

/**
 * Return Object.entries of locale objects to use in rendering
 * @param {Object} state
 * @returns {Array} Object.entries of all locales
 */
export const getInstitutionLocales = ({ locales }) =>
  Object.entries(locales).filter(([localeCode]) => localeCode !== "default");

const isReadOnly = (localeCode, state) =>
  state.readOnlyLocales.includes(localeCode);

const isSettingsLocale = (state) => state.product === "fi";

const TranslationsPage = ({ productName }) => {
  const context = useContext(EditorContext);
  const [state] = context;
  const { sendNotification } = useContext(NotificationContext);
  const csrfToken = jsonFromDocument("csrf_token") || "CSRF-TOKEN-NOT-FOUND";
  const permissions = useTranslationPermissions();

  useEffect(() => {
    if (isSettingsLocale(state)) {
      loadFiSettingsLocales(context);
    } else {
      loadLocales(state.product, context);
    }
  }, []);

  const shouldShowActionBar = (localeCode) => [
    permissions.removeLanguage,
    permissions.editRaw,
    permissions.viewRaw && isReadOnly(localeCode, state),
    permissions.statusChange && localeCode !== "en",
  ].some((p) => p);

  const isMessageReadOnly = (localeCode) =>
    !permissions.editEnglishMessage && localeCode === "en" ||
    !permissions.editOtherMessage && localeCode !== "en" ||
    isReadOnly(localeCode, state);

  const handleSearchResults = (visibleMessages) => {
    filterMessages(visibleMessages, context);
  };

  const handleAddLocale = (localeCode) => {
    addLocale(localeCode, context, csrfToken, sendNotification);
  };

  const handleLocaleChange = (tabIndex) => {
    const localeCode = getInstitutionLocales(state).map(([code]) => code)[
      tabIndex
    ];
    changeLocale(localeCode, context);
  };

  const handleLocaleStatusChange = (localeCode, isEnabled) => {
    if (isEnabled) {
      disableLocale(localeCode, context, csrfToken, sendNotification);
    } else {
      enableLocale(localeCode, context, csrfToken, sendNotification);
    }
  };

  const handleRemoveLanguage = (localeCode) => {
    hideLocale(localeCode, context, csrfToken, sendNotification);
  };

  // returns a list of nodes for the appropriate actions
  // for the given locale
  const getLanguageActions = (localeCode) => {
    const result = [];
    const removeLanguage = (
      <Button
        kind="plain"
        size="xs"
        label="Remove Language"
        key={`remove-${localeCode}`}
        onClick={() => handleRemoveLanguage(localeCode)}
      />
    );
    const viewRaw = (
      <EditRaw
        label="View Raw"
        isReadOnly
        key={`view-${localeCode}`}
        localeCode={localeCode}
      />
    );
    const editRaw = (
      <EditRaw
        label="Edit Raw"
        key={`edit-${localeCode}`}
        localeCode={localeCode}
      />
    );

    if (permissions.removeLanguage) {
      result.push(removeLanguage);
    }

    if (permissions.editRaw && !isReadOnly(localeCode, state)) {
      result.push(editRaw);
    }

    if (permissions.viewRaw && isReadOnly(localeCode, state)) {
      result.push(viewRaw);
    }

    return result;
  };

  if (state.hasLoadingFailed) {
    return (
      <main className="padding--all--xl alignChild--top--center">
        <h2>Loading failed.</h2>
      </main>
    );
  }

  return (
    <>
      <div className="bgColor--blueGrey padding--y">
        <h2 className="margin--left--xl">
          <SeparatorList
            separator="|"
            items={[
              <span key="heading">Translations</span>,
              <span
                key="product-name"
                className="fontFamily--default fontWeight--default"
              >
                {productName}
              </span>,
            ]}
          />
        </h2>
      </div>
      <div className="padding--all">
        <Row gapSize="xl">
          <Row.Item shrink>
            <div
              className="padding--all"
              style={{
                width: "260px",
              }}
            >
              <ul className="list--reset">
                {Object.entries(PRODUCTS_MAP).map(([productCode, linkText]) => (
                  <li key={productCode} className="margin--bottom">
                    <NavLink
                      key={productCode}
                      to={`/${productCode}`}
                      activeStyle={{
                        color: "var(--theme-primary)",
                        fontWeight: "var(--font-weight-bold)",
                      }}
                    >
                      {linkText}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </Row.Item>
          <Row.Item>
            <main style={{ maxWidth: "960px" }} className="margin--top--xs">
              <LoadingSkeleton isLoading={state.isLoadingLocales}>
                <Tabs onTabChange={handleLocaleChange}>
                  <Tabs.List>
                    {getInstitutionLocales(state).map(([localeCode]) => {
                      const label = ISO6391.getName(localeCode);
                      const isLocaleReadOnly = isReadOnly(localeCode, state);
                      return (
                        <Tabs.Tab
                          key={localeCode}
                          tabId={localeCode}
                          label={
                            isLocaleReadOnly ? `${label} (Read Only)` : label
                          }
                        />
                      );
                    })}
                    {permissions.addLanguage && !isSettingsLocale(state) && (
                      <div
                        style={{
                          marginLeft: "auto",
                          transform: "translateY(-10px)",
                        }}
                      >
                        <LanguageAdder onLanguageAdd={handleAddLocale} />
                      </div>
                    )}
                  </Tabs.List>
                  {getInstitutionLocales(state).map(
                    ([localeCode, { messages, visibleMessages }]) => {
                      const messagesToRender = visibleMessages || messages;
                      const isLocaleReadOnly = isReadOnly(localeCode, state);
                      return (
                        <Tabs.Panel key={localeCode} tabId={localeCode}>
                          <div className="padding--y">
                            {shouldShowActionBar(localeCode) && (
                              <div className="bgColor--snowGrey margin--bottom padding--y--xs padding--x--m">
                                <Row>
                                  <Row.Item>
                                    <strong>Language Actions</strong>
                                  </Row.Item>
                                  <Row.Item shrink>
                                    <SeparatorList
                                      items={getLanguageActions(
                                        localeCode,
                                        state.product
                                      )}
                                    />
                                  </Row.Item>
                                </Row>
                                {!isLocaleReadOnly &&
                                  localeCode !== "en" &&
                                  !isSettingsLocale(state) &&
                                  permissions.statusChange && (
                                    <div
                                      className="padding--top--s border--top margin--top--s"
                                      style={{ textAlign: "end" }}
                                    >
                                      <Toggle
                                        isActive={
                                          state.locales[localeCode].isEnabled
                                        }
                                        label={
                                          state.locales[localeCode].isEnabled
                                            ? "Visible to users"
                                            : "Not visible to users"
                                        }
                                        onChange={() => {
                                          handleLocaleStatusChange(
                                            localeCode,
                                            state.locales[localeCode].isEnabled
                                          );
                                        }}
                                      />
                                    </div>
                                  )}
                              </div>
                            )}

                            {!isLocaleReadOnly && (
                              <Search
                                messages={messages}
                                onSearchResults={handleSearchResults}
                              />
                            )}

                            <div role="region" aria-live="polite">
                              <LoadingSkeleton
                                isLoading={state.locales[localeCode].isPosting}
                              >
                                {Object.entries(messagesToRender).map(
                                  ([id, message]) => (
                                    <Message
                                      key={id}
                                      id={id}
                                      message={message}
                                      localeCode={localeCode}
                                      isReadOnly={isMessageReadOnly(localeCode)}
                                    />
                                  )
                                )}
                              </LoadingSkeleton>
                            </div>
                          </div>
                        </Tabs.Panel>
                      );
                    }
                  )}
                </Tabs>
              </LoadingSkeleton>
            </main>
          </Row.Item>
        </Row>
      </div>
    </>
  );
};

TranslationsPage.propTypes = {
  /** human readable name of product */
  productName: PropTypes.string.isRequired,
};

const TranslationsContainer = () => (
  <Router basename="/translations">
    {/* :TODO: generate this from list of products the institution actually has */}
    <Switch>
      {/**
       * FIXME: ROUTING USES HARD CODED PRODUCTS MAP
       * let's try loading data on the container level so we can get
       * the value of PRODUCTS_MAP via state, and pass that value down
       * to TranslationsPage as a prop.
       */}
      {Object.entries(PRODUCTS_MAP).map(([productCode, productName]) => (
        <Route key={productCode} path={`/${productCode}`}>
          <EditorContextProvider product={productCode}>
            <NotificationContextProvider>
              <TranslationsPage productName={productName} />
            </NotificationContextProvider>
          </EditorContextProvider>
        </Route>
      ))}
      <Route key="root" path="/">
        <Redirect to="/fi" />
      </Route>
    </Switch>
  </Router>
);

export default TranslationsContainer;
