import React from "react";
import { Button } from '@narmi/design_system'

export default function FedNowReports() {

  async function downloadReport(reportType){
    const url = `/v1/fednow/report_download?report_type=${reportType}`
    window.open(url, "_blank");
  }
  
  return <>
  <tr className="item">
    <td>
      <h5 className="ui header">
        <div className="content">
          FedNow transactions report
          <div className="sub header">All terminal FedNow transactions for the trailing 30-days.</div>
        </div>
      </h5>
    </td>
    <td>
      <Button
        onClick={ () => downloadReport("terminal")}
        as="a"
        kind="plain"
      >
        Download
      </Button>
    </td>
  </tr>
  <tr className="item">
    <td>
      <h5 className="ui header">
        <div className="content">
          FedNow non-terminal transactions report
          <div className="sub header">All non-terminal FedNow transactions currently.</div>
        </div>
      </h5>
    </td>
    <td>
      <Button
        onClick={ () => downloadReport("nonterminal")}
        as="a"
        kind="plain"
      >
        Download
      </Button>
    </td>
  </tr>
  </>
}
