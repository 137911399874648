import React, { useRef, useState } from "react";

import { Button, Dialog, Select } from "@narmi/design_system";

const BillingStats = ({ availableBillingMonths }) => {
  const downloadBillingReport = useRef();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(availableBillingMonths[0]);

  const handleSubmit = () => {
    downloadBillingReport.current.submit();
  };

  return (
    <>
      <tr className="item">
        <td>
          <h5 className="ui header">
            <div className="content">
              Billing
              <div className="sub header">A list of billing statistics visible only to Narmi.</div>
            </div>
          </h5>
        </td>
        <td>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setIsDialogOpen(true);
            }}
            as="a"
            kind="plain"
          >
            Download
          </Button>
        </td>
      </tr>
      <form
        action={`/billing/${selectedMonth.uuid}`}
        method="GET"
        ref={downloadBillingReport}
      >
        <input type="hidden" name="uuid" value={selectedMonth.uuid} />
        <Dialog
          isOpen={isDialogOpen}
          footer={
            <div style={{ textAlign: "right" }} className="margin--top--l">
              <Button kind="negative" onClick={() => setIsDialogOpen(false)}>
                Cancel
              </Button>
              <span className="margin--left--s">
                <Button onClick={handleSubmit}>Download</Button>
              </span>
            </div>
          }
          title="Download billing report"
          onUserDismiss={() => {
            setIsDialogOpen(false);
          }}
        >
          <div className="padding--top--m">
            <Select
              label="Month"
              onChange={(e) => setSelectedMonth(e)}
              defaultValue={selectedMonth}
            >
              {availableBillingMonths.map((report) => (
                <Select.Item value={report} key={report}>
                  {report.name}
                </Select.Item>
              ))}
            </Select>
          </div>
        </Dialog>
      </form>
    </>
  );
};

export default BillingStats;
