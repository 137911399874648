import React from "react";
import { FileUploader } from "cerulean";
import { Alert, Button, Dialog, Row, TextInput } from "@narmi/design_system";
import useBrandedCards from "../hooks/useBrandedCards";

function BrandedCardsDialog({
  isDialogOpen,
  setIsDialogOpen,
  brandedCardForm,
  setBrandedCardForm,
  formData,
  setFormDataAndInProgress,
  product,
}) {
  const brandedCards = useBrandedCards(
    product,
    brandedCardForm,
    setBrandedCardForm,
    formData,
    setFormDataAndInProgress
  );

  function onCancel() {
    brandedCards.clearForm();
    setIsDialogOpen(false);
  }

  function onSubmit() {
    brandedCards.onSubmit();
    setIsDialogOpen(false);
  }

  return (
    <Dialog
      isOpen={isDialogOpen}
      onUserDismiss={() => onCancel()}
      title="Add an image"
      width="764px"
    >
      <p className="margin--bottom--l">Please upload your card image. It must be 224px by 142px.</p>
      <p className="margin--bottom--s fontWeight--semibold">Details:</p>
      <div className="margin--bottom--s">
        <TextInput
          label="Card name"
          type="text"
          name="card_name"
          onChange={(event) => brandedCards.onTextInputChange(event)}
          onBlur={(event) => brandedCards.onTextInputBlur(event, "card name")}
          value={brandedCardForm.data.card_name ?? ""}
          error={brandedCardForm.errors.card_name ?? ""}
        />
      </div>
      <div className="margin--bottom--l">
        <TextInput
          label="Issue code"
          name="issue_code"
          onChange={(event) => brandedCards.onTextInputChange(event)}
          onBlur={(event) => brandedCards.onTextInputBlur(event, "issue code")}
          value={brandedCardForm.data.issue_code ?? ""}
          error={brandedCardForm.errors.issue_code ?? ""}
        />
      </div>
      <p className="margin--bottom--s fontWeight--semibold">Image file:</p>
      <div className="margin--bottom--xl">
        <div className="margin--bottom--s">
          <Alert
            kind="error"
            isActive={brandedCardForm.errors.image_url}
            onUserDismiss={() => brandedCards.onUserDismiss()}
          >
            <span>{brandedCardForm.errors.image_url}</span>
          </Alert>
        </div>
        <FileUploader
          docType=""
          value={brandedCardForm.data.image_url ?? ""}
          sendRequest={(file) => brandedCards.uploadImage(file)}
          onChange={() => undefined}
          expand
        />
      </div>
      <Row alignItems="center" justifyContent="end">
        <Row.Item shrink>
          <Button
            as="button"
            kind="negative"
            label="Cancel"
            onClick={() => onCancel()}
            type="button"
          />
        </Row.Item>
        <Row.Item shrink>
          <Button
            as="button"
            label="Save"
            onClick={() => onSubmit()}
            type="button"
            disabled={!brandedCards.canSubmit()}
          />
        </Row.Item>
      </Row>
    </Dialog>
  );
}

export default BrandedCardsDialog;
