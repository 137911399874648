import React, { useState } from "react";
import { TokenInput } from "@narmi/design_system";

export default function({ questionData, setQuestionData }) {

  const options = (questionData.options || []).filter(option => option.trim() !== "");
  const [inputValue, setInputValue] = useState("");

  return (
    <>
    <div className="margin--top--s">
      <TokenInput
        onInputChange={(event) => {
          setInputValue(event.target.value);
        }}
        onTokensChange={(newOptions) => {
          setQuestionData({ ...questionData, options: newOptions });
        }}
        inputValue={inputValue}
        tokens={options}
      />
    </div>
    </>
  );
}