import actionTypes from "./actionTypes";
import { ftlToLocaleData, isLocaleEnabled } from "../fluentUtil";

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.LOAD_LOCALES: {
      return { ...state, isLoadingLocales: true };
    }

    case actionTypes.LOAD_LOCALES_FAIL: {
      return {
        ...state,
        isLoadingLocales: false,
        hasLoadingFailed: true,
      };
    }

    case actionTypes.LOAD_LOCALES_SUCCESS: {
      const { locales, manifest } = action.payload;
      const localeData = {};

      // Locales are returned from our translations API
      // in alphabetical order.
      // We want the 'en' locale tab to always show first.
      const enLocale = Object.entries(locales).find(([code]) => code === "en");
      const sortedLocaleEntries = Object.entries(locales).filter(
        ([code]) => code !== "en"
      );
      sortedLocaleEntries.unshift(enLocale);

      // convert ftl strings from payload into
      // useful objects for the store
      sortedLocaleEntries.forEach(([locale, ftlString]) => {
        const messages = ftlToLocaleData(ftlString);
        localeData[locale] = {
          isEnabled: isLocaleEnabled(locale, state.product, manifest),
          messages,
          visibleMessages: messages,
          raw: ftlString,
        };
      });

      // Merge institution-specific locale messages against default
      // messages to ensure any new keys we added to the default ftl
      // show up in the editor for each institution locale
      const defaultData = localeData.default;
      Object.entries(localeData)
        .filter(([locale]) => locale !== "default")
        .forEach(([locale, { messages, visibleMessages, raw }]) => {
          localeData[locale] = {
            ...localeData[locale],
            messages: {
              ...defaultData.messages,
              ...messages,
            },
            visibleMessages: {
              ...defaultData.visibleMessages,
              ...visibleMessages,
            },
            ...defaultData.raw,
            ...raw,
          };
        });

      return {
        ...state,
        isLoadingLocales: false,
        locales: localeData,
        manifest,
      };
    }

    case actionTypes.LOAD_SETTINGS_LOCALES: {
      return { ...state, isLoadingLocales: true };
    }

    case actionTypes.LOAD_SETTINGS_LOCALES_FAIL: {
      return {
        ...state,
        isLoadingLocales: false,
        hasLoadingFailed: true,
      };
    }

    case actionTypes.LOAD_SETTINGS_LOCALES_SUCCESS: {
      const { locales, manifest } = action.payload;
      const localeData = {};

      // Adds locales found under `fi` from s3 _and_ the English locale
      // generated from setting values
      Object.entries(locales).forEach(([locale, ftlString]) => {
        const messages = ftlToLocaleData(ftlString);
        localeData[locale] = {
          isEnabled: isLocaleEnabled(locale, state.product, manifest),
          messages,
          visibleMessages: messages,
          raw: ftlString,
        };
      });

      return {
        ...state,
        isLoadingLocales: false,
        locales: localeData,
        manifest,
      };
    }

    case actionTypes.FILTER_MESSAGES: {
      const { currentLocale } = state;
      const newState = { ...state };
      const { visibleMessages } = action.payload;
      newState.locales[currentLocale].visibleMessages = visibleMessages;
      return newState;
    }

    case actionTypes.ENABLE_LOCALE_SUCCESS: {
      const { currentLocale } = state;
      const newState = { ...state };
      newState.locales[currentLocale].isEnabled = true;
      return newState;
    }

    case actionTypes.ENABLE_LOCALE_FAIL: {
      return state;
    }

    case actionTypes.DISABLE_LOCALE_SUCCESS: {
      const { currentLocale } = state;
      const newState = { ...state };
      newState.locales[currentLocale].isEnabled = false;
      return newState;
    }

    case actionTypes.DISABLE_LOCALE_FAIL: {
      return state;
    }

    case actionTypes.HIDE_LOCALE_SUCCESS: {
      const { currentLocale } = state;
      const newState = { ...state };
      delete newState.locales[currentLocale];
      return newState;
    }

    case actionTypes.HIDE_LOCALE_FAIL: {
      return state;
    }

    case actionTypes.CHANGE_LOCALE: {
      const { localeCode } = action.payload;
      return {
        ...state,
        currentLocale: localeCode,
      };
    }

    case actionTypes.UPDATE_LOCALE: {
      const { currentLocale } = state;
      const newState = { ...state };
      newState.locales[currentLocale].isPosting = true;
      return newState;
    }

    case actionTypes.UPDATE_LOCALE_FAIL: {
      const { currentLocale } = state;
      const newState = { ...state };
      newState.locales[currentLocale].isPosting = false;
      newState.locales[currentLocale].hasPostingFailed = true;
      return newState;
    }

    case actionTypes.UPDATE_LOCALE_SUCCESS: {
      const { ftlString } = action.payload;
      const { currentLocale } = state;
      const newState = { ...state };
      newState.locales[currentLocale] = {
        ...newState.locales[currentLocale],
        isPosting: false,
        hasPostingFailed: false,
        messages: ftlToLocaleData(ftlString),
        visibleMessages: ftlToLocaleData(ftlString),
        raw: ftlString
      };
      return newState;
    }

    case actionTypes.SET_MESSAGE: {
      const { currentLocale } = state;
      const { message } = action.payload;
      const newState = { ...state };
      const localeToUpdate = newState.locales[currentLocale];
      localeToUpdate.isPosting = true;
      localeToUpdate.hasPostingFailed = false;
      localeToUpdate.messages = {
        ...localeToUpdate.messages,
        ...message,
      };
      localeToUpdate.visibleMessages = {
        ...localeToUpdate.messages,
        ...message,
      };
      return newState;
    }

    case actionTypes.SET_MESSAGE_FAIL: {
      const { currentLocale } = state;
      const newState = { ...state };
      const localeToUpdate = newState.locales[currentLocale];
      localeToUpdate.isPosting = false;
      localeToUpdate.hasPostingFailed = true;
      return newState;
    }

    case actionTypes.SET_MESSAGE_SUCCESS: {
      const { currentLocale } = state;
      const newState = { ...state };
      const localeToUpdate = newState.locales[currentLocale];
      localeToUpdate.isPosting = false;
      localeToUpdate.hasPostingFailed = false;
      return newState;
    }

    case actionTypes.ADD_LOCALE: {
      return {
        ...state,
        isAddingLocale: true,
        hasLocaleAddFailed: false,
      };
    }

    case actionTypes.ADD_LOCALE_FAIL: {
      return {
        ...state,
        isAddingLocale: false,
        hasLocaleAddFailed: true,
      };
    }

    case actionTypes.ADD_LOCALE_SUCCESS: {
      const { localeCode } = action.payload;
      const newState = { ...state };

      // copy default locale messages into new locale,
      // with the locale disabled by default
      newState.locales[localeCode] = {
        ...newState.locales.default,
        isEnabled: false,
      };

      return {
        ...newState,
        isAddingLocale: false,
        hasLocaleAddFailed: false,
      };
    }

    default: {
      return { ...state };
    }
  }
};

export default reducer;
