/* eslint-disable react/require-default-props */
import React, { useState, useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Dialog, Row, Button } from "@narmi/design_system";
import { NotificationContext, jsonFromDocument } from "cerulean"; // eslint-disable-line import/no-unresolved

import AceEditor from "react-ace";
import { EditorContext } from "../store/context";
import { updateLocale } from "../store/actions";
import { localeDataToFtl, validateFtl } from "../fluentUtil";

const EditRaw = ({ localeCode, isReadOnly = false, label = "View Raw" }) => {
  const context = useContext(EditorContext);
  const [state] = context;
  const ftlString = state.locales[localeCode].raw;
  const defaultFtlString =
    state.product === "fi"
      ? localeDataToFtl(state.locales.en)
      : localeDataToFtl(state.locales.default);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [rawValue, setRawValue] = useState(ftlString);
  const aceRef = useRef(null);
  const { sendNotification } = useContext(NotificationContext);

  useEffect(() => {
    setRawValue(ftlString);
  }, [ftlString]);

  const handleViewRaw = () => {
    setIsDialogOpen(true);
  };

  const handleCopy = () => {
    if (navigator) {
      navigator.clipboard.writeText(aceRef.current.editor.getValue()).then(
        () => {
          sendNotification({
            type: "success",
            text: `copied ${localeCode}.ftl to clipboard`,
          });
        },
        () => {
          sendNotification({
            type: "negative",
            text: `failed to copy ${localeCode} to clipboard`,
          });
        }
      );
    }
  };

  const handleSave = () => {
    const currentFtlString = aceRef.current.editor.getValue();
    const { isValid, reason } = validateFtl(
      ftlString,
      currentFtlString,
      defaultFtlString || {},
      { allowAddMessage: true, allowRemoveMessage: true }
    );
    if (isValid) {
      const csrfToken =
        jsonFromDocument("csrf_token") || "CSRF-TOKEN-NOT-FOUND";
      const response = updateLocale(currentFtlString, context, csrfToken);

      response.then((statusCode) => {
        if (statusCode === 200) {
          sendNotification({ type: "success", text: "Success" });
        } else {
          sendNotification({
            type: "negative",
            text: "Failed to save changes",
          });
        }
      });

      setIsDialogOpen(false);
    } else {
      sendNotification({
        type: "negative",
        text: `Can't save message: ${reason}`,
      });
    }
  };

  const handleDismiss = () => {
    // reset the input value if the user dismisses without saving
    setRawValue(ftlString);
    setIsDialogOpen(false);
  };

  return (
    <>
      <Button kind="plain" size="xs" label={label} onClick={handleViewRaw} />
      <Dialog
        width="75vw"
        title={`${isReadOnly ? "Viewing" : "Editing"} ${localeCode}.ftl`}
        isOpen={isDialogOpen}
        onUserDismiss={() => {
          handleDismiss();
        }}
        footer={
          <Row justifyContent="end" alignItems="center">
            <Row.Item shrink>
              <Button
                kind="negative"
                label="Cancel"
                onClick={() => {
                  handleDismiss();
                }}
              />
            </Row.Item>
            <Row.Item shrink>
              <Button kind="secondary" label="Copy" onClick={handleCopy} />
            </Row.Item>
            {!isReadOnly && (
              <Row.Item shrink>
                <Button kind="primary" label="Save" onClick={handleSave} />
              </Row.Item>
            )}
          </Row>
        }
      >
        <AceEditor
          readOnly={isReadOnly}
          focus
          mode="fluent"
          theme={isReadOnly ? null : "fluent"}
          name="editor-en"
          showPrintMargin={false}
          showGutter={false}
          value={rawValue}
          ref={aceRef}
          height="50vh"
          width="68vw"
          cursorStart={1}
          fontSize={14}
          wrapEnabled
          setOptions={{
            tabSize: 4,
          }}
        />
      </Dialog>
    </>
  );
};

EditRaw.propTypes = {
  /** ISO locale code */
  localeCode: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool,
  label: PropTypes.string,
};

export default EditRaw;
