import React from "react";
import { getTitleDisplay } from "./utils";
import Setting from "./Setting";

// Settings that have a value preview hidden in the settings list
const HIDE_VALUE_SETTING_NAMES = ["OAO_ASK_ADDITIONAL_FIELDS"];

const SettingsList = ({
  settingsList,
  getSettingPath,
  group,
  subgroup,
  searchTerm,
  currentUserIsNarmi,
}) => (
  <div>
    <div className="segment nds-typography">
      <h4 className="nds-sans" id={`${group}_${subgroup.replace(/ /g, "_")}`}>
        {settingsList.length !== 0
          ? getTitleDisplay(subgroup.toLowerCase())
          : null}
      </h4>
      <hr />
      <div className="settings">
        {settingsList.length !== 0
          ? settingsList.map((setting, index) => (
              <div key={`${setting.name}_${group}`}>
                {index > 0 ? <hr /> : null}
                <Setting
                  setting={setting}
                  editRoute={getSettingPath(setting)}
                  searchTerm={searchTerm}
                  currentUserIsNarmi={currentUserIsNarmi}
                  hideSettingValue={HIDE_VALUE_SETTING_NAMES.includes(
                    setting.name
                  )}
                />
              </div>
            ))
          : ""}
      </div>
    </div>
  </div>
);

export default SettingsList;
