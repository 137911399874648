import React from "react";

const Iframe = ({ title, src, height, width, frameBorder }) => (
  <div>
    <iframe
      title={title}
      src={src}
      height={height}
      width={width}
      frameBorder={frameBorder}
    />
  </div>
);

export default Iframe;
