import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import { CsrfTokenMiddleware, jsonFromDocument } from "cerulean"; // eslint-disable-line import/no-unresolved
import { BUSINESS_APP_TYPES, PERSONAL_APP_TYPES } from "./OpwProduct";
import { settingByName } from "../institution_settings/SettingsPageRouter";

/**
 * @typedef { import("./OpwFormValidator").Product } Product
 * @param {Array<Product>} products - products to be sorted
 * @returns {Array<Product>} in-place sorted array
 */
export function sortProducts(products) {
  function categoryCompare(a, b) {
    if (
      (a.category === "checking" && b.category !== "checking") ||
      (a.category === "savings" &&
        ["certificate_of_deposit", "money_market"].includes(b.category)) ||
      (a.category === "money_market" && b.category === "certificate_of_deposit")
    ) {
      return -1;
    }
    if (
      (b.category === "checking" && a.category !== "checking") ||
      (b.category === "savings" &&
        ["certificate_of_deposit", "money_market"].includes(a.category)) ||
      (b.category === "money_market" && a.category === "certificate_of_deposit")
    ) {
      return 1;
    }
    return 0;
  }
  return products.sort((a, b) => {
    if (a.category === b.category) {
      return a.display_order - b.display_order;
    }
    return categoryCompare(a, b);
  });
}

/**
 *
 * @param {Product} product - product within sortedProducts that needs reordering
 * @param {Array<Product>} sortedProducts - sorted products by item.display_order where one
 *                                          product is out of order
 * @returns {Array<Product>} - a deep copy of sortedProducts that has been reordered
 *                             by item.display_order
 */
export function reorderProducts(product, sortedProducts) {
  let products = cloneDeep(sortedProducts);
  const idx = products.findIndex((p) => p.id === product.id);
  if (idx === -1) {
    // if product not in products, no need to reorder
    return products;
  }
  products = products.filter((p) => p.id !== product.id);
  // display order is 1-based
  const newArrPos = product.display_order - 1;
  if (newArrPos === 0) {
    // insert in front
    products = [product, ...products];
  } else if (newArrPos === products.length) {
    // insert at the end
    products = [...products, product];
  } else {
    products.splice(newArrPos, 0, product);
  }
  // reset display orders for the rest of the products
  products = products.map((obj, i) => {
    const p = obj;
    p.display_order = i + 1;
    return p;
  });
  return products;
}

/**
 *
 * @param {Product} formData Product
 * @param {string} operation add, delete, edit
 * @param {Array<Product>} products [formData]
 * @returns
 */
export function setModifiedProduct(formProduct, products, operation) {
  let ret = sortProducts(cloneDeep(products));
  const product = formProduct.toObject();
  let idx = ret.findIndex((p) => p.id === product.id);
  if (operation === "delete" && idx >= 0) {
    // remove current product
    ret = ret.filter((p) => p.id !== product.id);
    // reset display_order
    ret = ret.map((obj, i) => {
      const p = obj;
      p.display_order = i + 1;
      return p;
    });
    return ret;
  }
  if (operation === "add") {
    // inserting at the end, idx is length
    idx = ret.length;
    // if form didn't set display order, set to idx
    product.display_order = product.display_order ? product.display_order : idx;
    ret.push(product);
  } // default "edit"
  ret = reorderProducts(product, ret);
  return ret;
}

export function filterProductsByType(products, isBusiness) {
  const filteredProducts = products?.filter((product) => {
    const foundType = product.exclusive_application_types?.some((r) =>
      isBusiness
        ? Object.values(BUSINESS_APP_TYPES).includes(r)
        : Object.values(PERSONAL_APP_TYPES).includes(r)
    );
    if (
      foundType ||
      product.exclusive_application_types === undefined ||
      product.exclusive_application_types?.length === 0
    ) {
      return true;
    }
    return false;
  });
  return filteredProducts;
}

export const OpwContext = createContext({
  products: {},
  modifyingProducts: [],
  setModifyingProducts: () => null,
  error: "",
  setError: () => null,
  setNote: () => null,
  submitForm: () => null,
  isBusiness: false,
  setIsBusiness: () => null,
  caoEnabled: false,
  baoEnabled: false,
  institutionFeatures: {},
});

export const OpwContextProvider = ({
  children,
  allSettings,
  attemptedValue,
  error,
}) => {
  let productsDict = {};
  let caoEnabled = false;
  let baoEnabled = false;
  let debitCardRequiredByProductEnabled = false;
  let parsingError = null;
  let uatProducts = null;
  let canImport = false;
  const features = jsonFromDocument("institution_features");
  try {
    productsDict = settingByName(allSettings, "CORE_OPENING_PRODUCTS");
    caoEnabled = features.new_account_opening === true;
    baoEnabled = features.business_account_opening === true;
    debitCardRequiredByProductEnabled =
      settingByName(
        allSettings,
        "INSTITUTION_ALLOW_DEBIT_CARD_REQUIRED_BY_PRODUCT"
      ).value === "true";
    uatProducts = sortProducts(
      jsonFromDocument("initial_state")?.opw?.uat_products || []
    );
    const currProducts = JSON.parse(productsDict.value);
    if (uatProducts.length === currProducts.length) {
      uatProducts.forEach((uatProduct, mProduct) => {
        if (!isEqual(uatProduct, currProducts[mProduct])) {
          canImport = true;
        }
      });
    } else {
      canImport = true;
    }
  } catch (e) {
    parsingError =
      "The product wizard is having trouble loading your products, please contact support.";
  }
  const [newError, setError] = useState(error || parsingError);
  const productsValue =
    error && attemptedValue != null ? attemptedValue : productsDict.value;
  const [products, setProducts] = useState({});
  const [modifyingProducts, setModifyingProducts] = useState([]);
  const [isBusiness, setIsBusiness] = useState(baoEnabled && !caoEnabled);
  const [note, setNote] = useState("");
  const [settingValue, setSettingValue] = useState("");
  const [shouldSubmitOpwForm, setShouldSubmitOpwForm] = useState(false);
  const hsaEnabled = !!features.hsa;

  const opwForm = useRef();
  /**
   *
   * @param {ProductForm} formData
   * @param {*} evt
   * @param {String} operation: oneOf("edit", "add", "delete", null)
   */
  const submitForm = (formData, evt, operation = null, previousID = null) => {
    let ret;
    if (operation !== "import") {
      if (!formData.validate()) {
        const formErrors = [];
        Object.entries(formData.errors).forEach(([k, v]) =>
          formErrors.push(`${k}: ${v}`)
        );
        setError(`Fix form errors: ${formErrors}`);
        return;
      }
      evt.preventDefault();
      formData.cleanupDisclosures();
      ret = setModifiedProduct(formData.product, modifyingProducts, operation);
      if (formData.product.id !== previousID) {
        ret.forEach((p, index) => {
          if (p.id === previousID) {
            ret[index].id = formData.product.id;
          }
        });
      }
      setNote(
        `${formData.product.id} - ${formData.product.name} ${formData.note}`
      );
    } else {
      ret = formData.products;
      setNote(formData.note);
    }
    setSettingValue(JSON.stringify(ret));
    setShouldSubmitOpwForm(true);
  };

  useEffect(() => {
    if (!shouldSubmitOpwForm) return;
    setShouldSubmitOpwForm(false);
    opwForm.current.submit();
  }, [shouldSubmitOpwForm]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const unmodifiedProducts = productsDict;
    const ret = [];
    try {
      if (
        JSON.parse(productsValue).length !== 0 &&
        modifyingProducts.length === 0
      ) {
        JSON.parse(productsValue).forEach((p, idx) => {
          const product = p;
          if (!product.display_order) {
            product.display_order = idx + 1;
          }
          ret.push(product);
          unmodifiedProducts[p.id] = product;
        });
        setProducts(unmodifiedProducts);
        const sortedProducts = sortProducts(ret);
        const mProducts = sortedProducts.map((obj, idx) => {
          const p = obj;
          p.display_order = idx + 1;
          return p;
        });
        setModifyingProducts(mProducts);
      }
    } catch (e) {
      setError("There was an error loading products. Please contact support.");
    }
  });

  return (
    <OpwContext.Provider
      value={{
        products,
        modifyingProducts,
        setModifyingProducts,
        setError,
        error: newError,
        submitForm,
        isBusiness,
        setIsBusiness,
        caoEnabled,
        baoEnabled,
        uatProducts,
        canImport,
        debitCardRequiredByProductEnabled,
        hsaEnabled,
        institutionFeatures: features,
      }}
    >
      <form id="opw_submit" method="POST" ref={opwForm}>
        <input type="hidden" name="setting_name" value="products" />
        <input
          type="hidden"
          id="opw_note_value"
          name="note_value"
          value={note}
        />
        <input
          type="hidden"
          id="opw_setting_value"
          name="setting_value"
          value={settingValue}
        />
        <CsrfTokenMiddleware />
      </form>
      {children}
    </OpwContext.Provider>
  );
};

export const useOpwContext = () => useContext(OpwContext);
