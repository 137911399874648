import axios from "axios";

import React, { useState, useEffect } from "react";
import { LoadingSkeleton, Button, Row } from "@narmi/design_system";
import AceEditor from "react-ace";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/ext-language_tools";

import { jsonFromDocument } from "cerulean"; // eslint-disable-line import/no-unresolved
import ConditionalMessage from "../components/ConditionalMessage";
import FunctionTestingDrawer from "./FunctionTestingDrawer";

export default function CodeEditor({ uuid }) {
  const csrfToken = jsonFromDocument("csrf_token") || "CSRF-TOKEN-NOT-FOUND";
  const [editorValue, setEditorValue] = useState(null);
  const [error, setError] = useState(null);
  const [infoMessage, setInfoMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [testingDrawerOpen, setTestingDrawerOpen] = useState(false);

  useEffect(() => {
    async function fetchCode() {
      const resp = await fetch(`/v1/functions/${uuid}/code`);
      if (!resp.ok) {
        const err = resp.json();
        setError(err);
        setIsLoading(false);
        return;
      }
      const fileContents = await resp.text();
      setEditorValue(fileContents);
      setIsLoading(false);
    }
    fetchCode();
  }, [uuid]);

  function handleEditorChange(newValue) {
    setEditorValue(newValue);
  }

  async function handleCodeDeploy() {
    setInfoMessage(null);
    setError(null);
    setIsLoading(true);
    try {
      await axios.post(
        `/v1/functions/${uuid}/code_update`,
        { content: editorValue },
        {
          headers: {
            "Content-Type": "application/json",
            "X-CSRFToken": csrfToken,
          },
        }
      );
    } catch (e) {
      setError(
        e.response?.data?.message || e.response?.statusText || e.message
      );
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    setInfoMessage("Function handler successfully updated.");
  }

  const onLoad = ({ session }) => {
    if (session) {
      setTimeout(() => {
        if (session.$worker) {
          session.$worker.send("setOptions", [
            {
              esversion: 11,
              esnext: false,
            },
          ]);
        }
      }, 1000);
    }
  };

  return (
    <div className="padding--all" style={{ width: "inherit" }}>
      <ConditionalMessage message={error} />
      <ConditionalMessage message={infoMessage} kind="success" />
      <LoadingSkeleton isLoading={isLoading}>
        <AceEditor
          mode="javascript"
          theme="github"
          name="narmi-function-editor"
          onChange={(val) => handleEditorChange(val)}
          onLoad={onLoad}
          width="inherit"
          value={editorValue}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            enableSnippets: true,
          }}
          style={{ zIndex: 0 }}
        />
        <div className="padding--y">
          <Row>
            <Row.Item>
              <Button
                label="Submit a test event"
                kind="plain"
                onClick={() => setTestingDrawerOpen(true)}
              />
            </Row.Item>
            <Row.Item shrink>
              <Button label="Deploy code" onClick={() => handleCodeDeploy()} />
            </Row.Item>
          </Row>
        </div>
      </LoadingSkeleton>
      <FunctionTestingDrawer
        uuid={uuid}
        isOpen={testingDrawerOpen}
        setIsOpen={setTestingDrawerOpen}
      />
    </div>
  );
}
