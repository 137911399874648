import React from "react";
import { useClipboard } from "use-clipboard-copy";
import { Button } from "@narmi/design_system";

// Copied from https://github.com/narmi/design_system/blob/05f355c578ce4c67ef3ddd91e83799202606b10b/.storybook/helpers/TokenTable.jsx#L57-L81
const CopyableCell = ({ title, text, truncateLength }) => {
  const clipboard = useClipboard({
    copiedTimeout: 500,
  });
  return (
    <div>
      <span>{title ? `${title}: ` : ""}</span>
      <Button
        kind="plain"
        type="button"
        style={{ cursor: "pointer", color: "RGB(var(--nds-black))" }}
        onClick={() => clipboard.copy(text)}
        endIcon="copy"
      >
        {clipboard.copied
          ? "Copied"
          : `${text?.substring(0, truncateLength || 20)}...`}
      </Button>
    </div>
  );
};

export default CopyableCell;
