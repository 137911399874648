import React from "react";
import ReactMarkdown from "react-markdown";
import { useHistory, useLocation } from "react-router-dom";
import { Button, SeparatorList, Tooltip } from "@narmi/design_system";
import { CsrfTokenMiddleware, jsonFromDocument } from "cerulean"; // eslint-disable-line import/no-unresolved

const theme =
  document.getElementById("institution") &&
  JSON.parse(document.getElementById("institution").textContent);
const PRIMARY_COLOR_MUTED = theme && `${theme.primary_color}19`;

export const DynamicHighlightText = ({ text, highlight, className }) => {
  if (highlight && text.toLowerCase().includes(highlight.toLowerCase())) {
    const startIdx = text.toLowerCase().indexOf(highlight.toLowerCase());
    return (
      <div className={className}>
        {text.slice(0, startIdx)}
        <mark style={{ backgroundColor: PRIMARY_COLOR_MUTED }}>
          {text.slice(startIdx, startIdx + highlight.length)}
        </mark>
        {text.slice(startIdx + highlight.length)}
      </div>
    );
  }
  return (
    <div className={className} style={{ lineHeight: "1rem" }}>
      {text}
    </div>
  );
};

const SettingActions = ({ setting, editRoute }) => {
  const items = [];
  const history = useHistory();
  const isProduction = jsonFromDocument("is_production");

  const qp = new URLSearchParams(useLocation().search);

  if (setting.is_editable) {
    items.push(
      <Button
        kind="plain"
        onClick={() => history.push(`${editRoute}?${qp.toString()}`)}
        label="Edit"
      />
    );
  }

  if (setting.type === "EmailTemplateSettingType") {
    const sendEmailUrl = `/institution_settings/${setting.name}/send_sample_email`;
    if (isProduction) {
      items.push(
        <form
          id={`${setting.name}_sample_email`}
          action={sendEmailUrl}
          method="POST"
          data-testid="send-sample-email"
        >
          <CsrfTokenMiddleware />
          <input type="hidden" name="setting_name" value={setting.name} />
          <Button kind="plain" type="submit" label="Get a sample email" />
        </form>
      );
    } else {
      items.push(
        <Button kind="plain" as="a">
          <a href={sendEmailUrl} target="_blank" rel="noreferrer">
            View sample email
          </a>
        </Button>
      );
    }
  }

  return (
    <div className="right cell actions">
      <SeparatorList items={items} />
    </div>
  );
};

const SettingValue = ({ setting }) => (
  <div className="value cell">
    {setting.type === "ColorSettingType" ? (
      <span
        className="narmi-icon-solid-circle"
        style={{
          marginRight: "8px",
          color: `${setting.display_value}`,
          fontSize: "20px",
        }}
      />
    ) : null}
    {setting.display_value !== undefined ? setting.display_value : ""}
  </div>
);

const NotVisibleTag = () => <span className="not-visible-tag">Not visible</span>;

const ErrorTag = ({ message }) => (
  <Tooltip text={message}>
    <span
      className="narmi-icon-alert-octagon fontSize--l"
      style={{ color: "var(--color-errorDark)", marginLeft: 7 }}
    />
  </Tooltip>
);

const WarningTag = ({ message }) => (
  <Tooltip text={message}>
    <span
      className="narmi-icon-alert-triangle fontSize--l"
      style={{ color: "orange", marginLeft: 7 }}
    />
  </Tooltip>
);

const InfoTag = ({ message }) => (
  <Tooltip text={message}>
    <span
      className="narmi-icon-info fontSize--l"
      style={{ color: "RGB(var(--nds-black))", marginLeft: 7 }}
    />
  </Tooltip>
);

const Tags = ({ tags }) => {
  const sortTagsByCategory = (unsortedTags) => {
    const categories = { ERROR: 0, WARNING: 1, INFO: 2 };

    return unsortedTags.sort((a, b) => {
      const categoryDiff = categories[a.category] - categories[b.category];
      const messageDiff = a.message.localeCompare(b.message);
      return categoryDiff || messageDiff;
    });
  };

  const tagToComponent = (tag) => {
    const tagToComponentMap = {
      WARNING: <WarningTag message={tag.message} key={`${tag.name}_${tag.category}`} />,
      ERROR: <ErrorTag message={tag.message} key={`${tag.name}_${tag.category}`} />,
      INFO: <InfoTag message={tag.message} key={`${tag.name}_${tag.category}`} />,
    };

    return tagToComponentMap[tag.category];
  };

  if (!tags) return null;

  const sortedTags = sortTagsByCategory(tags);
  return sortedTags.map((tag) => tagToComponent(tag));
};

const Setting = ({
  setting,
  editRoute,
  searchTerm,
  currentUserIsNarmi,
  hideSettingValue = false,
}) => (
  <div className="item setting">
    <div style={{ display: "flex", flexDirection: "row" }}>
      <h5 className="ui header left cell" style={{ flexGrow: 3 }}>
        <div className="content nds-typography">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <DynamicHighlightText text={setting.human_facing_name} highlight={searchTerm} />
            {currentUserIsNarmi && !setting.is_visible_to_staff ? <NotVisibleTag /> : null}
            <Tags tags={setting.tags} />
          </div>
        </div>
        <ReactMarkdown>{setting.help_text}</ReactMarkdown>
      </h5>
      <SettingActions setting={setting} editRoute={editRoute} />
    </div>
    {!hideSettingValue && <SettingValue setting={setting} />}
  </div>
);

export default Setting;
