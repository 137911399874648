var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useState, useEffect } from "react";
var useIsInDialog = function (ref) {
    var _a = __read(useState(false), 2), isInDialog = _a[0], setIsInDialog = _a[1];
    useEffect(function () {
        var _a, _b;
        if (ref === null || ref === void 0 ? void 0 : ref.current) {
            var parent_1 = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.parentElement;
            while (parent_1) {
                var role = (_b = parent_1 === null || parent_1 === void 0 ? void 0 : parent_1.getAttribute) === null || _b === void 0 ? void 0 : _b.call(parent_1, "role");
                if (role === "dialog") {
                    setIsInDialog(true);
                    break;
                }
                parent_1 = parent_1 === null || parent_1 === void 0 ? void 0 : parent_1.parentElement;
            }
        }
    }, [ref]);
    return isInDialog;
};
export default useIsInDialog;
