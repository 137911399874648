/* eslint-disable camelcase */
import React from "react";
import { Button } from "@narmi/design_system";

const DeleteUserScreen = ({
  backFunction = () => {},
  user_to_delete_email,
  user_to_delete_id,
  csrf_token,
}) => {
  const submitForm = (evt) => {
    evt.preventDefault();
    document.getElementById("delete_user_submit").submit();
  };

  return (
    <div className="column sixteen wide">
      <Button kind="plain" label="Back" onClick={backFunction} />
      <h3 style={{ marginTop: "0.5rem" }}>Delete {user_to_delete_email}?</h3>
      <form id="delete_user_submit" method="POST">
        <p>
          If you delete {user_to_delete_email}, every dashboard owned by that
          account will also be deleted!
        </p>
        <div className="margin--top">
          <Button label="Delete user" onClick={submitForm} />
        </div>
        <input type="hidden" name="user_id" value={user_to_delete_id} />
        <input type="hidden" name="csrfmiddlewaretoken" value={csrf_token} />
        <input
          type="hidden"
          name="sisense_user_management_action_type"
          value="delete"
        />
      </form>
    </div>
  );
};

export default DeleteUserScreen;
