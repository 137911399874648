/* eslint-disable react/require-default-props */
import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Dialog, Combobox, Row, Button } from "@narmi/design_system";
import ISO6391 from "iso-639-1";
import Filters from "byzantine/src/filters.ts";

import { EditorContext } from "../store/context";

export const LANG_NAME_DELIM = "·";

/**
 * @param {Array} ignoredLocales locales to ignore by 2 letter locale code
 * @returns {Array}
 */
const getAddableLocales = (ignoredLocales = []) =>
  ISO6391.getAllCodes().filter((code) => !ignoredLocales.includes(code));

const LanguageAdder = ({ onLanguageAdd = () => {} }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const context = useContext(EditorContext);
  const [state] = context;
  const addableLocales = getAddableLocales(Object.keys(state.locales));

  const handleCancel = () => {
    setSelectedLanguage(null);
    setIsDialogOpen(false);
  };

  const handleConfirm = () => {
    onLanguageAdd(selectedLanguage);
    setIsDialogOpen(false);
  };

  const handleLanguageChange = (itemValue) => {
    if (!itemValue) {
      setSelectedLanguage(null);
    } else {
      const name = itemValue.split(LANG_NAME_DELIM)[0].trim();
      const code = ISO6391.getCode(name);
      setSelectedLanguage(code);
    }
  };

  // enable confirm button only if a language is selected
  const confirmButtonExtraProps = { disabled: true };
  if (selectedLanguage) {
    confirmButtonExtraProps.disabled = false;
  }

  return (
    <>
      <Button
        label="Add language"
        startIcon="plus"
        kind="negative"
        size="s"
        onClick={() => {
          setIsDialogOpen(true);
        }}
      />
      <Dialog
        title="Add a new language"
        isOpen={isDialogOpen}
        onUserDismiss={() => {
          setIsDialogOpen(false);
        }}
        footer={
          <Row alignItems="center" justifyContent="end">
            <Row.Item shrink>
              <Button kind="negative" label="Cancel" onClick={handleCancel} />
            </Row.Item>
            <Row.Item shrink>
              <Button
                testId="confirm-language-add"
                kind="primary"
                label="Add language"
                onClick={handleConfirm}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...confirmButtonExtraProps}
              />
            </Row.Item>
          </Row>
        }
      >
        <div className="padding--y">
          <Combobox label="Select a language" onChange={handleLanguageChange}>
            {addableLocales.map((localeCode) => {
              const item = {
                name: ISO6391.getName(localeCode),
                nativeName: Filters.capitalizeFirstLetter(
                  ISO6391.getNativeName(localeCode)
                ),
              };
              const itemText = `${item.name} ${LANG_NAME_DELIM} ${item.nativeName}`;
              return (
                <Combobox.Item key={localeCode} value={itemText}>
                  <Row>
                    <Row.Item>{itemText}</Row.Item>
                    <Row.Item shrink>
                      <code
                        style={{ color: "var(--theme-primary)" }}
                        className="fontSize--s"
                      >
                        {localeCode}
                      </code>
                    </Row.Item>
                  </Row>
                </Combobox.Item>
              );
            })}
          </Combobox>
        </div>
      </Dialog>
    </>
  );
};

LanguageAdder.propTypes = {
  /** callback invoked with localeCode when user confirms a language add */
  onLanguageAdd: PropTypes.func,
};

export default LanguageAdder;
