import React, { useState } from "react";
import { Checkbox, Button } from "@narmi/design_system";
import { jsonFromDocument } from "cerulean"; // eslint-disable-line import/no-unresolved
import ConditionalMessage from "../components/ConditionalMessage";

export default function TermsAcceptance() {
  const acceptancePerm = jsonFromDocument("can_accept_terms") || false;
  const isNarmi = jsonFromDocument("is_narmi") || false;
  const csrfToken = jsonFromDocument("csrf_token") || null;
  const [acceptanceChecked, setAcceptanceChecked] = useState(false);
  const [error, setError] = useState(null);
  const checkboxDisclaimer =
    "By proceeding, I confirm I have reviewed and agree to the Terms of Service for Narmi Functions " +
    "outlined above and I represent that I have the authority to bind Client, its affiliates, and all users who " +
    "access Narmi Functions on its behalf to these Terms. If you do not have authority, you must not accept " +
    "these Terms and you may not use the Narmi Functions services.";

  async function onSubmit() {
    setError(null);
    if (!acceptanceChecked) {
      setError("You must check the box to confirm your agreement.");
      return;
    }
    const res = await fetch("/v1/product_terms", {
      method: "PUT",
      headers: {
        "X-CSRFToken": csrfToken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        feature: "narmi_functions",
        accepted: acceptanceChecked,
      }),
    });
    if (!res.ok) {
      setError(res.json()?.message || res.statusText);
      return;
    }
    window.location.reload();
  }

  return (
    <div>
      <h1 className="padding--y">Narmi Functions</h1>
      <ConditionalMessage message={error} />
      {acceptancePerm && !isNarmi ? (
        <div className="nds-container">
          <p>
            Please review the following terms which includes important
            information on the Narmi Functions service including pricing and
            your organization&apos;s responsibilities in using the service.
          </p>
          <h2 className="padding--y">Terms of Service for Narmi Functions</h2>
          <iframe
            title="Terms of service"
            style={{ width: "100%", height: "50em" }}
            src="https://www.narmi.com/policies/functions-terms"
          />
          <Checkbox
            onChange={() => setAcceptanceChecked(!acceptanceChecked)}
            value={acceptanceChecked}
            label={checkboxDisclaimer}
          />
          <Button
            style={{ float: "right" }}
            label="Submit"
            onClick={() => onSubmit()}
          />
        </div>
      ) : (
        <div className="ui info message margin--y--l">
          <div className="ui center aligned container">
            <span className="title">
              An administrator must accept the Terms of Service for Narmi
              Functions before Narmi Functions can be used.
            </span>
          </div>
        </div>
      )}
    </div>
  );
}
