// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".inputError--InEU5 {\n  font-size: 12px;\n  font-weight: 400;\n  color: var(--color-errorDark);\n  width: 100%;\n  display: flex;\n  flex-flow: row nowrap;\n  align-items: center;\n  margin: 0.33em 0 0;\n}\n\n.inputError--InEU5 svg {\n  margin: -0.1em 0.4em 0 0.2em;\n  height: 1.2em;\n  width: 1.2em;\n}\n\n.noSemantic--V8LC5 label {\n  font: unset;\n  text-rendering: geometricPrecision;\n  font-family: var(--font-family-default);\n  line-height: var(--font-lineHeight-default);\n  color: var(--font-color-primary);\n}\n", "",{"version":3,"sources":["webpack://./src/institution_settings/JsonInput.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,6BAA6B;EAC7B,WAAW;EACX,aAAa;EACb,qBAAqB;EACrB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,4BAA4B;EAC5B,aAAa;EACb,YAAY;AACd;;AAEA;EACE,WAAW;EACX,kCAAkC;EAClC,uCAAuC;EACvC,2CAA2C;EAC3C,gCAAgC;AAClC","sourcesContent":[".inputError {\n  font-size: 12px;\n  font-weight: 400;\n  color: var(--color-errorDark);\n  width: 100%;\n  display: flex;\n  flex-flow: row nowrap;\n  align-items: center;\n  margin: 0.33em 0 0;\n}\n\n.inputError svg {\n  margin: -0.1em 0.4em 0 0.2em;\n  height: 1.2em;\n  width: 1.2em;\n}\n\n.noSemantic label {\n  font: unset;\n  text-rendering: geometricPrecision;\n  font-family: var(--font-family-default);\n  line-height: var(--font-lineHeight-default);\n  color: var(--font-color-primary);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputError": "inputError--InEU5",
	"noSemantic": "noSemantic--V8LC5"
};
export default ___CSS_LOADER_EXPORT___;
