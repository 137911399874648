import React, { useContext, useEffect, useState, } from "react";
import { Button, Dialog, Row, Select, TextInput, Tooltip } from "@narmi/design_system";
import { NotificationContext } from "cerulean"; // eslint-disable-line import/no-unresolved
import DropdownOptionInputs from "./DropdownOptionInputs";

function AddAccountActivityQuestionDialog({ isOpen, onClose, questionData, setQuestionData, isEditing, submitChange }) {

  const [errors, setErrors] = useState({});
  const { sendNotification } = useContext(NotificationContext);

  useEffect(() => {
    if (isEditing && questionData) {
      setQuestionData(questionData);
    }
  }, [isEditing, questionData, setQuestionData]);

  function resetAndClose() {
    setErrors({});
    onClose();
  }

  function onSave() {
    const { question, answer, options, label } = questionData;
    const updatedErrors = {}
    if (!question) updatedErrors.question = "Required";
    if (!answer) updatedErrors.answer = "Required";
    if (answer === "dropdown" && (options.some(opt => !opt) || options.length < 2)) {
      updatedErrors.options = options.map((opt) => !opt ? "Required" : "");
    } else if (answer === "freeform" && !label) {
      updatedErrors.label = "Required";
    }
    setErrors(updatedErrors);
    if (Object.keys(updatedErrors).length) {
      return;
    }

    // serialization
    const update = { question };
    if (answer !== "yes/no") {
      update.questionType = answer;
      if (label) {
        update.label = label;
      }
    }
    if (answer === "dropdown") {
      update.options = options;
    }
    sendNotification({ type: "success", text: "Changes saved!" });
    submitChange(update);
    resetAndClose();
  }

  return (
    <Dialog
      title={isEditing ? "Edit question" : "Add a question"}
      isOpen={isOpen}
      width='764px'
      onUserDismiss={() => resetAndClose()}
      footer={
        <div className="padding--top--l">
          <Row justifyContent="end" alignItems="center">
            <Row.Item shrink>
              <Button
                kind="negative"
                onClick={() => resetAndClose()}
                label="Cancel"
              />
            </Row.Item>
            <Row.Item shrink>
              <Button onClick={() => onSave()} label="Save" />
            </Row.Item>
          </Row>
        </div>
      }
    >
      <div className="margin--top--s margin--bottom--xs fontWeight--semibold">Question</div>
      <TextInput
        style={{ fontSize: "14px" }}
        multiline
        error={errors.question || ""}
        value={questionData.question}
        onChange={(event) => {
          setErrors({});
          setQuestionData((prev) => ({ ...prev, question: event.target.value }))}
        }
      />
      <div className="margin--top--l margin--bottom--xs fontWeight--semibold">Answer</div>
      <Select
        label="Answer type"
        errorText={errors.answer || ""}
        value={questionData.answer}
        onChange={(answer) => {
          setErrors({});
          // remove all values that do not pertain to the type of question selected
          if (answer === "yes/no") {
            setQuestionData((prev) => ({ question: prev.question, answer }));
          } else if (answer === "freeform") {
            setQuestionData(({ options, ...otherPrev }) => ({ ...otherPrev, answer }));
          } else {
            setQuestionData((prev) => ({ ...prev, options: [""], answer }));
          }
        }}
      >
        <Select.Item value="yes/no">Yes/No</Select.Item>
        <Select.Item value="dropdown">Dropdown</Select.Item>
        <Select.Item value="freeform">Open input</Select.Item>
      </Select>
      {questionData.answer && questionData.answer === "freeform" && (
        <>
          <div className="margin--top--m margin--bottom--xs fontWeight--semibold">Input label</div>
          <div style={{
            position: "relative"
          }}>
              <TextInput
                label="Input label"
                error={errors.label || ""}
                value={questionData.label}
                onChange={(event) => {
                  setErrors({});
                  setQuestionData((prev) => ({ ...prev, label: event.target.value }))}
                }
              />
              <div style={{
                position: "absolute",
                top: "50%",
                right: "16px",
                transform: "translateY(-44%)"
              }}>
                <Tooltip block text={'This label will appear inside the input text box. For example, "Enter answer"'}>
                  <span className="narmi-icon-info" />
                </Tooltip>
              </div>
            </div>
        </>
      )}
      {questionData.answer && questionData.answer === "dropdown" && (
        <>
        <div className="margin--top--l margin--bottom--xxs fontWeight--semibold">Dropdown inputs</div>
          <p className="fontSize--s">Use a comma to separate inputs.</p>
          <DropdownOptionInputs
            questionData={questionData}
            setQuestionData={setQuestionData}
          />
        </>
      )}


    </Dialog>
  );
};

export default AddAccountActivityQuestionDialog;