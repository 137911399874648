/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import {
  Button,
  Checkbox,
  Dialog,
  LoadingShim,
  RadioButtons,
  Row,
  Select,
  TextInput,
  Tooltip,
  formatNumber,
} from "@narmi/design_system";
import PropTypes from "prop-types";
import cloneDeep from "lodash.clonedeep";
import isEmpty from "lodash.isempty";
import { jsonFromDocument, NotificationContextProvider, ProductCard } from "cerulean"; // eslint-disable-line import/no-unresolved
import styles from "./styles/OpeningProductsWizard.module.css";
import { setModifiedProduct, useOpwContext } from "./OpwContext";
import Product, { BUSINESS_APP_TYPES, PERSONAL_APP_TYPES } from "./OpwProduct";
import ProductForm, { OpwFormError } from "./OpwFormValidator";
import OpwDisclosuresDrawer from "./OpwDisclosuresDrawer";
import AccountActivityQuestions from "./AccountActivityQuestions";
import PermissionedField from "./PermissionedField";
import BrandedCards from "./BrandedCards";

export const ACCOUNT_TYPES = {
  checking: {
    pluralToString: "Checking accounts",
    singularToString: "Checking",
  },
  certificate_of_deposit: {
    pluralToString: "Certificates of deposit",
    singularToString: "Certificate of deposit",
  },
  money_market: {
    pluralToString: "Money markets",
    singularToString: "Money market",
  },
  savings: {
    pluralToString: "Savings accounts",
    singularToString: "Savings",
  },
  hsa: {
    pluralToString: "HSA accounts",
    singularToString: "HSA",
  }
};

const getValueForBeneficiaries = (value) => {
  // The default for undefined is true because we only want to
  // disable beneficiaries for the products explicitly marked as "No"
  if (value === undefined) return true;
  return value.toString() === "true";
}

const OpwTextInputRowItem = (props) => {
  const {
    placeholder,
    label,
    name,
    defaultValue,
    onChange,
    disabled,
    maxLength,
    multiline,
    formatter,
    tooltip = "",
    error,
    endContent,
  } = props;

  return (
    <Row.Item>
      <div style={{ position: "relative" }}>
        <TextInput
          id={name}
          className="opw-input"
          placeholder={placeholder}
          label={label}
          name={name}
          defaultValue={defaultValue}
          onChange={onChange(name)}
          disabled={disabled}
          maxLength={maxLength}
          multiline={multiline}
          formatter={formatter}
          error={error}
          endContent={endContent}
        />
        {tooltip && (
          <div
            style={{
              position: "absolute",
              top: "50%",
              right: "16px",
              transform: "translateY(-44%)",
            }}
          >
            <Tooltip text={tooltip}>
              <span className="narmi-icon-info" />
            </Tooltip>
          </div>
        )}
      </div>
    </Row.Item>
  );
};

const OpwTextInputRow = (props) => {
  const {
    label,
    fieldName,
    placeholder,
    handleFieldChange,
    defaultValue,
    disabled,
    maxLength,
    multiline,
    formatter,
    tooltip = "",
    error,
  } = props;
  return (
    <>
      <div className="padding--bottom">
        <Row>
          <OpwTextInputRowItem
            placeholder={placeholder}
            label={label}
            name={fieldName}
            defaultValue={defaultValue}
            onChange={handleFieldChange}
            disabled={disabled}
            maxLength={maxLength}
            multiline={multiline}
            formatter={formatter}
            tooltip={tooltip}
            error={error}
          />
        </Row>
      </div>
    </>
  );
};

/**
 * take an application type and adds or removes it from the exclusive application type
 * list and returns the new list
 *
 * @param {String} appType - the app type to add to the exclusive application type
 * @param {ProductForm} formData - the form to reference existing exclusive application type
 * @param {Boolean} toRemove - should the appType be added or removed from the list
 * @returns {Array} returns the updated exclusive application types list
 */
export function updateExclusiveApplicationTypes(appType, formData, toRemove) {
  let newTypes = formData.product?.exclusive_application_types
    ? cloneDeep(formData.product.exclusive_application_types)
    : [];
  if (!Object.values(BUSINESS_APP_TYPES).includes(appType)) {
    return newTypes;
  }
  if (!toRemove) {
    newTypes.push(appType);
  } else {
    newTypes = newTypes.filter((val) => val !== appType);
  }
  return new Array(...new Set(newTypes));
}

const OpwExclusiveAppTypesCheckbox = ({
  label,
  value,
  formData,
  setFormDataAndInProgress,
}) => {
  const [checked, setChecked] = useState(
    formData.product?.exclusive_application_types === undefined
      ? true
      : formData.product?.exclusive_application_types?.includes(value)
  );
  return (
    <Checkbox
      label={label}
      checked={checked}
      onChange={() => {
        const data = new ProductForm({ ...formData });
        data.product.exclusive_application_types =
          updateExclusiveApplicationTypes(value, data, checked);
        setChecked(!checked);
        setFormDataAndInProgress(data, "exclusive_application_types");
      }}
    />
  );
};

const OpwSampleCardModal = ({ isModalOpen, setModalOpen, product }) => {
  const data = new Product({ ...product });
  const details = [];
  data.details.forEach((detail, idx) => {
    if (detail) {
      details.push(
        // eslint-disable-next-line react/no-array-index-key
        <li style={{ marginBottom: "12px" }} key={idx}>
          <ReactMarkdown className="markdown" linkTarget="_blank">
            {detail}
          </ReactMarkdown>
        </li>
      );
    }
  });
  if (details.length === 0) {
    for (let i = 0; i < 3; i += 1) {
      details.push(
        <li style={{ marginBottom: "12px" }} key={`Detail #${i + 1}`}>
          {`Detail #${i + 1}`}
        </li>
      );
    }
  }
  return (
    <Dialog
      isOpen={isModalOpen}
      title="Product details"
      onUserDismiss={() => {
        setModalOpen(false);
      }}
    >
      <div className="padding--bottom--m">
        Your product details will be shown in the product card. Here’s where to
        put everything.
      </div>
      <div id="opw-previews">
        <div className="large">
          <div className="account-selection-form">
            <div
              style={{ marginTop: "25px" }}
              className="margin--bottom--xxl"
            >
              <div className="product-card-body">
                <ProductCard product={product} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const OpwSubmitModal = ({
  isActive,
  isModalOpen,
  setModalOpen,
  confirmCallback,
}) => {
  const { modifyingProducts } = useOpwContext();
  useEffect(() => {}, [modifyingProducts]);
  const actions = (
    <div style={{ float: "right" }} className="padding--right">
      <Row alignItems="center">
        <Row.Item shrink>
          <Button
            onClick={() => {
              setModalOpen(false);
            }}
            kind="negative"
            label="Cancel"
          />
        </Row.Item>
        <Row.Item shrink>
          <Button
            onClick={(evt) => {
              confirmCallback(evt);
              setModalOpen(false);
            }}
            label="Yes, save"
          />
        </Row.Item>
      </Row>
    </div>
  );
  return (
    <Dialog
      isOpen={isModalOpen}
      title="Save changes"
      footer={actions}
      onUserDismiss={() => {
        setModalOpen(false);
      }}
    >
      {isActive
        ? "Are you sure you want to save your changes? They will go live immediately."
        : "Upon saving, kindly go and activate the product for the changes to become available."}
    </Dialog>
  );
};

const OpwForm = ({
  buttonTitle,
  initialProduct,
  setInProgressProducts,
  setCategorySelected,
  setChanged,
}) => {
  const isNewProduct = Object.keys(initialProduct).length === 1;
  const operation = isNewProduct ? "add" : "edit";
  const {
    isBusiness,
    modifyingProducts,
    submitForm,
    debitCardRequiredByProductEnabled,
    institutionFeatures,
  } = useOpwContext();
  const otherProductIds = new Set();
  modifyingProducts.forEach((p) => {
    if (initialProduct.display_order !== p.display_order) {
      otherProductIds.add(p.id);
    }
  });
  const [formData, setFormData] = useState(
    new ProductForm({
      product: new Product({
        display_order: -1,
        ...initialProduct,
      }),
      otherProductIds,
    })
  );
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [isSampleCardOpen, setIsSampleCardOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDebitCardChecked, setIsDebitCardChecked] = useState(
    formData.product?.options?.debit_card || false
  );
  const [isCourtesyPayChecked, setIsCourtesyPayChecked] = useState(
    formData.product?.options?.courtesy_pay || false
  );
  const [hasChanged, setHasChanged] = useState(false);
  const currentUserIsNarmi = jsonFromDocument("current_user").is_narmi;
  const permissions = jsonFromDocument("user_permissions");

  const canEditProducts = permissions.includes("indigo.change_opening_products");

  const setFormDataAndInProgress = (data, field) => {
    data.validate(field);
    if (field === "metadata" && data.validate(field)) {
      // eslint-disable-next-line no-param-reassign
      data.product.metadata = JSON.parse(data.product.metadata);
    }
    setFormData(data);
    setHasChanged(true);
    const product = new Product({ ...formData.product });
    product.public = true;
    setCategorySelected(product.category);
    setChanged(true);
    setInProgressProducts(
      setModifiedProduct(product, modifyingProducts, operation)
    );
  };

  useEffect(() => {
    if (!isNewProduct) {
      const product = new Product({ ...formData.product });
      product.public = true;
      setCategorySelected(product.category);
      setInProgressProducts(
        setModifiedProduct(product, modifyingProducts, operation)
      );
    }

    if (isEmpty(formData.product?.exclusive_application_types) && !isBusiness) {
      formData.product.exclusive_application_types =
        Object.values(PERSONAL_APP_TYPES);
    }
  }, []);

  const handleFieldChange = (field) => (event) => {
    // make a copy of the object first to avoid changes by reference
    const data = new ProductForm({ ...formData });
    data.product[field] = event.target.value;
    setFormDataAndInProgress(data, field);
  };
  const handleDollarFieldChange = (field) => (event) => {
    // make a copy of the object first to avoid changes by reference
    const data = new ProductForm({ ...formData });
    if (event.target?.value !== "") {
      const parsedValue = Number(
        String(event.target.value).replace(/[$,]/g, "")
      );
      data.product[field] = parsedValue;
    } else {
      data.product[field] = "";
    }
    setFormDataAndInProgress(data, field);
  };

  const handleBoolFieldChange = (field) => (event) => {
    // make a copy of the object first to avoid changes by reference
    const data = new ProductForm({ ...formData });
    data.product[field] = event.target.value === "true";
    setFormDataAndInProgress(data, field);
  };
  const handleDetailChange = (indexStr) => (event) => {
    const data = new ProductForm({ ...formData });
    const index = indexStr.split("_")[1]; // ex. details_1
    data.product.details[index] = event.target.value;
    setFormDataAndInProgress(data, "details");
  };

  const handleMetadataChange = (field) => (event) => {
    const data = new ProductForm({ ...formData });
    if (!data.product.metadata) {
      data.product.metadata = {};
    }
    data.product.metadata[field] = event.target.value;
    setFormDataAndInProgress(data, field);
  };

  const handleMetadataFieldChange = (event) => {
    const data = new ProductForm({ ...formData });
    data.product.metadata = event.target.value;
    setFormDataAndInProgress(data, "metadata");
  };
  const handleNoteFieldChange = (event) => {
    const data = new ProductForm({ ...formData });
    data.note = event.target.value;
    setFormDataAndInProgress(data, "note");
  };

  const OpwDisplayOrder = () => {
    const selectItems = [];

    const selectCategorySubList = modifyingProducts.filter(
      (p) => p.category === formData.product.category
    );
    const subListLength = selectCategorySubList.length;
    let subRangeLength =
      subListLength - 1 >= 0
        ? selectCategorySubList[subListLength - 1].display_order -
          selectCategorySubList[0].display_order +
          1
        : 0;
    if (isNewProduct || selectCategorySubList.length === 0) {
      // adding a new product or adding to a category with no current products
      // changes the range of the subcategory
      subRangeLength += 1;
    }
    for (let idx = 1; idx <= subRangeLength; idx += 1) {
      selectItems.push(
        <Select.Item
          key={`display_order${idx}`}
          value={selectCategorySubList[0]?.display_order + idx - 1 || 1}
        >
          {idx}
        </Select.Item>
      );
    }
    if (!formData.product.display_order) {
      formData.product.display_order = subRangeLength;
    }
    return (
      <div id="opw-select">
        <Select
          id="opw-select"
          label="Display order"
          placeholder="Display order"
          name="display_order"
          onChange={(updatedVal) => {
            const data = new ProductForm({ ...formData });
            data.product.display_order = updatedVal;
            setFormDataAndInProgress(data, "display_order");
          }}
          value={formData.product?.display_order || subRangeLength}
          errorText={formData.errors.display_order}
        >
          {selectItems}
        </Select>
      </div>
    );
  };

  return (
    <div id="opw-form">
      <LoadingShim isLoading={isLoading} style={{ position: "fixed" }}>
        <div>
          <div className={styles.opwContainer}>
            <div className={styles.opwFormContainer}>
              <Row>
                <div className="fontSize--l fontWeight--bold">
                  Product details
                </div>
              </Row>
              <div className="padding--top--xxs padding--bottom">
                <Row>
                  <span>
                    This information will appear to applicants on the product
                    card.{" "}
                    <Button
                      kind="plain"
                      onClick={() => {
                        setIsSampleCardOpen(true);
                      }}
                    >
                      View a sample card
                    </Button>
                  </span>
                </Row>
              </div>
              <PermissionedField canEdit={canEditProducts}>
                <OpwTextInputRow
                  label="Product name"
                  placeholder="Product name"
                  fieldName="name"
                  handleFieldChange={handleFieldChange}
                  defaultValue={formData.product.name}
                  maxLength={canEditProducts ? 60 : 0}
                  error={formData?.errors?.name}
                />
                <OpwTextInputRow
                  label="Description"
                  placeholder="Description"
                  fieldName="description"
                  handleFieldChange={handleFieldChange}
                  defaultValue={formData.product.description}
                  maxLength={canEditProducts ? 120 : 0}
                  error={formData?.errors?.description}
                />
                <OpwTextInputRow
                  label="Details #1"
                  placeholder="Details #1"
                  fieldName="details_0"
                  handleFieldChange={handleDetailChange}
                  defaultValue={
                    formData.product.details ? formData.product.details[0] : ""
                  }
                  maxLength={canEditProducts ? 120 : 0}
                  error={formData?.errors?.details}
                />
                <OpwTextInputRow
                  label="Details #2"
                  placeholder="Details #2"
                  fieldName="details_1"
                  handleFieldChange={handleDetailChange}
                  defaultValue={
                    formData.product.details ? formData.product.details[1] : ""
                  }
                  maxLength={canEditProducts ? 120 : 0}
                  error={formData?.errors?.details}
                />
                <OpwTextInputRow
                  label="Details #3"
                  placeholder="Details #3"
                  fieldName="details_2"
                  handleFieldChange={handleDetailChange}
                  defaultValue={
                    formData.product.details ? formData.product.details[2] : ""
                  }
                  maxLength={canEditProducts ? 120 : 0}
                  error={formData?.errors?.details}
                />
                <OpwTextInputRow
                  label="Monthly service fee"
                  placeholder="Monthly service fee"
                  fieldName="monthly_service_fee"
                  handleFieldChange={handleFieldChange}
                  defaultValue={
                    formData.product?.monthly_service_fee
                      ? `${formData.product?.monthly_service_fee}`
                      : "$0"
                  }
                  tooltip="If no value is set, the display value will be $0"
                  error={formData?.errors?.monthly_service_fee}
                />
                <OpwTextInputRow
                  label="More details URL"
                  placeholder="More details URL"
                  fieldName="more_details_url"
                  handleFieldChange={handleFieldChange}
                  defaultValue={formData.product?.more_details_url || ""}
                  tooltip="An extra link at the bottom of the product tile. It is recommended to either include a 'More Details URL' for all products offered or none."
                  error={formData?.errors?.more_details_url}
                />
                <OpwTextInputRow
                  label="More details copy"
                  placeholder="More details copy"
                  fieldName="more_details_copy"
                  handleFieldChange={handleFieldChange}
                  defaultValue={formData.product?.more_details_copy || ""}
                  error={formData?.errors?.more_details_copy}
                  tooltip="The rendered text for the more details hyperlink"
                />
              </PermissionedField>
              <div className="padding--y fontSize--l fontWeight--bold">
                <Row>
                  <div>Product settings</div>
                </Row>
              </div>
              <div className="padding--bottom">
                <Row>
                  <Row.Item>
                    <PermissionedField canEdit={canEditProducts}>
                      <OpwDisplayOrder />
                    </PermissionedField>
                  </Row.Item>
                  <Row.Item>
                    <PermissionedField canEdit={canEditProducts}>
                      <OpwTextInputRowItem
                        label="Product ID"
                        placeholder="Product ID"
                        name="id"
                        onChange={handleFieldChange}
                        defaultValue={formData.product.id}
                        error={formData.errors.id}
                      />
                    </PermissionedField>
                  </Row.Item>
                </Row>
              </div>
              {!currentUserIsNarmi && (
                <div className="padding--bottom">
                  <PermissionedField canEdit={canEditProducts}>
                    <Row>
                      <OpwTextInputRowItem
                        label="Minimum suffix"
                        placeholder="Minimum suffix"
                        name="minimum_suffix"
                        onChange={handleMetadataChange}
                        defaultValue={
                          formData.product?.metadata?.minimum_suffix
                        }
                        error={formData.errors.metadata}
                      />
                      <OpwTextInputRowItem
                        label="Maximum suffix"
                        placeholder="Maximum suffix"
                        name="maximum_suffix"
                        onChange={handleMetadataChange}
                        defaultValue={
                          formData.product?.metadata?.maximum_suffix
                        }
                        error={formData.errors.metadata}
                      />
                    </Row>
                  </PermissionedField>
                </div>
              )}
              <div className="padding--bottom">
                <PermissionedField canEdit={canEditProducts}>
                  <Row>
                    <OpwTextInputRowItem
                      label="Minimum balance"
                      placeholder="Minimum balance"
                      name="minimum_balance"
                      onChange={handleDollarFieldChange}
                      tooltip="Funding is not permitted for HSA products"
                      disabled={formData.product.category === "hsa"}
                      defaultValue={
                        formData.product?.minimum_balance
                          ? `$${formData.product?.minimum_balance}`
                          : "$"
                      }
                      // eslint-disable-next-line consistent-return
                      formatter={(value) => {
                        const formattedValue = value
                          .replaceAll("$", "")
                          .replaceAll(",", "");
                        return formatNumber(formattedValue, "currency");
                      }}
                      error={formData?.errors?.minimum_balance}
                    />
                    <OpwTextInputRowItem
                      label="Maximum balance"
                      placeholder="Maximum balance"
                      name="maximum_balance"
                      onChange={handleDollarFieldChange}
                      tooltip="Funding is not permitted for HSA products"
                      disabled={formData.product.category === "hsa"}
                      defaultValue={
                        formData.product?.maximum_balance
                        ? `$${formData.product?.maximum_balance}`
                        : ""
                      }
                      // eslint-disable-next-line consistent-return
                      formatter={(value) => {
                        if (!value) {
                          return "";
                        }
                        const formattedValue = value
                          .replaceAll("$", "")
                          .replaceAll(",", "");
                        return formatNumber(formattedValue, "currency");
                      }}
                      error={formData?.errors?.maximum_balance}
                    />
                  </Row>
                </PermissionedField>
              </div>
              {isBusiness && (
                <div className="padding--bottom--xs padding--top">
                  <Row>
                    <Row.Item>
                      <div className="fontFamily--normal fontWeight--bold padding--bottom--s">
                        What kind of applicant can open this product?
                        <OpwFormError
                          error={formData.errors.exclusive_application_types}
                        />
                      </div>
                      <PermissionedField canEdit={canEditProducts}>
                        <OpwExclusiveAppTypesCheckbox
                          label="Sole Proprietor"
                          value={BUSINESS_APP_TYPES.sole_proprietor}
                          formData={formData}
                          setFormDataAndInProgress={setFormDataAndInProgress}
                        />
                        <OpwExclusiveAppTypesCheckbox
                          label="Partnership"
                          value={BUSINESS_APP_TYPES.general_partnership}
                          formData={formData}
                          setFormDataAndInProgress={setFormDataAndInProgress}
                        />
                        <OpwExclusiveAppTypesCheckbox
                          label="LLC"
                          value={BUSINESS_APP_TYPES.llc}
                          formData={formData}
                          setFormDataAndInProgress={setFormDataAndInProgress}
                        />
                        <OpwExclusiveAppTypesCheckbox
                          label="Corporation"
                          value={BUSINESS_APP_TYPES.corporation}
                          formData={formData}
                          setFormDataAndInProgress={setFormDataAndInProgress}
                        />
                      </PermissionedField>
                    </Row.Item>
                  </Row>
                </div>
              )}
              <NotificationContextProvider>
                <AccountActivityQuestions
                  formData={formData}
                  setFormData={setFormDataAndInProgress}
                />
              </NotificationContextProvider>
              <div className="padding--y">
                <Row>
                  <Row.Item>
                    <div className="fontFamily--normal fontWeight--bold padding--bottom--s">
                      Is this product required?
                      <OpwFormError error={formData.errors.required} />
                    </div>
                    <PermissionedField canEdit={canEditProducts}>
                      <RadioButtons
                        value={
                          formData.product?.required
                            ? formData.product.required.toString() === "true"
                            : false
                        }
                        id="required"
                        style={{ display: "block" }}
                        name="required"
                        options={{ Yes: true, No: false }}
                        // If setting to false, also set required_for_existing_members to false
                        onChange={(event) => {
                          const data = new ProductForm({ ...formData });
                          data.product.required = event.target.value === "true";
                          setFormDataAndInProgress(data, "required");
                          data.product.required_for_existing_members = false;
                          setFormDataAndInProgress(
                            data,
                            "required_for_existing_members"
                          );
                        }}
                      />
                    </PermissionedField>
                  </Row.Item>
                </Row>
              </div>
              {formData.product?.required && (
                <div className="padding--y">
                  <Row>
                    <Row.Item>
                      <div className="fontFamily--normal fontWeight--bold padding--bottom--s">
                        Should this product be required for existing members?
                        <OpwFormError error={formData.errors.setting_value} />
                        <Tooltip
                          text={
                            "If `yes`, this product will be required for existing members opening new accounts using their existing membership, \
                            even if they already have this product. If `no`, this product won't be required for existing members and will be hidden \
                            for existing members if they have exceeded the product maximum (but still required for new memberships)."
                          }
                        >
                          <span className="narmi-icon-info padding--left--xs" />
                        </Tooltip>
                      </div>
                      <PermissionedField canEdit={canEditProducts}>
                        <RadioButtons
                          value={
                            formData.product?.required_for_existing_members !==
                            undefined
                              ? formData.product?.required_for_existing_members.toString() ===
                                "true"
                              : null
                          }
                          id="onChange="
                          style={{ display: "block" }}
                          name="required_for_existing_members"
                          options={{ Yes: true, No: false }}
                          onChange={handleBoolFieldChange(
                            "required_for_existing_members"
                          )}
                        />
                      </PermissionedField>
                    </Row.Item>
                  </Row>
                </div>
              )}
              <div className="padding--bottom">
                <Row>
                  <Row.Item>
                    <div className="fontFamily--normal fontWeight--bold padding--bottom--s padding--top">
                      Do you want to recommend this product?
                      <OpwFormError error={formData.errors.recommended} />
                    </div>
                    <PermissionedField canEdit={canEditProducts}>
                      <RadioButtons
                        value={
                          formData.product?.recommended
                            ? formData.product.recommended.toString() === "true"
                            : false
                        }
                        id="recommended"
                        style={{ display: "block" }}
                        name="recommended"
                        options={{ Yes: true, No: false }}
                        onChange={handleBoolFieldChange("recommended")}
                      />
                    </PermissionedField>
                  </Row.Item>
                </Row>
              </div>
              <div className="padding--bottom">
                <Row>
                  <Row.Item>
                    <div className="fontFamily--normal fontWeight--bold padding--bottom--s padding--top">
                      Do you want this product to be hidden by default?
                      <Tooltip text="Products marked as hidden will be removed from the default product selection view and will only be visible to users coming from designated links.">
                        <span
                          className="narmi-icon-info fontSize--m"
                          style={{ marginLeft: 7 }}
                        />
                      </Tooltip>
                      <OpwFormError error={formData.errors.hidden} />
                    </div>
                    <PermissionedField canEdit={canEditProducts}>
                      <RadioButtons
                        value={
                          formData.product?.hidden
                            ? formData.product.hidden.toString() === "true"
                            : false
                        }
                        id="hidden"
                        style={{ display: "block" }}
                        name="hidden"
                        options={{ Yes: true, No: false }}
                        onChange={handleBoolFieldChange("hidden")}
                      />
                    </PermissionedField>
                  </Row.Item>
                </Row>
              </div>
              {institutionFeatures.cao_beneficiaries && (
                <div className="padding--bottom">
                  <Row>
                    <Row.Item>
                      <div className="fontFamily--normal fontWeight--bold padding--bottom--s padding--top">
                        Can applicants add beneficiaries to this product?
                        <OpwFormError error={formData.errors.can_add_beneficiaries} />
                      </div>
                      <RadioButtons
                        value={getValueForBeneficiaries(formData.product.can_add_beneficiaries)}
                        id="can_add_beneficiaries"
                        style={{ display: "block" }}
                        name="can_add_beneficiaries"
                        options={{ Yes: true, No: false }}
                        onChange={handleBoolFieldChange("can_add_beneficiaries")}
                      />
                    </Row.Item>
                  </Row>
                </div>
              )}
              {(formData.canHaveCourtesyPay() || formData.canHaveDebitCard()) && !isBusiness && (
                <div className="padding--bottom--xs">
                  <Row>
                    <Row.Item>
                      <div className="fontFamily--normal fontWeight--bold padding--bottom--s padding--top">
                        What product offerings do you want to support?
                        <OpwFormError error={formData.errors.options} />
                      </div>
                      <PermissionedField canEdit={canEditProducts}>
                        {formData.canHaveDebitCard() && (
                          <Checkbox
                            label="Debit card"
                            checked={isDebitCardChecked}
                            onChange={() => {
                              const data = new ProductForm({ ...formData });
                              if (!isDebitCardChecked) {
                                if (!data.product.options) {
                                  data.product.options = {};
                                }
                                data.product.options.debit_card = {
                                  card_type: "20",
                                  "create:CardCreatableFields": {
                                    IssueCode: "100",
                                  },
                                };
                              } else {
                                delete data.product.options.debit_card;
                                if (data.product.debit_card_required) {
                                  data.product.debit_card_required = false;
                                }
                                if (Object.keys(data.product.options).length === 0) {
                                  delete data.product.options;
                                }
                              }
                              setIsDebitCardChecked(!isDebitCardChecked);
                              setFormDataAndInProgress(data, "options");
                            }}
                          />
                        )}
                        {formData.canHaveCourtesyPay() && (
                          <Checkbox
                            label="Overdraft opt in"
                            checked={isCourtesyPayChecked}
                            onChange={() => {
                              const data = new ProductForm({ ...formData });
                              if (!isCourtesyPayChecked) {
                                if (!data.product.options) {
                                  data.product.options = {};
                                }
                                data.product.options.courtesy_pay = "boolean";
                              } else {
                                delete data.product.options.courtesy_pay;
                                if (Object.keys(data.product.options).length === 0) {
                                  delete data.product.options;
                                }
                              }
                              setIsCourtesyPayChecked(!isCourtesyPayChecked);
                              setFormDataAndInProgress(data, "options");
                            }}
                          />
                        )}
                      </PermissionedField>
                      {isDebitCardChecked && debitCardRequiredByProductEnabled && (
                        <>
                          <div className="fontFamily--normal fontWeight--bold padding--bottom--s padding--top--xl">
                            Do you want to require a debit card?
                            <OpwFormError error={formData.errors.debit_card_required} />
                          </div>
                          <div>
                            <PermissionedField canEdit={canEditProducts}>
                              <RadioButtons
                                value={
                                  formData.product.debit_card_required
                                    ? formData.product.debit_card_required.toString() === "true"
                                    : false
                                }
                                id="debit_card_required"
                                style={{ display: "block" }}
                                name="debit_card_required"
                                options={{ Yes: true, No: false }}
                                onChange={handleBoolFieldChange("debit_card_required")}
                              />
                            </PermissionedField>
                          </div>
                        </>
                      )}

                      {isDebitCardChecked && !formData.product.debit_card_required &&(
                        <>
                          <div className="fontFamily--normal fontWeight--bold padding--bottom--s padding--top--xl">
                            Do you want the debit card to be enabled by default?
                            <OpwFormError error={formData.errors.debit_card_enabled_by_default} />
                          </div>
                          <div>
                            <PermissionedField canEdit={canEditProducts}>
                              <RadioButtons
                                value={
                                  formData.product.debit_card_enabled_by_default
                                    ? formData.product.debit_card_enabled_by_default.toString() === "true"
                                    : false
                                }
                                id="debit_card_enabled_by_default"
                                style={{ display: "block" }}
                                name="debit_card_enabled_by_default"
                                options={{ Yes: true, No: false }}
                                onChange={
                                  (event) => {
                                    const data = new ProductForm({ ...formData });
                                    data.product.debit_card_enabled_by_default = event.target.value.toString() === "true";
                                    setFormDataAndInProgress(data, "debit_card_enabled_by_default");
                                      if (!data.product.features) {
                                        data.product.features = {};
                                      }
                                      if (event.target.value.toString() === "true") {
                                        data.product.features.debit_card = true;
                                      }
                                      else {
                                        data.product.features.debit_card = false;
                                      }
                                    setFormDataAndInProgress(data, "features");
                                  }
                                }
                              />
                            </PermissionedField>
                          </div>
                        </>
                      )}

                      {isDebitCardChecked && institutionFeatures?.cao_branded_cards && (                        
                        <BrandedCards
                          formData={formData}
                          setFormDataAndInProgress={setFormDataAndInProgress}
                          canEditProducts={canEditProducts}
                          product={initialProduct}
                        />
                      )}
                    </Row.Item>
                  </Row>
                </div>
              )}
              <div className="padding--top padding--bottom--s">
                <Row alignItems="center">
                  <Row.Item shrink>
                    <div className="fontFamily--normal fontWeight--bold">
                      Do you want to add any additional disclosures?
                    </div>
                    <OpwFormError
                      error={
                        formData.errors.disclosures
                          ? "Please fix disclosures errors"
                          : ""
                      }
                    />
                  </Row.Item>
                  <Row.Item>
                    <PermissionedField canEdit={canEditProducts}>
                      <OpwDisclosuresDrawer
                        formData={formData}
                        setFormData={setFormDataAndInProgress}
                      />
                    </PermissionedField>
                  </Row.Item>
                </Row>
                <Row>
                  <div className="padding--top--s">
                    {formData.product.disclosures?.length > 0 ? (
                      <div>
                        {formData.product.disclosures?.length} disclosure
                        {formData.product.disclosures?.length > 1 && "s"}
                      </div>
                    ) : (
                      <div>No disclosures</div>
                    )}
                  </div>
                </Row>
              </div>
              <div className="padding--top">
                <Row>
                  <Row.Item>
                    <div className="fontFamily--normal fontWeight--bold padding--bottom--s">
                      How many times can a user open this product?
                    </div>
                  </Row.Item>
                </Row>
              </div>
              <PermissionedField canEdit={canEditProducts}>
                <OpwTextInputRowItem
                  label="Number of times"
                  placeholder="Number of times"
                  name="max_count"
                  onChange={handleFieldChange}
                  defaultValue={formData.product?.max_count}
                  error={formData.errors.max_count}
                />
              </PermissionedField>
              {currentUserIsNarmi && (
                <>
                  <div className="padding--top">
                    <Row>
                      <Row.Item>
                        <div className="fontFamily--normal fontWeight--bold padding--bottom--s">
                          Metadata
                        </div>
                      </Row.Item>
                    </Row>
                  </div>
                  <div>
                    <Row>
                      <Row.Item>
                        <div style={{ position: "relative" }}>
                          <TextInput
                            id="metadata"
                            className="opw-input"
                            placeholder="Metadata"
                            label="Metadata"
                            name="Metadata"
                            defaultValue={JSON.stringify(
                              formData.product.metadata
                            )}
                            onBlur={handleMetadataFieldChange}
                            error={formData.errors.metadata}
                            multiline
                          />
                        </div>
                      </Row.Item>
                    </Row>
                  </div>
                </>
              )}
              {canEditProducts && (
                <>
                  <div className="padding--top">
                    <Row>
                      <Row.Item>
                        <div className="fontFamily--normal fontWeight--bold padding--bottom--s">
                          Change Log
                        </div>
                      </Row.Item>
                    </Row>
                  </div>
                  <div className="padding--bottom">
                    <Row>
                      <Row.Item>
                        <div style={{ position: "relative" }}>
                          <TextInput
                            id="note"
                            className="opw-input"
                            placeholder="Note"
                            label="Note"
                            name="Note"
                            value={formData.notes}
                            onChange={handleNoteFieldChange}
                            maxLength="255"
                            error={formData.errors.note}
                          />
                        </div>
                      </Row.Item>
                    </Row>
                  </div>
                </>
              )}
              <div className="padding--top--l">
                <Row alignItems="center">
                  <Button
                    label={buttonTitle}
                    kind="primary"
                    onClick={() => {
                      if (formData.validate()) {
                        setIsSubmitModalOpen(true);
                      } else {
                        setFormDataAndInProgress(formData);
                      }
                    }}
                    disabled={
                      !canEditProducts || (!hasChanged && !formData.isValid)
                    }
                  />
                </Row>
                <OpwFormError error={formData.errors.submitErrors} />
              </div>
              <OpwSubmitModal
                isModalOpen={isSubmitModalOpen}
                setModalOpen={setIsSubmitModalOpen}
                isActive={formData.product.public}
                confirmCallback={(evt) => {
                  if (formData.isValid) {
                    setIsLoading(true);
                    setIsSubmitModalOpen(false);
                    submitForm(formData, evt, operation, initialProduct.id);
                  }
                }}
              />
              <OpwSampleCardModal
                isModalOpen={isSampleCardOpen}
                setModalOpen={setIsSampleCardOpen}
                product={formData.product}
              />
            </div>
          </div>
        </div>
      </LoadingShim>
    </div>
  );
};

OpwForm.propTypes = {
  initialProduct: PropTypes.object,
  buttonTitle: PropTypes.string,
};
OpwForm.defaultProps = {
  initialProduct: {},
  buttonTitle: "",
};

export default OpwForm;
