/* eslint-disable no-underscore-dangle,no-unused-vars */
import { ftlToLocaleData } from "./fluentUtil";

/**
 * Gets Narmi default FTL content.
 * @returns {String} full content of our default FTL
 */
export const getDefaultFtl = async (product) => {
  const url = `/translationsapi/${product}/default`;
  const response = await fetch(url);
  const ftl = await response.text();
  return ftl;
};

/**
 * Gets an institution-specific FTL file by given locale code
 *
 * @param {String} locale ISO 2 Letter language code
 * @returns {String} full content of the institution's [locale].ftl
 */
export const getInstitutionFtl = async (product) => {
  const url = `/translationsapi/${product}/`;
  const response = await fetch(url);
  const { language_data: institutionFtls, manifest } = await response.json();
  return {
    institutionFtls,
    manifest,
  };
};

/**
 * @param {String} product cao, bao, etc ...
 * @param {String} locale ISO 639-1 Letter language code
 * @param {String} ftlString string of full FTL content for the given locale
 * @param {String} csrfToken
 */
export const postInstitutionFtl = async (
  product,
  locale,
  ftlString,
  csrfToken
) => {
  const url = `/translationsapi/${product}/${locale}`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "X-CSRFToken": csrfToken,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ ftl_string: ftlString }),
  });
  return response.status;
};

/**
 * @param {String} locale ISO 639-1 Letter language code
 * @param {String} ftlString string of full FTL content for the given locale
 */
export const updateLanguage = async (product, locale, csrfToken, action) => {
  const url = `/translationsapi/${product}/${locale}`;
  const response = await fetch(url, {
    method: "PUT",
    headers: {
      "X-CSRFToken": csrfToken,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ action }),
  });

  return response.status;
};

/**
 * @param {String} namespace institution namespace
 * @param {string} product narmi three letter product code
 * @param {String} locale ISO 2 Letter language code
 */
export const addInstitutionLocale = async (locale, ftlString) => null;

/**
 * Makes a call to our translations api that returns a fluent file
 * with ids and messages generated from setting names and values.
 */
export const getSettingValuesFtl = async () => {
  const url = "/translationsapi/settings";
  const response = await fetch(url);
  const settingsFtl = await response.json();
  return settingsFtl;
};
