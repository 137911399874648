import React from "react";
import { Button, ContentCard, Row } from "@narmi/design_system";


export default function Question({ question, onEdit, onDelete }) {

  return (
    <ContentCard kind="bordered">
      <Row>
        <Row.Item><span className="fontSize--m fontWeight--normal">{question.question}</span></Row.Item>
        <Row.Item shrink>
          <Button kind="plain" label="Edit" size="s" onClick={onEdit} />
        </Row.Item>
        <Row.Item shrink>
          <Button kind="plain" label="Delete" size="s" onClick={onDelete} />
        </Row.Item>
      </Row>
    </ContentCard>
  )
};
