/* eslint-disable import/prefer-default-export */

const getTitleDisplay = (rawTitle) => {
  const TITLE_REFORMAT = {
    ach: "ACH",
    "ach information": "ACH Information",
  };
  return TITLE_REFORMAT[rawTitle] || rawTitle;
};

export { getTitleDisplay };
