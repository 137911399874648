import { jsonFromDocument } from "cerulean";
import ProductForm from "../../OpwFormValidator";

export const brandedCardStruct = {
  card_name: undefined,
  issue_code: undefined,
  image_url: undefined,
};

function useBrandedCards(
  product,
  brandedCardForm,
  setBrandedCardForm,
  formData,
  setFormDataAndInProgress
) {
  const csrfToken = jsonFromDocument("csrf_token") || null;

  function onEditCard(card) {
    setBrandedCardForm((prev) => ({
      ...prev,
      data: card,
    }));
  }

  function clearForm() {
    setBrandedCardForm({
      data: brandedCardStruct,
      errors: brandedCardStruct,
    });
  }

  function onTextInputChange(event) {
    setBrandedCardForm((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        [event.target.name]: event.target.value,
      },
    }));
  }

  function updateErrors(field, error) {
    setBrandedCardForm((prev) => ({
      ...prev,
      errors: {
        ...prev.errors,
        [field]: error,
      },
    }));
  }

  function onTextInputBlur(event, label) {
    updateErrors(
      event.target.name,
      event.target.value.length > 0 ? undefined : `Please enter a valid ${label}`
    );
  }

  function onUserDismiss() {
    updateErrors("image_url", undefined);
  }

  function handleFetchErrors(json) {
    if (json.message) {
      if (json.message.file) {
        throw new Error(json.message.file[0]);
      }

      throw new Error(json.message);
    }

    throw new Error("Upload failed. Please try again.");
  }

  async function uploadImage(file) {
    const imageFormData = new FormData();
    imageFormData.append("file", file);
    imageFormData.append("product_id", product.id);

    try {
      const response = await fetch("/v1/products/upload_card_image/", {
        method: "POST",
        headers: {
          "X-CSRFToken": csrfToken,
        },
        body: imageFormData,
        credentials: "same-origin",
      });
      if (!response.ok) {
        const json = await response.json();
        handleFetchErrors(json);
        return;
      }

      const json = await response.json();
      setBrandedCardForm((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          image_url: json.image_url,
        },
      }));
    } catch (error) {
      updateErrors("image_url", error.message);
    }
  }

  function deleteImage(cardToDelete) {
    const data = new ProductForm({ ...formData });

    const filteredCards = data.product.options.card_images.filter(
      (card) => card.issue_code !== cardToDelete.issue_code
    );

    data.product.options.card_images = filteredCards;
    setFormDataAndInProgress(data, "options");
  }

  function canSubmit() {
    return (
      Object.entries(brandedCardForm.data).every(([, value]) => value != null) &&
      Object.entries(brandedCardForm.errors).every(([, value]) => value == null)
    );
  }

  function onSubmit() {
    const data = new ProductForm({ ...formData });

    const elementIndex = data.product.options.card_images.findIndex(
      (card) => card.issue_code === brandedCardForm.data.issue_code
    );

    if (elementIndex === -1) {
      data.product.options.card_images.push(brandedCardForm.data);
    } else {
      data.product.options.card_images[elementIndex] = brandedCardForm.data;
    }

    setFormDataAndInProgress(data, "options");
    clearForm();
  }

  return {
    clearForm,
    onEditCard,
    onTextInputChange,
    onTextInputBlur,
    onUserDismiss,
    uploadImage,
    deleteImage,
    canSubmit,
    onSubmit,
  };
}

export default useBrandedCards;
