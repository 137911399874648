/* eslint-disable camelcase */
import React from "react";

import { Button } from "@narmi/design_system";
import BillingStats from "./BillingStats";
import FedNowReports from "./FedNowReports";

const ReportsPageReportsTable = ({
  legacy_reports,
  availableBillingMonths,
  displayBillingReports,
}) => {
  const features = JSON.parse(
    document.getElementById("features")?.textContent || "{}"
  )
  const hasFedNowReportAccess = JSON.parse(
    document.getElementById("has_fednow_report_access")?.textContent || "false"
  )

  return <>
    <table className="ui celled table">
      <thead>
        <tr>
          <th className="fifteen wide">Name</th>
          <th className="one wide" aria-label="Download" />{" "}
          {/* eslint-disable-line jsx-a11y/control-has-associated-label */}
        </tr>
      </thead>
      <tbody>
        {Object.keys(legacy_reports)
          .sort((a, b) => legacy_reports[a].title.localeCompare(legacy_reports[b].title))
          .map((key) => (
          <tr key={key} className="item">
            <td>
              <h5 className="ui header">
                <div className="content">
                  {legacy_reports[key].title}
                  <div className="sub header">
                    {legacy_reports[key].description}
                  </div>
                </div>
              </h5>
            </td>
            <td>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = legacy_reports[key].download_url;
                }}
                as="a"
                kind="plain"
              >
                Download
              </Button>
            </td>
          </tr>
        ))}
        {displayBillingReports && availableBillingMonths.length > 0 && (
          <BillingStats availableBillingMonths={availableBillingMonths} />
        )}
        {features.fednow_receive === true && hasFedNowReportAccess ? 
         <FedNowReports />
        : null}
      </tbody>
    </table>
  </>
};

export default ReportsPageReportsTable;
